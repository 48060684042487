import React, { useEffect, useState } from "react";
import { usePmsContext } from "../../../context/PmsContext";
import { Button, Col, Drawer, Form, Image, Row, Select } from 'antd';
import filterImg from '../../../assets/images/filter.svg';
import whiteFilter from '../../../assets/images/filterWhite.svg';
import { useApi } from "../../../util/useRequestProcessor";

const ReportsFilter: React.FC = () => {
    const { reportsFilters, setReportsFilters } = usePmsContext();
    const [form] = Form.useForm<any>();
    const [visible, setVisible] = useState(false);
    const [departments, setDepartments] = useState<any[]>([]);
    const [designations, setDesignations] = useState<any[]>([]);
    const [isReportFilterApplied, setIsReportFilterApplied] = useState<boolean>(false);
    const [departmentPage, setDepartmentPage] = useState(0);
    const [designationPage, setDesignationPage] = useState(0);
    const [departmentSearch, setDepartmentSearch] = useState('');
    const [designationSearch, setDesignationSearch] = useState('');

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
        setReportsFilters({});
        resetDropdownStates();
    };

    const handleCancelClose = () => {
        setVisible(false);
    };

    const isEmptyOrUndefined = (obj: any) => {
        if (typeof obj !== 'object' || obj === null) {
            return false;
        }
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (value !== null && !(Array.isArray(value) && value.length === 0)) {
                    return true;
                }
            }
        }
        return false;
    };

    const onFinish = (values: any) => {
        const { statusID, departmentID, designationID } = values;
        const formattedValues = {
            goalStatusId: statusID || [],
            departmentId: departmentID || [],
            designationId: designationID || [],
        };
        setReportsFilters(formattedValues);
        setVisible(false);
    };

    useEffect(() => {
        setIsReportFilterApplied(isEmptyOrUndefined(reportsFilters));
    }, [reportsFilters]);

    const { data: departmentData, refetch: fetchDepartments } = useApi({
        key: ['departments', departmentPage, departmentSearch],
        httpConfig: {
            method: 'get',
            url: 'master/departments',
            params: {
                pageSize: 10 + (departmentPage * 10),
                pageNo: '0',
                sortBy: 'name',
                sortDir: 'desc',
                departmentName: departmentSearch
            },
        },
    });

    const { data: designationData, refetch: fetchDesignations } = useApi({
        key: ['designations', designationPage, designationSearch],
        httpConfig: {
            method: 'get',
            url: 'master/designations',
            params: {
                pageSize: 10 + (designationPage * 10),
                pageNo: '0',
                sortBy: 'name',
                sortDir: 'desc',
                designationName: designationSearch
            },
        },
    });
    const status = [
        {
            label: 'Submitted',
            value: 1
        },
        {
            label: 'Not Submitted',
            value: 0
        }
    ]
    useEffect(() => {
        if (departmentData?.data) {
            setDepartments(departmentData.data.content.map((item: any) => ({
                label: item.name,
                value: String(item.id),
            })));
        }
        // eslint-disable-next-line
    }, [departmentData]);

    useEffect(() => {
        if (designationData?.data) {
            setDesignations(designationData.data.content.map((item: any) => ({
                label: item.name,
                value: String(item.id),
            })));
        }
        // eslint-disable-next-line
    }, [designationData]);

    useEffect(() => {
        if (visible) {
            fetchDepartments();
            fetchDesignations();
        }
        // eslint-disable-next-line
    }, [visible]);

    const handleDepartmentSearch = (value: string) => {
        setDepartmentSearch(value);
        setDepartmentPage(0);
        fetchDepartments();
    };

    const handleDesignationSearch = (value: string) => {
        setDesignationSearch(value);
        setDesignationPage(0);
        fetchDesignations();
    };

    const handleDepartmentScroll = (e: any) => {
        if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
            setDepartmentPage((prevPage) => prevPage + 1);
            fetchDepartments();
        }
    };

    const handleDesignationScroll = (e: any) => {
        if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
            setDesignationPage((prevPage) => prevPage + 1);
            fetchDesignations();
        }
    };

    const resetDropdownStates = () => {
        setDepartmentPage(0);
        setDesignationPage(0);
        setDepartmentSearch('');
        setDesignationSearch('');
    };

    return (
        <>
            <Button className={`m-2 extensionBtn no-hover ${isReportFilterApplied ? 'filterApplied' : ''}`} hidden={false} onClick={showModal}>
                <span>
                    <Image src={isReportFilterApplied ? whiteFilter : filterImg} className="extensionImage" preview={false} />
                    Filter
                </span>
            </Button>

            <Drawer
                title="Reports Table Filter"
                width={720}
                onClose={handleCancelClose}
                maskClosable={false}
                destroyOnClose={true}
                closable={true}
                footer={
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" ghost size="large" onClick={handleCancel}>
                                Clear Filter
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" size="large" htmlType="submit" onClick={form.submit}>
                                Apply Filter
                            </Button>
                        </Col>
                    </Row>
                }
                open={visible}
                className="filterDrawer"
            >
                <Form
                    form={form}
                    name="filterForm"
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="statusID"
                        label="G&O Status"
                        rules={[{ required: false, message: "This is a mandatory field" }]}
                    >
                        <Select
                            mode="multiple"
                            size="middle"
                            id="statusID"
                            placeholder="Please select Status"
                            options={status}
                            filterOption={false}
                            showSearch={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="departmentID"
                        label="Department"
                        rules={[{ required: false, message: "This is a mandatory field" }]}
                    >
                        <Select
                            mode="multiple"
                            size="middle"
                            id="departmentID"
                            placeholder="Please select Department"
                            options={departments}
                            onSearch={handleDepartmentSearch}
                            onPopupScroll={handleDepartmentScroll}
                            showSearch
                            filterOption={false}
                        />
                    </Form.Item>

                    <Form.Item
                        name="designationID"
                        label="Designation"
                        rules={[{ required: false, message: "This is a mandatory field" }]}
                    >
                        <Select
                            mode="multiple"
                            size="middle"
                            id="designationID"
                            placeholder="Select Designation"
                            options={designations}
                            onSearch={handleDesignationSearch}
                            onPopupScroll={handleDesignationScroll}
                            showSearch
                            filterOption={false}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default ReportsFilter;