import React, { useEffect, useState } from "react";
import { Card, Col, List, Menu, Image, Row, Tag, Typography, Flex } from "antd";
import ytsImg from '../../assets/images/ytsImg.svg'
import abovePlanImg from '../../assets/images/abovePlanImg.svg'
import inProgressImg from '../../assets/images/inProgressImg.svg'
import { Container } from "react-bootstrap";
import { useApi } from "../../util/useRequestProcessor";
import { dateTime } from "../../util/Common";
import { usePmsContext } from "../../context/PmsContext";
import NoData from "../../layouts/shared/NoData";

const { Text } = Typography;


const YearlyStatusCard: React.FC = () => {
    const { setBaseCycles, baseCycles, profile } = usePmsContext();

    const [currentTab, setCurrentTab] = useState('tab1');

    const onTabChange = (e: any) => {
        setCurrentTab(e.key);
    };

    const { data, isLoading } = useApi({
        key: ['baseCycles', profile?.id],
        httpConfig: {
            method: 'get',
            url: `config/reviewCycles/baseCycles`
        },
        queryConfig: {
            enabled: !!profile?.id
        }
    })

    useEffect(() => {
        if (data?.data) {
            setBaseCycles(data?.data);
        }
        // eslint-disable-next-line
    }, [data, currentTab])

    return (
        <>
            <Card className='menuCard' bordered={false} loading={isLoading}>
                <Menu onClick={onTabChange} selectedKeys={[currentTab]} style={{ width: '100%' }} mode="horizontal"
                    items={
                        [
                            { key: 'tab1', label: <Text>Current Year</Text> },
                            { key: 'tab2', label: <Text>Previous Year</Text> }
                        ]
                    }
                />
                <Container className='listItems'>
                    {currentTab === 'tab1' && (
                        <List
                            bordered
                            dataSource={baseCycles}
                            renderItem={(item: any) => (
                                <List.Item key={item?.id}>
                                    {(item?.status === 'ABOVE_PLAN' || item?.status === 'COMPLETED') && (
                                        <>
                                            <Text>
                                                <Row>
                                                    <Col>
                                                        <Image src={abovePlanImg} className='menuListImage' alt="" preview={false} />
                                                    </Col>
                                                    <Col>
                                                        <Text className='menuText'>{item?.reviewLabel}</Text>
                                                        <Text className='dateTxt'>{dateTime(item?.reviewCycleDateList[0]?.startDate, 'date1')} - {dateTime(item?.reviewCycleDateList[0]?.endDate, 'date1')} </Text>
                                                    </Col>
                                                </Row>
                                            </Text>
                                            <Text>
                                                <Tag color="success">{item?.status}</Tag>
                                            </Text>
                                        </>
                                    )}
                                    {(item?.status === 'INPROGRESS' || item?.status === 'STARTED') && (
                                        <>
                                            <Text>
                                                <Row>
                                                    <Col>
                                                        <Image src={inProgressImg} className='menuListImage' alt="" preview={false} />
                                                    </Col>
                                                    <Col>
                                                        <Text className='menuText'>{item?.reviewLabel}</Text>
                                                        <Text className='dateTxt'>{dateTime(item?.reviewCycleDateList[0]?.startDate, 'date1')} - {dateTime(item?.reviewCycleDateList[0]?.endDate, 'date1')} </Text>
                                                    </Col>
                                                </Row>
                                            </Text>
                                            <Text>
                                                <Tag color="warning">In Progress</Tag>
                                            </Text>
                                        </>
                                    )}
                                    {(item?.status === 'YET_TO_START' || item?.status === 'BELOW_PLAN') && (

                                        <>
                                            <Text>
                                                <Row>
                                                    <Col>
                                                        <Image src={ytsImg} className='menuListImage' alt="" preview={false} />
                                                    </Col>
                                                    <Col>
                                                        <Text className='menuText'>{item?.reviewLabel}</Text>
                                                        <Text className='dateTxt'>{item?.reviewCycleDateList[0]?.isActive ? (dateTime(item?.reviewCycleDateList[0]?.startDate, 'date1') + '-' + dateTime(item?.reviewCycleDateList[0]?.endDate, 'date1')) : '-'} </Text>
                                                    </Col>
                                                </Row>
                                            </Text>
                                            <Text>
                                                <Tag color="default">Yet to Start</Tag>
                                            </Text>
                                        </>

                                    )}
                                </List.Item>
                            )}
                        />
                    )}
                    {currentTab === 'tab2' && (
                        <Flex className="mt-4" align="center" justify="center">
                            <NoData widthPX="150px" heightPX="auto" />
                        </Flex>
                    )}
                </Container>
            </Card>
        </>
    )
}

export default YearlyStatusCard;