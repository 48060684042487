import React, {useState } from "react";
import '../../styles/requests.scss';
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { Input, Row, Col, Select, Form, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import RequestTable from "../shared/data-table/RequestTable";

const { Text } = Typography;

const Requests: React.FC = () => {

    const [searchKey, setSearchKey] = useState<string>('');
    const [filterForm] = Form.useForm();
    const onFinish = () => {
    };

    const filterOptions = [
        {
            value: 'design',
            label: 'Design',
        },
        {
            value: 'between',
            label: 'Between',
        },
        {
            value: 'coimbatore',
            label: 'Coimbatore',
        },
        {
            value: 'sample',
            label: 'Sample',
        },
    ]

    return (
        <Content>
            <Title level={4}>Requests</Title>
            <Row className="mb-4">
                <Col span={5}>
                    <Text className="d-block mt-2">List of all the employee requests</Text>
                    <Input
                        placeholder="Search by name/response"
                        className="userSearch"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        suffix={<SearchOutlined style={{ color: '#476085', fontSize: 18 }} />}
                    />
                </Col>
                <Col span={5} offset={14}>
                    <Title level={5}>Filter by response</Title>
                    <Form className="mb-4" form={filterForm} name="basic" onFinish={onFinish}>
                        <Form.Item
                            name="currentCycle"
                        >
                            <Select
                                className="drop-down"
                                placeholder="Select Filter Options"
                                options={filterOptions}

                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            
            <RequestTable />
            
        </Content>
    )
}

export default Requests;