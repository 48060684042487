import React from "react";
import NoDataImg from '../../assets/images/noDataFound.svg';

import { Image, Typography } from 'antd';
const { Title } = Typography;

interface Props {
    heightPX?: string;
    widthPX?: string;
};


const NoData: React.FC<Props> = ({ heightPX = 'auto', widthPX = 'auto' }) => {

    return (
        <div className="text-center">
            <div>
                <Image src={NoDataImg} preview={false} height={heightPX} width={widthPX} />
            </div>
            <div>
                <Title level={5}>Looks like there is No Data Found!</Title>
            </div>
        </div>
    )
}

export default NoData;