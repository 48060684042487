import React, { useEffect, useState } from "react";
import { Progress, Tooltip, Typography, Card, Avatar } from 'antd'
import { Container } from "react-bootstrap";
// import profileImg from '../../assets/images/profile3.svg'
import { useApi } from "../../util/useRequestProcessor";
import useAuth from "../../hooks/useAuth";
const { Text, Title } = Typography;

const UpdatesCard: React.FC = () => {

    const { user } = useAuth();

    const { data, isLoading } = useApi({
        key: ['update-card'],
        httpConfig: {
            method: 'get',
            url: `team/goal_submissions/${user?.id}`
        },
        queryConfig: {
            enabled: !!user?.id
        }
    })

    const [submissionDetails, setSubmissionDetails] = useState<any>([])
    useEffect(() => {
        const Data: any = data?.data
        if (Data) {
            setSubmissionDetails(Data)
        }
        // eslint-disable-next-line
    }, [data])

    // calculate percentage for progress bar 
    const calculatePercentage = (progressNo: any, totalTeammates: any) => {
        return (progressNo / totalTeammates) * 100;
    };

    const progressNo = submissionDetails?.submitted ? submissionDetails?.submitted : 0;
    const totalTeammates = submissionDetails?.teamSize ? submissionDetails?.teamSize : 0;
    const percentage = calculatePercentage(progressNo, totalTeammates);

    //tooltip mapping
    const toolTipItems = submissionDetails?.users;
    const tooltipContent = toolTipItems?.map((item: any, index: any) =>
        <Container className="d-block updatesCard py-1" key={index}>
            <Avatar className="px-2" size={20}>{item.firstName?.charAt(0)?.toUpperCase()}</Avatar>
            <Text className="p-2">{item.firstName + ' ' + item.lastName}</Text>
        </Container>
    );


    return (
        <>
            {totalTeammates !== 0 && (
                <Card className='pb-4 updateCard' bordered={false} title={"G&O Updates"} loading={isLoading}>
                    <Text>No.of Teammates who have created their G&O </Text>
                    <Title level={3} className="mt-3">{progressNo} out of {totalTeammates}</Title>
                    {(
                        <>
                            <Container className="updatesCard">
                                {progressNo !== 0 ? (
                                    <Tooltip title={tooltipContent} overlayClassName="custom-tooltip">
                                        <Progress percent={percentage} showInfo={false} />
                                    </Tooltip>
                                ) : (
                                    <Progress percent={percentage} showInfo={false} />
                                )}
                            </Container>
                        </>
                    )}
                </Card>
            )}
        </>
    )
}

export default UpdatesCard;