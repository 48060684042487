
import { Col, Row, Image, Space, Popover, Button, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import logo from '../../assets/images/logo.svg';
import TopMenu from '../shared/TopMenu';
import useAuth from '../../hooks/useAuth';

const { Title } = Typography

const EmployeeHeader = () => {
    const { user, logout } = useAuth();

    const content = (
        <Row align={'middle'} gutter={[16, 8]}>
            <Col span={4} xxl={3} className='text-center'>
                <Avatar src={user?.name} size={44} shape='square'>{user?.name?.charAt(0)?.toUpperCase()}</Avatar>
            </Col>
            <Col span={20} xxl={21}>
                <Title level={5} className='mb-0'>{user?.name}</Title>
                <span style={{wordBreak:'break-word'}}>{user?.email}</span>
            </Col>
            <Col span={24} >
                <Button icon={<LogoutOutlined style={{ fontSize: '18px' }} />} type='text' onClick={logout} className='px-0'>Logout</Button>
            </Col>
        </Row>
    );

    return (
        <>
            <Row align={'middle'} className='px-5' style={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
                lineHeight: '60px',
                background:'#ffff'
            }}>
                <Col span={6}>
                    <Link to={"/"}>
                        <Image height={39} width={233} src={logo} preview={false} />
                    </Link>
                </Col>
                <Col span={12} className='text-center'>
                    <Title level={4} className='mb-0'>Performance Management System</Title>
                </Col>
                <Col span={6}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align={'middle'} justify={'end'}>
                        {/* <Col className='text-end'>
                            <BellOutlined style={{ fontSize: '24px' }} />
                        </Col> */}
                        <Col className='text-center'>
                            <Space wrap>
                                <Popover content={content} overlayStyle={{ width: "25vw" }} placement="bottomLeft" trigger="click">
                                    <UserOutlined style={{ fontSize: '24px' }} />
                                </Popover>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{
                position: 'sticky',
                top: 60,
                zIndex: 100,
                lineHeight: '60px'
            }}>
                <Col span={24}>
                    <TopMenu />
                </Col>
            </Row>
        </>
    )
}

export default EmployeeHeader
