import React, { useEffect, useState } from 'react';
import filterImg from '../../../assets/images/filter.svg'
import whiteFilter from '../../../assets/images/filterWhite.svg'
import { Button, Col, Drawer, Form, Image, Row, Select } from 'antd';
import { useApi } from '../../../util/useRequestProcessor';
import '../../../styles/datatable.scss'
import { usePmsContext } from '../../../context/PmsContext';
// const { RangePicker } = DatePicker;


const ExtensionFilter: React.FC = () => {
    const { setExtensionFilters, extensionFilters } = usePmsContext();
    const [form] = Form.useForm<any>();
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<any[]>([]);
    const [requestFor, setRequestFor] = useState<any[]>([]);
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
        setExtensionFilters({});
    };
    const handleCancelClose = () => {
        setVisible(false);
    };


    const isEmptyOrUndefined = (obj: any) => {
        if (typeof obj !== 'object' || obj === null) {
            return false;
          }
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              const value = obj[key];
              if (value !== null && !(Array.isArray(value) && value.length === 0)) {
                return true;
              }
            }
          }
          return false;
    };

    const onFinish = (values: any) => {
        const { statusID, requestForId, requestForRange, extendRange } = values;
        const formattedValues = {
            requestFromDate: requestForRange ? requestForRange[0].toISOString() : null,
            requestToDate: requestForRange ? requestForRange[1].toISOString() : null,
            extendFromDate: extendRange ? extendRange[0].toISOString() : null,
            extendToDate: extendRange ? extendRange[1].toISOString() : null,
            status: statusID || [],
            requestedFor: requestForId || [],
        };
        setExtensionFilters(formattedValues);
        setVisible(false);
    };

    useEffect(() => {
        setIsFilterApplied(isEmptyOrUndefined(extensionFilters));
    }, [extensionFilters])

    const { data: statusData } = useApi({
        key: ['extensionStatus'],
        httpConfig: {
            method: 'get',
            url: 'master/extension-status',
        },
    });

    const { data: requestForData } = useApi({
        key: ['extensionRequestFor'],
        httpConfig: {
            method: 'get',
            url: 'master/extension-review-status',
        },
    });

    useEffect(() => {
        if (statusData?.data) {
            setStatus(statusData?.data?.map((item: any) => ({
                label: item.status,
                value: String(item.id),
            })));
        }
        if (requestForData?.data) {
            setRequestFor(requestForData?.data?.map((item: any) => ({
                label: item.status,
                value: String(item.id),
            })));
        }
    }, [statusData, requestForData]);

    return (
        <>
            <Button className={`m-2 extensionBtn no-hover ${isFilterApplied ? 'filterApplied' : ''}`} hidden={false} onClick={showModal}>
                <span>
                    <Image src={isFilterApplied? whiteFilter: filterImg} className="extensionImage" preview={false} />
                    Filter
                </span>
            </Button>

            <Drawer
                title="Extension Management Filter"
                width={720}
                onClose={handleCancelClose}
                maskClosable={false}
                destroyOnClose={true}
                closable={true}
                footer={
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" ghost size="large" onClick={handleCancel}>
                                Clear Filter
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" size="large" htmlType="submit" onClick={form.submit}>
                                Apply Filter
                            </Button>
                        </Col>
                    </Row>
                }
                open={visible}
                className="filterDrawer"
            >
                <Form
                    form={form}
                    name="filterForm"
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="statusID"
                        label="Status"
                        rules={[{ required: false, message: "This is a mandatory field" }]}
                    >
                        <Select
                            mode="multiple"
                            size="middle"
                            id="statusID"
                            placeholder="Please select Status"
                            options={status}
                        />
                    </Form.Item>

                    <Form.Item
                        name="requestForId"
                        label="Requested For"
                        rules={[{ required: false, message: "This is a mandatory field" }]}
                    >
                        <Select
                            mode="multiple"
                            size="middle"
                            id="requestForId"
                            placeholder="Select Review Requested For"
                            options={requestFor}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        name="requestForRange"
                        label="Requested For Date"
                        rules={[{ required: false, message: "Please select the Requested For Date" }]}
                    >
                        <RangePicker
                            className="custom-range-picker"
                            popupClassName="custom-range-picker-dropdown"
                            getPopupContainer={trigger => (trigger.parentNode as HTMLElement) || document.body}
                        />
                    </Form.Item>
                    <Form.Item
                        name="extendRange"
                        label="Extend Date"
                        rules={[{ required: false, message: "Please select the Extend Date" }]}
                    >
                        <RangePicker
                            className="custom-range-picker"
                            popupClassName="custom-range-picker-dropdown"
                            getPopupContainer={trigger => (trigger.parentNode as HTMLElement) || document.body}
                        />
                    </Form.Item> */}
                </Form>
            </Drawer>
        </>
    );
};

export default ExtensionFilter;