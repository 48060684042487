import React from "react";
import { Row, Col, Card, Button } from 'antd'
import { usePmsContext } from "../../context/PmsContext";
import CommentsList from "./CommentsList";
import GraphComponent from "./GraphComponent";

const TabContent: React.FC = () => {
    const { activeComment, comments, setCommentsModal } = usePmsContext();

    return (
        <Row gutter={[24, 24]}>
            <Col span={12}>
                <GraphComponent />
            </Col>
            <Col span={12}>
                <Card
                    bordered={false}
                    className="commentsScroll"
                    title={activeComment?.reviewName ? activeComment?.reviewName + ' Comments' : ''}
                    extra={comments?.length && (<Button type="link" onClick={() => setCommentsModal(true)}>View all</Button>)}
                    style={{ minHeight: '100%' }}
                >
                    <CommentsList comments={activeComment} />
                </Card>
            </Col>
        </Row>
    )
}

export default TabContent;