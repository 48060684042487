import React, { useEffect, useState } from 'react';
import { Card, Col, Flex, FloatButton, Progress, Row, Image, Button } from 'antd'
import { Typography } from 'antd';
import './../../styles/employeeDashboard.scss'
import KPIImage from '../../assets/images/kpiImage.svg'
import Title from "antd/es/typography/Title";
import TimeLineComponent from './TimeLineComponent';
import YearlyStatusCard from './YearlyStatusCard';
import UpdatesCard from './UpdatesCard';
import ProfileCard from './ProfileCard';
import { useApi } from '../../util/useRequestProcessor';
import useAuth from '../../hooks/useAuth';
import { usePmsContext } from '../../context/PmsContext';
import ReviewInsightCard from './ReviewInsightCard';
import GoalsObjectiveCard from './GoalsObjectiveCard';
import Goals from './forms/goals';
import Approval from './forms/approval';
import ShowComments from './models/ShowComments';

const { Text } = Typography;

const EmployeeDashboard: React.FC = () => {

    const { user } = useAuth();
    const { stage } = usePmsContext();

    const rootCycleID = 1;
    const { data } = useApi({
        key: ['team-performance'],
        httpConfig: {
            method: 'get',
            url: `/goals/overall-achieved/${user?.id}/${rootCycleID}`
        }
    })
    const [performerDetails, setPerformerDetails] = useState<any>([])

    useEffect(() => {
        if (data?.data)
            setPerformerDetails(data?.data);
         // eslint-disable-next-line
    }, [data])

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <ProfileCard />
            </Col>
            <Col span={24}>
                <TimeLineComponent />
            </Col>
            <Col span={8} xxl={9}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <YearlyStatusCard />
                    </Col>
                    <Col span={24}>
                        <Card bordered={false}>
                            <Flex align='center' justify='space-evenly'>
                                <Text>
                                    <Image src={KPIImage} className="KpiImg" alt="employee-img" preview={false} />
                                </Text>
                                <Text className='px-4'>
                                    <Title level={3}>What is KPI?</Title>
                                    <Title level={5}>How to choose the best KPI for your role?</Title>
                                </Text>
                            </Flex>
                            <Flex className='d-flex justify-content-end pdfRead'>
                                <Button type="text" target='_blank' href='https://heptagon-pd.s3.ap-south-1.amazonaws.com/kpi.pdf' >
                                    {'2 Mins Read >>'}
                                </Button>
                            </Flex>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <UpdatesCard />
                    </Col>
                </Row>
            </Col>
            <Col span={16} xxl={15}>
                <Row gutter={[24, 24]}>
                    {stage?.current >= 2 && (<Col span={24}>
                        <ReviewInsightCard />
                    </Col>
                    )}
                    <Col span={24}>
                        <GoalsObjectiveCard />
                    </Col>
                    <Col span={24}>
                        {/* <StepForm1 stepNo={stepNo} stepChange={onStepChange} /> */}
                    </Col>
                </Row>

            </Col>
            {stage?.current === 3 &&
                <FloatButton
                    shape="square"
                    className='float-btn'
                    style={{ right: 48, bottom: 10, backgroundColor: 'linear-gradient(180deg, #0A93D5 0%, #006CBA 100%)', width: 'auto' }}
                    icon={<><Text className='float-text '>Overall Achieved</Text>
                        <Progress className='float-progress p-1' type="circle" percent={performerDetails?.target} size={62} strokeColor="#fff" strokeWidth={6} /></>}
                />
            }
            <Goals />
            <Approval />
            <ShowComments />
        </Row >
    );
};

export default EmployeeDashboard;