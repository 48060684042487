import React from "react";
import { Typography, Modal, Row, Col } from "antd";
import { usePmsContext } from "../../../context/PmsContext";
import CommentsList from "../CommentsList";

const { Text } = Typography;

const ShowComments: React.FC = () => {
    const { comments, commentsModal, setCommentsModal } = usePmsContext();

    return (
        <Modal
            title={"Review Comments"}
            open={commentsModal}
            footer={null}
            onCancel={() => {
                setCommentsModal(false);
            }}
            maskClosable={false}
            destroyOnClose={true}
            className='modal-right no-footer'
        >
            {comments?.filter((item) => item?.commentsDtoList?.length > 0)?.map((item) => (
                <Row key={item?.id}>
                    <Col span={24}>
                        <Text type="secondary">{item?.reviewName}</Text></Col>
                    <Col span={24}>
                        <CommentsList comments={item} key={item?.id} />
                    </Col>
                    <Col span={24}><hr className="mt-0" /></Col>
                </Row>
            ))}
        </Modal>
    );
};

export default ShowComments;
