import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tabs, Typography, Image, Flex } from "antd";
import useAuth from "../../hooks/useAuth";
import { useApi } from "../../util/useRequestProcessor";
import upArw from '../../assets/images/upArrow.svg';
import downArw from '../../assets/images/downArrow.svg';
import sideArw from '../../assets/images/sideArrow.svg';
import RoundChart from "./RoundChart";

const { Title, Text } = Typography;

const DashboardFooter: React.FC = () => {
    const { user } = useAuth();
    const [adminBaseCycle, setAdminBaseCycle] = useState<any>(null);
    const [reviewDetails, setReviewDetails] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<any>(null);
    const { data } = useApi({
        key: ['aseCycles', user?.id],
        httpConfig: {
            method: 'get',
            url: `config/reviewCycles/baseCycles`
        },
        queryConfig: {
            enabled: !!user?.id
        }
    });

    useEffect(() => {
        if (Array.isArray(data?.data)) {
            const reviewCycle = data.data;
            const formattedData = reviewCycle.map((review: any) => ({
                key: review.id.toString(),
                label: review.reviewName
            }));
            setAdminBaseCycle(formattedData);
            if (formattedData.length > 0) {
                setActiveTab(formattedData[0].key);
            }
        } else {
            console.error("Expected Basecycle to be an array");
        }
    }, [data]);

    const { data: reviewData, refetch: reviewApiCall, isLoading: reviewLoading } = useApi({
        key: ['dashboardReviewData', activeTab],
        httpConfig: {
            method: 'get',
            url: `dashboard/employee-review-status/${activeTab}`,
        },
        queryConfig: {
            enabled: !!activeTab
        }
    });

    useEffect(() => {
        if (activeTab) {
            reviewApiCall();
        }
        // eslint-disable-next-line
    }, [activeTab]);

    useEffect(() => {
        if (reviewData?.data) {
            const data = reviewData?.data
            setReviewDetails(data);
        }
    }, [reviewData])

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };

    return (
        <>
            <Row className="mt-4">
                <Col span={24}>
                    <Tabs
                        defaultActiveKey={activeTab}
                        activeKey={activeTab}
                        items={adminBaseCycle || []}
                        onChange={onTabChange}
                    />
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={9}>
                    <Card bordered={false} className="dashboardCard" loading={reviewLoading}>
                        <Title level={4} className="adminHead">{reviewDetails?.reviewName + ' Employee Review'}</Title>
                        <Text className="adminSubHead">Submission</Text>
                        <RoundChart
                            submittedCount={reviewDetails?.submitted}
                            notSubmittedCount={reviewDetails?.notSubmitted}
                            submittedLabel={'Submitted'}
                            notSubmittedLabel={'Not Submitted'} />
                    </Card>
                </Col>
                <Col span={9}>
                    <Card bordered={false} className="dashboardCard" loading={reviewLoading}>
                        <Title level={4} className="adminHead">{reviewDetails?.reviewName + ' - Supervisor'}</Title>
                        <Text className="adminSubHead">Approval Status</Text>
                        <RoundChart
                            submittedCount={reviewDetails?.rmApproved}
                            notSubmittedCount={reviewDetails?.rmNotApproved}
                            submittedLabel={'Approved'}
                            notSubmittedLabel={'Not Approved'} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false} className="dashboardCard reviewContent" loading={reviewLoading}>
                        <Title level={4} className="adminHead">Performances</Title>
                        <div>
                            <Flex className="mt-3" align='center' justify="flex-start" gap={7}>
                                <Image src={upArw} preview={false} />
                                <Text className="adminCountTxt">{reviewDetails?.abovePlan}</Text>
                                <Text>Above Plan</Text>
                            </Flex>
                            <Flex className="mt-1" align='center' justify="flex-start" gap={7}>
                                <Image src={sideArw} preview={false} />
                                <Text className="adminCountTxt">{reviewDetails?.inPLan}</Text>
                                <Text>In Plan</Text>
                            </Flex>
                            <Flex className="mt-1" align='center' justify="flex-start" gap={7}>
                                <Image src={downArw} preview={false} />
                                <Text className="adminCountTxt">{reviewDetails?.belowPlan}</Text>
                                <Text>Below Plan</Text>
                            </Flex>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default DashboardFooter;
