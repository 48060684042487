import React from "react";
import { Progress, Col, Row, Flex, Typography } from "antd";
import '../../styles/dashboard.scss';

const { Text } = Typography;

interface RoundchartProps {
    submittedCount: number,
    notSubmittedCount: number,
    submittedLabel: string,
    notSubmittedLabel: string
}

const RoundChart: React.FC<RoundchartProps> = ({ submittedCount, notSubmittedCount, submittedLabel, notSubmittedLabel }) => {
    const totalCount = submittedCount + notSubmittedCount;
    const submittedPercentage = (submittedCount / totalCount) * 100;

    return (
        <Row>
            <Col span={10} className="mt-2" style={{ textAlign: 'center' }}>
                <Progress
                    type="circle"
                    className="dashboardChart"
                    percent={Number(submittedPercentage.toFixed(2))}
                    strokeColor="#0A93D5"
                    trailColor="#F1B343"
                    format={() => `${submittedCount} Submitted / ${notSubmittedCount} Not Submitted`}
                    showInfo={false}
                    strokeWidth={30}
                    strokeLinecap="butt"
                />
            </Col>
            <Col span={14} className="countSection" style={{ textAlign: 'left' }}>
                <Flex justify='start' align='center' gap={8}>
                    <div className="bluCircle"></div>
                    <Text className="adminCountTxt">{submittedCount}</Text>
                    <Text className="adminSecHead">{submittedLabel}</Text>
                </Flex>
                <Flex justify='start' align='center' gap={8}>
                    <div className="yellowCircle"></div>
                    <Text className="adminCountTxt">{notSubmittedCount}</Text>
                    <Text className="adminSecHead">{notSubmittedLabel}</Text>
                </Flex>
            </Col>
        </Row>
    )
}

export default RoundChart;