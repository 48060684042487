import { Col, Flex, Modal, Row, Typography, Card, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutate } from '../../util/useRequestProcessor';
import useAuth from '../../hooks/useAuth';
import NoData from '../../layouts/shared/NoData';

const { Text } = Typography;
interface Props {
    tab: string
};
const TopPerformance: React.FC<Props> = (tab: any) => {

    const { user } = useAuth();
    const cycleId = tab.tab;
    const [topPerformanceCard, setTopPerformanceCard] = useState([])
    const [viewall, setview] = useState([])
    const [visible, setVisible] = useState(false);

    let dataPayload = {
        userId: user?.id,
        rootCycleId: 1,
        reviewTimelineId: Number(cycleId),
        fromAchievement: true
    };

    const { isPending, mutate: team = dataPayload } = useMutate({
        key: "top-performance",
        httpConfig: {
            method: "post",
            url: "goals/my-team/review-performers",
        },
        customConfig: {
            form: dataPayload,
            callback: async (response: any) => {
                const firstEightItems = response?.data?.toppers.slice(0, 8);
                setTopPerformanceCard(firstEightItems)
                setview(response?.data?.toppers)
            },
        },
    });

    const showModal = () => {
        setVisible(true);
    };
    const handleConfirmCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        team(dataPayload);
        // eslint-disable-next-line
    }, [setTopPerformanceCard, tab])

    return (
        <>
            <Card className="topPerformanceCard" loading={isPending} style={{ minHeight: '100%' }} bordered={false} title={"Top Performers"} extra={(topPerformanceCard?.length !== 0) && (
                <Button type='link' onClick={showModal} className="viewAllBtn">View all</Button>
            )}>
                <Flex vertical>
                    {(topPerformanceCard?.length === undefined || topPerformanceCard?.length === 0) && (
                        <div>
                            <NoData/>
                        </div>
                    )}
                    {topPerformanceCard?.map((item: any, index): any => (
                        <Row key={index} className="per-table">
                            <Col className="per-name" span={20}>
                                <Text className="per-name-text">{item?.name}</Text>
                            </Col>
                            <Col className="per-score" span={4}>
                                <Text className="per-score-text">{item?.percentage ? item.percentage + ' %' : '-'}</Text>
                            </Col>
                        </Row>
                    ))}
                </Flex>
            </Card>
            <Modal
                className="viewall-modal"
                title={<div className="modal-title">Top Performers</div>}
                open={visible}
                onCancel={handleConfirmCancel}
                centered
                footer={null}
                closable={true}
                maskClosable={false}
                style={{ maxWidth: 500 }}
            >
                <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>

                    {viewall?.map((item: any, index): any => (
                        <Row key={index} className="per-table">
                            <Col className="per-name" span={19}>
                                <Text className="per-name-text">{item?.name}</Text>
                            </Col>
                            <Col className="per-score" span={5}>
                                <Text className="per-score-text">{item?.percentage ? item.percentage + ' %' : '-'}</Text>
                            </Col>
                        </Row>
                    ))}
                </div>
            </Modal>
        </>
    )
}

export default TopPerformance