import React, { useEffect, useState, useRef } from 'react';
import { Drawer, Form, Typography, Input, App, Tag, Collapse, Progress, Row, Col, Radio, Slider, Button, InputNumber } from 'antd';
import { usePmsContext } from '../../../context/PmsContext';
import { useMutate } from '../../../util/useRequestProcessor';
import { IGoal } from '../../../types/Pms';
import { dateTime } from '../../../util/Common';

const { Paragraph, Text } = Typography;
const { TextArea } = Input;
const { Group } = Radio;

interface IReviewProgress {
    id?: number | null;
    currentStatusId: number;
    targetAchieved: number;
    goalId: number;
    reviewCycleId: number;
    userId: number;
    individualComments?: string | null;
    slider?: number;
}

interface Item {
    key: number | string;
    label: React.ReactNode;
    children: React.ReactNode;
    extra?: React.ReactNode;
    className?: string;
}

function mergeArraysByKey<T extends Record<K, number>, K extends keyof T>(arr1: T[], arr2: T[], key: K): T[] {
    const map = new Map<T[K], T>();

    for (const item of arr1.concat(arr2)) {
        const keyValue = item[key];
        const existingItem = map.get(keyValue);
        if (existingItem) {
            Object.assign(existingItem, item);
        } else {
            map.set(keyValue, { ...item });
        }
    }

    return Array.from(map.values());
}

const MarkProgress: React.FC = () => {
    const { progressModal, setProgressModal, profile, stage, goals, reviewStatus, activeTab, setCurrent, baseCycles } = usePmsContext();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [reviewProgress, setReviewProgress] = useState<IReviewProgress[]>([]);
    const [currentDate, setCurrentDate] = useState<any>();
    const [panelActive, setPanelActive] = useState<(number | string)[]>([]);
    const colorArray = ['#ffa305', '#7B2B7D', '#03bf7a', '#4f53f8'];

    // Ref for panels
    const panelRefs = useRef<Record<number | string, HTMLDivElement | null>>({});

    useEffect(() => {
        setReviewProgress(goals?.map((goal: IGoal) => ({
            id: goal?.goalAchievementId ?? null,
            currentStatusId: goal?.currentStatusId,
            targetAchieved: goal?.target_achieved ?? 0,
            slider: goal?.target_achieved ?? 0,
            goalId: goal?.id,
            reviewCycleId: Number(activeTab),
            userId: profile?.id,
            individualComments: goal?.goalSettingDto?.individualComments ?? null
        })));
        // eslint-disable-next-line
    }, [goals, progressModal]);

    const { isPending, mutate: submitApproval } = useMutate({
        key: ["markProgress", profile?.id],
        httpConfig: {
            method: "post",
            url: "/goals/review-progress",
        },
        customConfig: {
            callback: async (response: any) => {
                if (response?.error === null) {
                    message.success(response?.message, 4);
                    onCancel();
                } else {
                    console.log(response?.error);
                }
            },
            invalidateKey: [["goalObjective", profile?.id]]
        },
    });

    useEffect(() => {
        if (baseCycles && activeTab) {
            setCurrentDate(baseCycles?.filter(obj => Number(obj?.id) === Number(activeTab)));
        }
        // eslint-disable-next-line
    }, [activeTab]);

    const onCancel = () => {
        setCurrent({});
        form.resetFields();
        setProgressModal(false);
        const unfilledKeys = getUnfilledPanelKeys();
        setPanelActive(unfilledKeys);
        if (unfilledKeys.length > 0) {
            setTimeout(() => scrollToPanel(unfilledKeys[0]), 100);
        }
    };

    const onSubmit = (values: any) => {
        if (!values) return;

        let userProgress: IReviewProgress[] = Object.keys(values?.reviewProgressRequestDto)?.map((item: any) => values?.reviewProgressRequestDto[item]);
        let payload: IReviewProgress[] = mergeArraysByKey(reviewProgress, userProgress, 'goalId');
        submitApproval({ reviewProgressRequestDto: payload });
    };

    const onChange = (changedValues: any, allValues: any) => {
        let userProgress: IReviewProgress[] = Object.keys(allValues?.reviewProgressRequestDto)?.map((item: any) => allValues?.reviewProgressRequestDto[item]);
        setReviewProgress(mergeArraysByKey(reviewProgress, userProgress, 'goalId'));
    };

    function generateMarks(targetValue: number) {
        const marks: { [key: number]: string } = {};
        if (targetValue <= 0) return marks;

        const step = Math.max(1, Math.ceil(targetValue / 4));
        for (let i = 0; i <= targetValue; i += step) {
            marks[i] = `${Math.round((i / targetValue) * 100)}%`;
        }
        marks[targetValue] = "100%";
        return marks;
    }

    const genExtra = (goal: IGoal, index: number) => (
        <Progress
            type="circle"
            className={`routine-color-${index % colorArray.length}`}
            percent={Math.round((reviewProgress[index]?.targetAchieved / goal?.goalSettingDto?.target * 100)) ?? 0}
            size={32}
        />
    );

    const panelContent = (goal: IGoal, index: number) => {
        return (
            <Row ref={(el) => panelRefs.current[goal?.goalAchievementId ?? goal?.id] = el}>
                <Col span={24}>
                    <Text type="secondary">Description</Text>
                </Col>
                <Col span={24}>
                    <Paragraph>{goal?.goalSettingDto?.description}</Paragraph>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, 'currentStatusId']}
                        label="Current Status"
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                        ]}
                    >
                        <Group size='small' className='tag-selection'>
                            {reviewStatus?.map((item) => (
                                <Radio.Button
                                    key={item?.value}
                                    value={item?.value}
                                    className={`${item?.label?.toString()?.replace(/\s+/g, '-').toLowerCase()}`}
                                >
                                    {item?.label}
                                </Radio.Button>
                            ))}
                        </Group>
                    </Form.Item>
                </Col>
                <Col span={12} className='mb-3'>Target</Col>
                <Col span={12} className='text-end'>{Number(goal?.goalSettingDto?.target).toLocaleString('en-IN') + ' ' + goal?.goalSettingDto?.measurement?.label}</Col>
                <Col span={8}>Achieved</Col>
                <Col span={16} className='text-end'>
                    <Form.Item name={[`reviewProgressRequestDto`, `${index}`, `goalId`]} hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name={[`reviewProgressRequestDto`, `${index}`, `id`]} hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={[`reviewProgressRequestDto`, `${index}`, `reviewCycleId`]} hidden={true}>
                        <Input value={Number(activeTab)} />
                    </Form.Item>
                    <Form.Item name={[`reviewProgressRequestDto`, `${index}`, `userId`]} hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, `targetAchieved`]}
                        label=""
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                            { max: goal?.goalSettingDto?.target, type: 'number', message: "The achieved value can't be more than target" }
                        ]}
                    >
                        <InputNumber addonAfter={goal?.goalSettingDto?.measurement?.label} min={0} max={goal?.goalSettingDto?.target} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, `targetAchieved`]}
                        label="Percentage Achieved"
                        initialValue={typeof goal?.target_achieved === 'number' ? reviewProgress[index]?.targetAchieved : 0}
                    >
                        <Slider
                            min={0}
                            max={goal?.goalSettingDto?.target ?? 100}
                            step={1}
                            marks={generateMarks(goal?.goalSettingDto?.target ?? 100)}
                            value={reviewProgress[index]?.targetAchieved ?? 0}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, `individualComments`]}
                        label="Comments"
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                            { max: 250, message: "Comment cannot be more than 250 characters" },
                            { whitespace: true, message: "This is a mandatory field" }
                        ]}
                    >
                        <TextArea
                            placeholder="Please Enter Comment"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            showCount
                            maxLength={250}
                        />
                    </Form.Item>
                </Col>
            </Row>
        )
    };

    const generateInitialValues = () => {
        const initialValues: { reviewProgressRequestDto: { [key: string]: IReviewProgress } } = { reviewProgressRequestDto: {} };
        goals.forEach((goal, index) => {
            initialValues.reviewProgressRequestDto[index] = {
                id: goal?.goalAchievementId ?? null,
                currentStatusId: goal?.currentStatusId,
                targetAchieved: goal?.target_achieved ?? 0,
                goalId: goal?.id,
                reviewCycleId: Number(activeTab),
                userId: profile?.id,
                individualComments: goal?.goalSettingDto?.individualComments ?? null
            };
        });
        return initialValues;
    };

    const items: Item[] = goals?.map((goal: IGoal, index: number) => ({
        key: goal?.goalAchievementId ?? goal?.id,
        label: goal?.goalSettingDto?.objective,
        children: panelContent(goal, index),
        extra: genExtra(goal, index),
        className: `mb-4 routine-color-${index % colorArray.length}`
    }));

    const getUnfilledPanelKeys = () => {
        const unfilledKeys: (number | string)[] = [];
        reviewProgress.forEach((progress) => {
            if (!progress.currentStatusId || !progress.individualComments) {
                unfilledKeys.push(progress.goalId);
            }
        });
        return unfilledKeys;
    };

    const scrollToPanel = (key: number | string) => {
        const panelElement = panelRefs.current[key];
        if (panelElement) {
            panelElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleSave = () => {
        form.submit();
        const unfilledKeys = getUnfilledPanelKeys();
        setPanelActive(unfilledKeys);
        if (unfilledKeys.length > 0) {
            setTimeout(() => scrollToPanel(unfilledKeys[0]), 100);  // Delay to ensure panel is rendered
        }
    };

    return (
        <Drawer
            title={<>Self Review <Tag>{stage?.item?.reviewLabel}</Tag> <Text type='secondary'>{currentDate ? dateTime(currentDate[0]?.selfReviewDates[0]?.startDate, 'date1') + ' - ' + dateTime(currentDate[0]?.selfReviewDates[0]?.endDate, 'date1') : ''}</Text></>}
            width={720}
            onClose={onCancel}
            maskClosable={false}
            destroyOnClose={true}
            footer={
                <Row gutter={16} justify={"end"}>
                    <Col><Button type='primary' ghost size='large' onClick={onCancel}>Cancel</Button></Col>
                    <Col><Button type='primary' size='large' onClick={handleSave} loading={isPending}>Save</Button></Col>
                </Row>
            }
            open={progressModal}
        >
            <Form
                form={form}
                layout="vertical"
                size='large'
                requiredMark={false}
                onFinish={onSubmit}
                onValuesChange={onChange}
                preserve={false}
                initialValues={generateInitialValues()}
            >
                <Collapse
                    items={items}
                    defaultActiveKey={items[0]?.key}
                    activeKey={panelActive.length > 0 ? panelActive : items[0]?.key}
                    expandIconPosition={'end'}
                    accordion={true}
                    ghost
                    size='middle'
                    className='review-progress'
                    onChange={keys => setPanelActive(keys as (number | string)[])}
                />
            </Form>
        </Drawer>
    )
}

export default MarkProgress;
