import React, { useEffect, useState } from 'react';
import './../../styles/my-team.scss'
import { Card, Col, Row, Image, Typography, theme } from 'antd';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { useMutate } from '../../util/useRequestProcessor';
import chartIcon from '../../assets/images/chartIcon.svg';
import "../../styles/my-team.scss";
import useAuth from '../../hooks/useAuth';
import PlanChartDisplay from './planchart';
import NoData from '../../layouts/shared/NoData';
ChartJS.register(ArcElement, Tooltip, Legend);
const { Title, Text } = Typography;
interface chartData {
    teamStrength: number;
    submitted: number;
    notSubmitted: number;
    score: any;
    status: any;
}
interface Props {
    tab: string
};
const ChartDisplay: React.FC<Props> = (tab: any) => {
    const [payloadData, setPayloadData] = useState();
    const [chartData, setChartData] = useState<chartData>();
    const reviewCycleId: number = tab.tab;
    const { user } = useAuth();
    const { token } = theme.useToken();
    const [data, setData] = useState<any>({
        labels: ['Submitted', 'Not Submitted'],
        datasets: [
            {
                label: 'Team Strength',
                data: [0, 0],
                backgroundColor: [
                    'rgb(26, 147, 211)',
                    'rgb(243, 189, 90)'
                ],
                borderColor: [
                    'rgb(26, 147, 211)',
                    'rgb(243, 189, 90)'
                ],
                borderWidth: 1,
            },
        ],
    });

    useEffect(() => {
        const payload = {
            managerId: user?.id,
            reviewCycleId: Number(reviewCycleId)
        }
        setPayloadData(payloadData);
        getObjectiveApi(payload);
        // eslint-disable-next-line
    }, [tab])

    const { isPending, mutate: getObjectiveApi = (payloadData) } = useMutate({
        key: "goals",
        httpConfig: {
            method: "post",
            url: "/team/status",
        },
        customConfig: {
            form: payloadData,
            callback: async (response: any) => {
                let data: any = response?.data;
                setChartData(data);
            },
        },
    });
    useEffect(() => {
        if (chartData) {
            let total = chartData?.teamStrength;
            let submittedPercent = (((chartData?.submitted) / total) * 100).toFixed(2);
            let notSubmittedPercent = (((chartData?.notSubmitted) / total) * 100).toFixed(2);
    
            setData({
                labels: ['Submitted', 'Not Submitted'],
                datasets: [
                    {
                        label: 'Team Strength',
                        data: [submittedPercent, notSubmittedPercent],
                        backgroundColor: [
                            'rgb(26, 147, 211)',
                            'rgb(243, 189, 90)'
                        ],
                        borderColor: [
                            'rgb(26, 147, 211)',
                            'rgb(243, 189, 90)'
                        ],
                        borderWidth: 1,
                    },
                ],
            });
        }
         // eslint-disable-next-line
    }, [chartData]);

    const options: ChartOptions<'pie'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false,
                position: 'right',
            },
            tooltip: {
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBgContainer,
                borderWidth: 1,
                padding: 10,
                cornerRadius: 5,
                titleColor: token.colorText,
                titleFont: {
                    weight: 500,
                    size: token.fontSizeSM,
                    family: token.fontFamily
                },

                bodyFont:{
                    weight: 500,
                    size: token.fontSizeSM,
                    family: token.fontFamily
                },
                bodyColor: token.colorText,
                callbacks: {
                    title: (xDatapoint: any) => {
                        return '';
                    },
                    label: (tooltipItem: any) => {
                        let label = tooltipItem.label || '';
                        if (label) {
                            label += ': ';
                        }
                        const value = tooltipItem.formattedValue;
                        label += value + '%'; 
                        return label;
                    },
                    labelTextColor: () => token.colorText,
                }
            }
        },
    };
    return (
        <Row gutter={[
            { xs: 8, sm: 16, md: 24, lg: 32 },
            { xs: 8, sm: 16, md: 24, lg: 32 },
        ]}>
            <Col className="gutter-row" span={12}>
                <Card className="teamCard" style={{ height: '430px', minHeight: '100%' }} bordered={false} loading={isPending}
                    title='Submitted v/s Not Submitted'>
                    {(chartData === null) && (
                        <div className='mt-4'>
                            <NoData />
                        </div>
                    )}
                    {(chartData !== null) && (
                        <Row>
                            <Col span={12} className="d-flex justify-content-center align-items-center">
                                <Pie data={data} options={options} height={300} width={300} />
                            </Col>
                            <Col className="my-4 p-0 d-flex justify-content-center align-items-center" span={12}>
                                <div className='pie-chart-label greyCard'>
                                    <Row>
                                        <Col span={6} className="mt-4">
                                            <div className="d-block">
                                                <Image className="imgchart " alt='commentor' src={chartIcon} preview={false} />
                                            </div>
                                        </Col>
                                        <Col span={18} className="px-2">
                                            <div className="d-block">
                                                <Text className="chart-text mb-0">{chartData?.teamStrength}</Text>
                                                <Title level={5} className="chart-subText m-0">Team Strength</Title>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col span={12} className="d-inline p-0">
                                            <div className="d-block mt-1">
                                                <div className="submittedIcon"></div><Title level={5} className="m-0 chart-subText"> Submitted</Title>
                                                <Text className="chart-text m-0">{chartData?.submitted}</Text>
                                            </div>
                                        </Col>
                                        <Col span={12} className="d-inline p-0">
                                            <div className="d-block  mt-1">
                                                <div className="notsubmittedIcon"></div><Title level={5} className="m-0 chart-subText">Not Submitted </Title>
                                                <Text className="chart-text m-0">{chartData?.notSubmitted}</Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    )}
                </Card>
            </Col>
            <Col className="gutter-row" span={12}>
                <PlanChartDisplay tab={tab} />
            </Col>
        </Row>

    );
};
export default ChartDisplay;
