import Layout, { Content } from 'antd/es/layout/layout'
import EmployeeHeader from '../partials/Header'
import { Outlet } from 'react-router-dom'
import TitleCard from '../shared/TitleCard'
import PmsProvider from '../../context/PmsProvider'

const EmployeeMain = () => {
    return (
        <PmsProvider>
            <Layout>
                <EmployeeHeader />
                <TitleCard />
                <Content className='px-5 py-4'>
                    <Outlet />
                </Content>
            </Layout>
        </PmsProvider>
    )
}

export default EmployeeMain
