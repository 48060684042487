import { useState } from "react";
import { Titles } from "../../shared/Titles";
import useDocumentTitle from "../../shared/useDocumentTitle";
import '../../../styles/user.scss';
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { Input, Row, Col, Button, Select, Form, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import DataTable from "../../shared/data-table/DataTable";
import { PaginationProvider } from "../../../context/PaginationProvider";
const { Text } = Typography;

const Users: React.FC = () => {

    useDocumentTitle(Titles.users);

    const [filterForm] = Form.useForm();
    const onFinish = () => {
    };

    const options = [
        {
            value: 'design',
            label: 'Design',
        },
        {
            value: 'between',
            label: 'Between',
        },
        {
            value: 'coimbatore',
            label: 'Coimbatore',
        },
        {
            value: 'sample',
            label: 'Sample',
        },
    ]
    const [searchKey, setSearchKey] = useState<string>('');

    return (
        <PaginationProvider>
            <Content>
                <Title level={4}>Users</Title>
                <Text className="d-block">
                    Search for filter employees based on their joining date, current
                    cycle, work location etc
                </Text>
                <Input
                    placeholder="Type name / ID / Reporting Manager"
                    className="userSearch"
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    suffix={<SearchOutlined style={{ color: '#476085', fontSize: 20 }} />}
                />

                <Title className="mt-4" level={4}>Filter by</Title>
                <Form className="mb-4" form={filterForm} name="basic" onFinish={onFinish}>
                    <Row justify="space-between">
                        <Col span={5}>
                            <Title level={5}>Department</Title>
                            <Form.Item
                                name="department"
                            >
                                <Select
                                    className="drop-down"
                                    placeholder="Select Department"
                                    options={options}

                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Title level={5}>Date of Joining</Title>
                            <Form.Item
                                name="doj"
                            >
                                <Select
                                    className="drop-down"
                                    placeholder="Select Date of Joining"
                                    options={options}

                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Title level={5}>Work Location</Title>
                            <Form.Item
                                name="workLocation"
                            >
                                <Select
                                    className="drop-down"
                                    placeholder="Select Work Locatoin"
                                    options={options}

                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Title level={5}>Current Cycle</Title>
                            <Form.Item
                                name="currentCycle"
                            >
                                <Select
                                    className="drop-down"
                                    placeholder="Select Current Cycle"
                                    options={options}

                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button className="filterBtn" type="primary" htmlType="submit" >Filter</Button>
                        </Col>
                    </Row>
                </Form>


                <DataTable />
            </Content>
        </PaginationProvider>
    );
};

export default Users;
