import React from 'react';
import { Modal, Form, Typography, Input, App } from 'antd';
import { usePmsContext } from '../../../context/PmsContext';
import { useMutate } from '../../../util/useRequestProcessor';

const { Paragraph } = Typography;
const { TextArea } = Input;

const Approval: React.FC = () => {
    const { approvalModal, setApprovalModal, profile, activeTab, stage } = usePmsContext();
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const { isPending, mutate: submitApproval } = useMutate({
        key: ["timeline", profile?.id],
        httpConfig: {
            method: "post",
            url: "goals/goal-submit",
        },
        customConfig: {
            callback: async (response: any) => {
                if (response?.error === null) {
                    message.success(response?.message, 4);
                    setApprovalModal(false);
                } else {
                    message.error(response?.error, 4);
                }
            },
            invalidateKey: [["timeline", profile?.id], ['baseCycles', profile?.id], ["goalObjective", profile?.id], ["comments", profile?.id]]
        },
    });

    return (
        <Modal
            open={approvalModal}
            onOk={form.submit}
            okText={"Submit"}
            onCancel={() => {
                setApprovalModal(false);
                form.resetFields();
            }}
            closable={false}
            centered={true}
            confirmLoading={isPending}
            maskClosable={false}
            title={activeTab ? "Submit for Supervisor Review" : "Submit for Approval"}
        >
            <Form
                form={form}
                layout="vertical"
                size='large'
                requiredMark={false}
                onFinish={submitApproval}
                preserve={false}
                initialValues={{
                    userId: profile?.id,
                    managerId: 0,
                    currentReviewCycleId: activeTab ?? 0,
                    comments: "",
                    reviewPrefix: null,
                    reviewSubmit: !!activeTab,
                }}
            >
                <Paragraph type='secondary' className='mb-2'>
                    {activeTab ? (
                        <>Enter your self-evaluation for {stage?.item?.reviewLabelAlter}</>
                    ) : (
                        <>Confirm that the Goals and Objectives can be sent for the Supervisor Approval?</>
                    )}
                </Paragraph>
                <Form.Item name="userId" hidden={true} >
                    <Input />
                </Form.Item>
                <Form.Item name="managerId" hidden={true} >
                    <Input />
                </Form.Item>
                <Form.Item name="currentReviewCycleId" hidden={true} >
                    <Input />
                </Form.Item>
                <Form.Item name="currentReviewCycleId" hidden={true} >
                    <Input />
                </Form.Item>
                <Form.Item name="reviewSubmit" hidden={true} >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="comments"
                    label="Comment"
                    rules={[
                        { required: !!activeTab, message: "This is a mandatory field" },
                        { max: 250, message: "Comment cannot be more than 250 characters." },
                        { whitespace: true, message: "This is a mandatory field" } 
                    ]}
                    hidden={!activeTab}
                >
                    <TextArea
                        placeholder="Please enter your comment"
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        showCount
                        maxLength={250}
                    />
                </Form.Item>
            </Form>
        </Modal >
    )
}

export default Approval;