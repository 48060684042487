import { createContext, useContext, useReducer, ReactNode } from "react";
import {
    SET_PAGE,
    SET_PAGE_SIZE,
    RESET_PAGINATION,
    SET_TOTAL,
    SET_FILTERS,
    SET_SORT_BY,
    RESET_FILTERS,
} from "./consts";

interface PaginationState {
    page: number;
    pageSize: number;
    total: number;
    filters: Record<string, any>;
    sort_by?: string;
    sort_order?: string;
}

interface PaginationAction {
    type: string;
    payload?: any;
}

interface PaginationContextType {
    pagination: PaginationState;
    setPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    setTotal: (totala: number) => void;
    resetPagination: () => void;
    setFilters: (key: string, value: any) => void;
    resetFilters: (key: string) => void;
    setSorter: (sortBy: string, sortOrder: string) => void;
}

const PaginationContext = createContext<PaginationContextType | undefined>(undefined);

const sortOrderMap: Record<string, string> = {
    ascend: "asc",
    descend: "desc",
};

const initialState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 1,
    filters: {},
};

const paginationReducer = (state: PaginationState, action: PaginationAction) => {
    switch (action.type) {
        case SET_PAGE:
            return { ...state, page: action.payload };
        case SET_PAGE_SIZE:
            return { ...state, pageSize: action.payload };
        case SET_TOTAL:
            return { ...state, total: action.payload };
        case SET_SORT_BY:
            return {
                ...state,
                sort_by: action.payload.sortBy,
                sort_order: sortOrderMap[action.payload.sortOrder],
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.key]: action.payload.value,
                },
            };
        case RESET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.key]: undefined,
                },
            };
        case RESET_PAGINATION:
            return initialState;
        default:
            return state;
    }
};

interface PaginationProviderProps {
    children: ReactNode;
}

const PaginationProvider = ({ children }: PaginationProviderProps) => {
    const [pagination, dispatch] = useReducer(paginationReducer, initialState);

    const setPage = (page: number) => {
        dispatch({ type: SET_PAGE, payload: page });
    };

    const setPageSize = (pageSize: number) => {
        dispatch({ type: SET_PAGE_SIZE, payload: pageSize });
    };

    const setTotal = (total: number) => {
        dispatch({ type: SET_TOTAL, payload: total });
    };

    const setFilters = (key: string, value: any) => {
        dispatch({ type: SET_FILTERS, payload: { key, value } });
    };

    const resetFilters = (key: string) => {
        dispatch({ type: RESET_FILTERS, payload: { key } });
    };

    const setSorter = (sortBy: string, sortOrder: string) => {
        dispatch({ type: SET_SORT_BY, payload: { sortBy, sortOrder } });
    };

    const resetPagination = () => {
        dispatch({ type: RESET_PAGINATION });
    };

    return (
        <PaginationContext.Provider
            value={{
                pagination,
                setPage,
                setPageSize,
                setTotal,
                resetPagination,
                setFilters,
                resetFilters,
                setSorter,
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
};

const usePagination = (): PaginationContextType => {
    const context = useContext(PaginationContext);
    if (!context) {
        throw new Error("usePagination must be used within a PaginationProvider");
    }
    return context;
};

export { PaginationProvider, usePagination };
