import { createContext, useContext } from 'react';
import { PmsContextType } from '../types/Pms';

const PmsContext = createContext<PmsContextType | undefined>(undefined);

export const usePmsContext = (): PmsContextType => {
    const context = useContext(PmsContext);
    if (!context) {
        throw new Error('usePmsContext must be used within an PmsProvider');
    }
    return context;
};

export default PmsContext;
