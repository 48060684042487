import moment from "moment";

export enum ROLE {
    EMPLOYEE = "ROLE_EMPLOYEE",
    ADMIN = "ROLE_PEOPLEDESK ADMIN",
    MANAGER = "ROLE_REPORTING MANAGER",
    APPROVER = "ROLE_FINANCE DEPARTMENT APPROVER"
}

export const dateTimeFormat: Record<string, string> = {
    //Display
    date: "MMM D, YYYY",
    date1: "D MMM, YYYY",
    date2: "DD/MM/YYYY",
    time: "h:mm A",
    dateTime: "MMM D, YYYY h:mm A",
    //Input
    dateInput: "YYYY-MM-DD",
    dateTimeInput: "YYYY-MM-DD HH:mm:ss"
}

export const dateTime = (value: string, format: string, custom: string | null = null) => {
    if (!value || value === "" || (!format && !custom)) return "";

    if (custom) return moment(value).format(custom);

    return dateTimeFormat[format] && moment(value).format(dateTimeFormat[format]);
};