import React, { useEffect, useState } from 'react';
import './../../styles/employeeDashboard.scss'
import { Card, Col, Row, theme } from "antd";
import "../../styles/my-team.scss";
import { useMutate } from "../../util/useRequestProcessor";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import useAuth from '../../hooks/useAuth';
import { usePmsContext } from '../../context/PmsContext';
import NoData from '../../layouts/shared/NoData';

ChartJS.register(ArcElement, Tooltip, Legend);
interface ChartDataItem {
    name: string;
    count: number;
}
interface Props {
    tab: string
};
const PlanChartDisplay: React.FC<Props> = (tab: any) => {
    const [topPerformanceCard, setTopPerformanceCard] = useState<ChartDataItem[]>();
    const [chartData, setChartData] = useState<number[]>([0, 0, 0]);
    const cycleId = tab.tab.tab;
    let dataPayload;
    const { user } = useAuth();
    const { performerDetails } = usePmsContext();
    const { token } = theme.useToken();

    const { isPending, mutate: team = dataPayload } = useMutate({
        key: ['topPerformance', user?.id],
        httpConfig: {
            method: "post",
            url: "goals/my-team/review-performers",
        },
        customConfig: {
            form: dataPayload,
            callback: async (response: any) => {
                setTopPerformanceCard(response?.data?.percentageResults);
            },
        },
    });
    useEffect(() => {
        let dataPayload = {
            userId: user?.id,
            rootCycleId: 1,
            reviewTimelineId: Number(cycleId),
            fromAchievement: true
        };
        team(dataPayload);
        // eslint-disable-next-line
    }, [team, tab, performerDetails])

    useEffect(() => {
        if (topPerformanceCard) {
            const counts = [0, 0, 0];
            topPerformanceCard.forEach((item) => {
                switch (item.name) {
                    case "Above Plan":
                        counts[0] = item.count;
                        break;
                    case "In Plan":
                        counts[1] = item.count;
                        break;
                    case "Below Plan":
                        counts[2] = item.count;
                        break;
                    default:
                        break;
                }
            });
            const totalCount = counts?.reduce((acc, curr) => acc + curr, 0);
            const percentageData = counts?.map(count => parseFloat(((count / totalCount) * 100).toFixed(2)));
            setChartData(percentageData);
        }
        // eslint-disable-next-line
    }, [topPerformanceCard])

    const data = {
        labels: ['Above Plan', 'In Plan', 'Below Plan'],
        datasets: [
            {
                label: 'Plan Review',
                data: chartData,
                backgroundColor: [
                    'rgba(72, 171, 138, 1)',
                    'rgb(243, 189, 90, 1)',
                    'rgba(254, 138, 100, 1)'
                ],
                borderColor: [
                    'rgba(72, 171, 138, 1)',
                    'rgb(243, 189, 90, 1)',
                    'rgba(254, 138, 100, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const options: ChartOptions<'pie'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                onClick: () => { },
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 30,
                    color: token.colorText,
                    font: {
                        weight: 500,
                        size: 14,
                        family: token.fontFamily
                    }
                }
            },
            tooltip: {
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBgContainer,
                borderWidth: 1,
                padding: 10,
                cornerRadius: 5,
                titleColor: token.colorText,
                titleFont: {
                    weight: 500,
                    size: token.fontSizeSM,
                    family: token.fontFamily
                },
                bodyFont: {
                    weight: 500,
                    size: token.fontSizeSM,
                    family: token.fontFamily
                },
                bodyColor: token.colorText,
                callbacks: {
                    title: (xDatapoint: any) => {
                        return '';
                    },
                    label: (tooltipItem: any) => {
                        let label = tooltipItem.label || '';
                        if (label) {
                            label += ': ';
                        }
                        const value = tooltipItem.formattedValue;
                        label += value + '%';
                        return label;
                    },
                    labelTextColor: () => token.colorText,
                }
            }
        },
    };
    return (
        // <Col span={12}>
        <Card style={{ minHeight: '100%' }} loading={isPending} bordered={false} title={"Plan Review"}>
            {topPerformanceCard?.every(item => item.count === 0) ? (
                <div className='mt-4'>
                    <NoData />
                </div>
            ) : (

                <Row gutter={[8, 8]}>
                    <Col span={20}>
                        <Pie data={data} options={options} height={300} width={300} />
                    </Col>
                </Row>
            )}
        </Card>
        // </Col>
    );
};

export default PlanChartDisplay;
