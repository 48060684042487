import React from "react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Image, Input, Typography, App } from "antd";

import logo from "../../assets/images/logo.svg";
import { useMutate } from "../../util/useRequestProcessor";
import { useAuthContext } from "../../context/AuthContext";
import Password from "antd/lib/input/Password";

const LoginForm: React.FC = () => {
    const { Title, Paragraph } = Typography,
        [form] = Form.useForm();
    const { login } = useAuthContext();
    const { message } = App.useApp();

    const { isPending, mutate: doLogin } = useMutate({
        key: "login",
        httpConfig: {
            method: "post",
            url: "auth/signin",
        },
        customConfig: {
            form: form,
            callback: async (response: any) => {
                if (response?.data) {
                    login({
                        ...{
                            role: response?.data?.roles,
                            name: response?.data?.username
                        },
                        ...response?.data,
                    });
                } else {
                    message.error('Oops! Something Went Wrong')
                }
            },
        },
    });

    return (
        <Flex justify="center" align="center" style={{ height: "100vh" }} vertical>
            <Flex vertical className="border p-5 rounded ">
                <Image height={30} src={logo} preview={false} width={150} />
                <Title className="mt-3" level={3}>
                    Login to PMS!
                </Title>

                <Paragraph type="secondary">
                    Enter your existing Email ID and Password to login
                </Paragraph>
                <Form
                    size="large"
                    form={form}
                    style={{ width: 400 }}
                    onFinish={doLogin}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                            {
                                pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
                                message: "Enter a valid Email ID",
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Email ID"
                        />
                    </Form.Item>
                    <Form.Item
                        className="mt-3"
                        name="password"
                        rules={[{ required: true, message: "This is a mandatory field" }]}
                    >
                        <Password
                            className="password"
                            prefix={<LockOutlined />}
                            placeholder="Enter password"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item className="mt-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={isPending}
                            block
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Flex>
        </Flex>
    );
};

export default LoginForm;
