import React, { useState, useEffect } from 'react';
import { Drawer, Form, Input, Select, InputNumber, App, Row, Col, Button, Typography } from 'antd';
import { usePmsContext } from '../../../context/PmsContext';
import useAuth from '../../../hooks/useAuth';
import { useMutate } from '../../../util/useRequestProcessor';

const { TextArea } = Input;
const { Text } = Typography;

const Goals: React.FC = () => {
    const { goalModal, setGoalModal, measurement, current, setCurrent, rootCycleId } = usePmsContext();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { user } = useAuth();
    const [measure, setMeasure] = useState<any[]>([]);
    const [targetSuffix, setTargetSuffix] = useState({});
    const [targetMax, setTargetMax] = useState<number>(999999999999);

    const setSuffix = (value: any) => {
        let suffix = measurement?.filter((item: any) => item.id === value)[0]?.label;
        setTargetSuffix(suffix ? { addonAfter: <span className="rupeeClass">{suffix}</span> } : {});
    }

    useEffect(() => {
        setMeasure(measurement?.map((item: any) => ({
            label: item?.name + (item?.label ? (" (" + item?.label + ")") : ''),
            value: item?.id,
            className: 'rupeeClass'
        })));
        if (current?.id) {
            setSuffix(current?.goalSettingDto?.measurement?.id)
        }
        // eslint-disable-next-line
    }, [measurement, current]);

    useEffect(() => {
        let initialValue = current?.id ? {
            id: current?.goalSettingDto?.id,
            objective: current?.goalSettingDto?.objective,
            description: current?.goalSettingDto?.description,
            measurementId: current?.goalSettingDto?.measurement?.id,
            target: current?.goalSettingDto?.target,
            reviewCycleId: rootCycleId
        } : { id: null, reviewCycleId: rootCycleId }
        form.setFieldsValue(initialValue);
        // eslint-disable-next-line
    }, [current]);

    useEffect(() => {
        if (form.getFieldValue('measurementId') === 1) {
            setTargetMax(100);
        } else {
            setTargetMax(999999999999);
        }
        // eslint-disable-next-line
    }, [form.getFieldValue('measurementId')]);


    const onCancel = () => {
        setCurrent({});
        form.resetFields();
        setGoalModal(false);
    }

    const { isPending, mutate: doCreate } = useMutate({
        key: ["goalObjective", user?.id],
        httpConfig: {
            method: current?.id ? "post" : "post",
            url: current?.id ? "/goals/update/" + current?.id : "/goals/create",
        },
        customConfig: {
            form: form,
            callback: async (response: any) => {
                if (response?.error === null) {
                    message.success(response?.message, 4);
                    onCancel();
                } else {
                    message.error(response?.error, 4);
                }
            },
            invalidateKey: [["goalObjective", user?.id]],
        },
    });

    return (
        <Drawer
            title={
                current?.id
                    ? <>Edit G&O <Text type='secondary'>(2024-2025)</Text></>
                    : <>Add G&O <Text type='secondary'>(2024-2025)</Text></>
            }
            open={goalModal}
            width={720}
            onClose={onCancel}
            maskClosable={false}
            destroyOnClose={true}
            footer={
                <Row gutter={16} justify={"end"}>
                    <Col><Button type='primary' ghost size='large' onClick={onCancel}>Cancel</Button></Col>
                    <Col><Button type='primary' size='large' onClick={form.submit} loading={isPending}>{current?.id ? "Update" : "Add"}</Button></Col>
                </Row>
            }
        >
            <Form
                form={form}
                layout="vertical"
                size='large'
                requiredMark={false}
                onFinish={doCreate}
                key={current?.id ?? "AddGoals"}
            >
                <Form.Item name="userId" hidden={true} initialValue={user?.id}>
                    <Input />
                </Form.Item>
                <Form.Item name="reviewCycleId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="id" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="objective"
                    label="Objective"
                    rules={[
                        { required: true, message: "This is a mandatory field" },
                        { max: 75, message: "Objective cannot be more than 75 characters" },
                        { whitespace: true, message: "This is a mandatory field" }
                    ]}
                    extra={'Ex: Learning, Increase Annual Sales, ...'}
                >
                    <TextArea
                        placeholder="Please enter the Objective"
                        autoSize={{ minRows: 2, maxRows: 5 }}
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                        { required: true, message: "This is a mandatory field" },
                        { max: 250, message: "Description cannot be more than 250 characters" },
                        { whitespace: true, message: "This is a mandatory field" }
                    ]}
                >
                    <TextArea
                        placeholder="Please enter the Description"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                </Form.Item>
                <Form.Item
                    name="measurementId"
                    label="Measurement Criteria (UOM)"
                    rules={[
                        { required: true, message: "This is a mandatory field" }
                    ]}
                    extra={"UOM - Unit of Measurement"}
                >
                    <Select options={measure} className='rupeeClass' placeholder="Select an Measurement Criteria" onSelect={setSuffix} />
                </Form.Item>
                <Form.Item
                    name="target"
                    label="Target"
                    rules={[
                        { required: true, message: "This is a mandatory field" },
                        { max: targetMax, type: 'number', message: 'Maximum Limit Exceeds' },
                        { min: 1, type: 'number', message: 'Target must be greater than Zero' }
                    ]}
                >
                    <InputNumber
                        placeholder="Please enter the Target"
                        className='w-100'
                        {...targetSuffix}
                    />
                </Form.Item>
            </Form>
        </Drawer >
    )
}

export default Goals;