import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Typography, Image, Flex, Avatar, Input } from 'antd';
import '../../styles/my-team.scss'
import commentImg from '../../assets/images/comment.svg'
import commentImg2 from '../../assets/images/comment2.svg'
import { Container } from 'react-bootstrap';
import Review from './models/Review';
import { useApi } from '../../util/useRequestProcessor';
import useAuth from '../../hooks/useAuth';
import { usePmsContext } from '../../context/PmsContext';
import NoData from '../../layouts/shared/NoData';
import ConfirmationModal from '../shared/ConfirmationModal';
const { Text, Title, Paragraph } = Typography;
const { Search } = Input;

interface Props {
    tab: string;
    teamUserId?: any
};


const TeamPerformance: React.FC<Props> = (tab: any) => {

    const { user } = useAuth();
    const {performerDetails, setPerformerDetails}= usePmsContext();
    const cycleID = tab.tab;
    const { data, refetch, isLoading } = useApi({
        key: ['team-performance', user?.id],
        httpConfig: {
            method: 'get',
            url: `team/performance/${user?.id}/${cycleID}`
        }
    })

    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        if(cycleID){
            refetch();
        }
        // eslint-disable-next-line
    }, [cycleID]);

    useEffect(() => {
        const Data: any = data?.data
        if (Data) {
            setPerformerDetails(Data);
        } else {
            setPerformerDetails(null)
        }
        // eslint-disable-next-line
    }, [data])

    const filteredPerformers = performerDetails?.filter((performer: any) =>
        performer?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    const handleSearchChange = (event: any) => {
        setSearchQuery(event?.target?.value);
    };
    return (
        <>
            <Card  bordered={false} className='PerformanceScroll pb-5' title={
                <Row align="middle">
                    <Col flex={1}>Supervisor Review <span className='countTeamPerformance'>{filteredPerformers?.length ? filteredPerformers?.length : 0}</span></Col>
                    <Col><Search className='px-3' placeholder="Search by name" hidden={filteredPerformers?.length===undefined}  onChange={handleSearchChange} /></Col>
                </Row>
            }
            extra={     
                    <ConfirmationModal model='extension'/>
            }>
                <div >
                    {(filteredPerformers !== null && filteredPerformers?.length !== 0 && filteredPerformers !== undefined) ? (
                        <>
                            {Array?.isArray(filteredPerformers) && filteredPerformers?.map((item: any, index: any) => (
                                <Card key={item?.timeLineId} loading={isLoading} className={`teamSubCard ${index === 0 ? 'mt-1' : ''}`}>
                                    <Row justify="space-between" align="middle">
                                        <Col span={20}>
                                            <Row className='bordered'>
                                                <Col span={8} className='py-4 px-3 right-bordered d-flex align-items-center justify-content-center'>
                                                    <Flex className='d-flex align-items-center'>
                                                        <Avatar src={item?.profileImgUrl} size={100} className='me-3'>{item?.name?.charAt(0)?.toUpperCase()}</Avatar>
                                                        <Text>
                                                            <Title level={4} className='d-block greyTxt'>{item?.name}</Title>
                                                            <Paragraph type='secondary' className='mb-2'>Emp ID - {item?.employeeId}</Paragraph>
                                                            <Paragraph type='secondary' className='mb-2'>Role - {item?.designation}</Paragraph>
                                                        </Text>
                                                    </Flex>
                                                </Col>
                                                <Col span={16} className='px-3 right-bordered d-flex align-items-center'>
                                                    <Flex className='centerProgressCard' align='middle'>
                                                        <Container className='px-1'>
                                                            <Row className='mt-3'>
                                                                <Col span={10}>
                                                                    <Text className='d-block greyTxt'>No of Objectives</Text>
                                                                    <Title className='mt-2 d-inline objectiveText' level={5}>{item?.totalObjectives ? item?.totalObjectives : '0'}</Title>
                                                                    {tab.tab === '13' && (
                                                                        <Review model="view" key={item?.id} teamMateData={item} tab={tab} />
                                                                    )}
                                                                </Col>
                                                                <Col span={10} offset={2}>
                                                                    <Text className='d-block greyTxt'>Overall Progress</Text>
                                                                    <Title className='mt-2 d-inline objectiveText' level={5}>{item?.overAllProgress ? item?.overAllProgress + ' %' : '0'}</Title>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        <Container className='pl-5 d-flex align-items-center justify-content-start'>
                                                            <Flex className='outer-progress-card'>
                                                                <Text className='inner-progress-card ipc1'>
                                                                    <Title level={2} className=''>{item?.yetToStartCount ?? 0}</Title>
                                                                    <Text>Yet to Start</Text>
                                                                </Text>
                                                                <Text className='inner-progress-card ipc2'>
                                                                    <Title level={2}>{item?.inProgressCount ?? 0}</Title>
                                                                    <Text>Inprogress</Text>
                                                                </Text>
                                                                <Text className='inner-progress-card ipc3'>
                                                                    <Title level={2}>{item?.completedCount ?? 0}</Title>
                                                                    <Text>Completed</Text>
                                                                </Text>
                                                                <Text className='inner-progress-card ipc4'>
                                                                    <Title level={2}>{item?.onHoldCount}</Title>
                                                                    <Text>On Hold</Text>
                                                                </Text>
                                                            </Flex>
                                                        </Container>
                                                    </Flex>
                                                </Col>
                                            </Row>
                                            <Row className='px-3 py-2 right-bordered'>
                                                <Col span={11} className='d-flex justify-content-start'>
                                                    {item?.reportingManagerComment && (
                                                        <>
                                                            <Image src={commentImg} style={{width:'auto'}} height={15} preview={false} />
                                                            <Text className='sub-head1 px-2 mt-1' style={{lineHeight:'20px !important'}}>{item?.reportingManagerComment}</Text>
                                                        </>
                                                    )}
                                                </Col>
                                                <Col span={11} offset={2} className='d-flex justify-content-end'>
                                                    {item?.performanceCommet && (
                                                        <>
                                                            <Text className='sub-head1 px-2 mt-1' style={{lineHeight:'20px !important'}}>{item?.performanceCommet}</Text>
                                                            <Image src={commentImg2} height={15} style={{width:'auto'}} preview={false} />
                                                        </>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4} className={item?.status === true ? 'inPlan' : 'review'}>
                                            {item?.status === false && (
                                                // <Image className='btn reviewBtn' src={reviewBtn} preview={false} />
                                                <Review model="review" key={item?.id} teamMateData={item} tab={tab} />

                                            )}
                                            {item?.status === true && (
                                                <>
                                                    <Review model="reviewEdit" key={item?.id} teamMateData={item} tab={tab} />

                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </>
                    ) : (
                        <div className='mt-4'>
                            <NoData/>
                        </div>
                    )}
                </div>
            </Card>
        </>
    )
}

export default TeamPerformance;