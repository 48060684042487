import { Menu, MenuProps } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import { ROLE } from '../../util/Common';

const style: React.CSSProperties = { lineHeight: '46px', fontSize: '16px', background: '#0A93D5' }

interface IMenu {
    label: string;
    key: string;
    path: string;
    requiredRoles: string[];
}

const menus: IMenu[] = [
    {
        label: 'Dashboard',
        key: 'admin',
        path: '/admin',
        requiredRoles: [ROLE.ADMIN]
    },
    {
        label: 'Configure Review Cycles',
        key: 'configure-review',
        path: '/admin/configure-review',
        requiredRoles: [ROLE.ADMIN]
    },
    {
        label: 'Users',
        key: 'users',
        path: '/admin/users',
        requiredRoles: [ROLE.ADMIN]
    },
    {
        label: 'Extension Management',
        key: 'ExtensionManagement',
        path: '/admin/ExtensionManagement',
        requiredRoles: [ROLE.ADMIN]
    },
    {
        label: 'PMS',
        key: 'pms',
        path: '/',
        requiredRoles: [ROLE.EMPLOYEE]
    },
    {
        label: 'My Team',
        key: 'my-team',
        path: '/my-team',
        requiredRoles: [ROLE.MANAGER]
    },
]

const TopMenu = () => {
    const [current, setCurrent] = useState<string>('pms');
    const { pathname } = useLocation();
    const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);
    const { user } = useAuth();

    useEffect(() => {
        setMenuItems(menus.filter((item) =>
            item.requiredRoles.some(role => user?.role?.includes(role))
        ).map((item) => {
            return {
                'label': <Link to={item.path}>{item.label}</Link>,
                'key': item.key
            }
        }));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let segments = pathname?.split('/')?.filter(segment => segment !== '');
        let path = segments.length > 0 ? segments.pop() : 'pms';

        setCurrent(path as string);
    }, [pathname]);

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };

    return (
        <>
            <Menu style={style} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menuItems} theme="light" />
        </>
    )
}

export default TopMenu
