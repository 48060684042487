import React, { useEffect, useRef, forwardRef } from "react";
import { Card, Steps, Image } from "antd";
import { Container } from "react-bootstrap";
import './../../styles/employeeDashboard.scss';
import circleImg from '../../assets/images/waitingCircle.svg';
import blueTargetImg from '../../assets/images/targetBlue.svg';
import orgTargetImg from '../../assets/images/targetOrg.svg';
import greenTargetImg from '../../assets/images/targetGreen.svg';
import aprrovalOrgImg from '../../assets/images/approvalOrg.svg';
import aprrovalGreenImg from '../../assets/images/approvalGreen.svg';
import selfOrgImg from '../../assets/images/selfReviewOrg.svg';
import selfGreenImg from '../../assets/images/selfReviewGreen.svg';
import supervisorOrgImg from '../../assets/images/supReviewOrg.svg';
import supervisorGreenImg from '../../assets/images/supReviewGreen.svg';
import { useApi } from '../../util/useRequestProcessor';
import { dateTime } from "../../util/Common";
import { usePmsContext } from "../../context/PmsContext";
import { TimelineItem } from "../../types/Pms";
import NoData from "../../layouts/shared/NoData";

const { Step } = Steps;

// ForwardRef component for Steps
const ForwardedSteps = forwardRef<HTMLDivElement, any>((props, ref) => (
    <div ref={ref}>
        <Steps {...props} />
    </div>
));

const TimeLineComponent: React.FC = () => {
    const { timeline, setTimeline, stage, setStage, profile } = usePmsContext();
    const stepsRef = useRef<HTMLDivElement>(null);

    const { data, isLoading } = useApi({
        key: ['timeline', profile?.id],
        httpConfig: {
            method: 'get',
            url: `timeline/${profile?.id}`
        },
        queryConfig: {
            enabled: !!profile?.id,
            refetchInterval: 60000
        }
    });

    useEffect(() => {
        if (data?.data) {
            const groupedItems = data?.data?.reduce((accumulator: any, item: TimelineItem) => {
                const category = item.timelineStatus;
                if (category === null) return accumulator;

                accumulator[category] = {
                    category: item.level,
                    item: item
                };
                return accumulator;
            }, {});
            
            if (groupedItems?.STARTED) {
                setStage({
                    current: groupedItems?.STARTED?.category,
                    status: 'process',
                    item: groupedItems?.STARTED?.item
                });
            } else if (groupedItems?.NOTSTARTED) {
                setStage({
                    current: groupedItems?.NOTSTARTED?.category,
                    status: 'wait',
                    item: groupedItems?.NOTSTARTED?.item
                });
            } else if (groupedItems?.COMPLETED) {
                setStage({
                    current: groupedItems?.COMPLETED?.category,
                    status: 'finish',
                    item: groupedItems?.COMPLETED?.item
                });
            } else if (groupedItems?.WAITING) {
                setStage({
                    current: groupedItems?.WAITING?.category,
                    status: 'wait',
                    item: groupedItems?.WAITING?.item
                });
            }
            setTimeline?.(data?.data);
        }
    }, [data, setStage, setTimeline]);

    useEffect(() => {
        if (stepsRef.current && stage) {
            const activeStep = stepsRef.current.querySelector('.ant-steps-item-process, .ant-steps-item-wait');
            if (activeStep) {
                activeStep.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }, [stage]);

    const renderIcon = (status: 'COMPLETED' | 'STARTED' | null, index: number) => {
        if (status === null && index !== 0) {
            return <Image className='timeLineImg waitImg' src={circleImg} alt='Waiting' preview={false} />;
        } else if (status === null && index === 0) {
            return <Image className='timeLineImg' src={blueTargetImg} alt="timeLineImg" preview={false} />;
        } else {
            if (index === 0) {
                return <Image className='timeLineImg' src={status === 'COMPLETED' ? greenTargetImg : orgTargetImg} alt="timeLineImg" preview={false} />;
            } else if (index === 1) {
                return <Image className='timeLineImg' src={status === 'COMPLETED' ? aprrovalGreenImg : aprrovalOrgImg} alt="timeLineImg" preview={false} />;
            } else if (index === 2 || index === 4 || index === 6 || index === 8) {
                return <Image className='timeLineImg' src={status === 'COMPLETED' ? selfGreenImg : selfOrgImg} alt="timeLineImg" preview={false} />;
            } else if (index === 3 || index === 5 || index === 7 || index === 9) {
                return <Image className='timeLineImg' src={status === 'COMPLETED' ? supervisorGreenImg : supervisorOrgImg} alt="timeLineImg" preview={false} />;
            }
        }
    };

    return (
        <Card bordered={false} loading={isLoading} title='Performance Appraisal Timeline' className="header-border">
            {(timeline === undefined) && (
                <NoData />
            )}
            {(timeline !== undefined) && (
                <Container className='stepContainer'>
                    <ForwardedSteps ref={stepsRef} labelPlacement="vertical" {...stage}>
                        {timeline?.map((step: any, index: any) => (
                            <Step
                                key={index}
                                title={step.reviewName}
                                description={step?.reviewCycleDateList[0]?.isActive ? (dateTime(step?.reviewCycleDateList[0]?.startDate, 'date1') + ' - ' + dateTime(step?.reviewCycleDateList[0]?.endDate, 'date1')) : '-'}
                                icon={renderIcon(step.timelineStatus, index)}
                            />
                        ))}
                    </ForwardedSteps>
                </Container>
            )}
        </Card>
    );
}

export default TimeLineComponent;
