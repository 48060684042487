import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Tabs } from 'antd'
import { usePmsContext } from "../../context/PmsContext";
import { useApi } from "../../util/useRequestProcessor";
import TabContent from "./TabContent";
import { IComments, Review } from "../../types/Pms";
import MarkProgress from "./forms/markProgress";

const { Title } = Typography;

const ReviewInsightCard: React.FC = () => {

    const { baseCycles, activeTab, setActiveTab, rootCycleId, profile, comments, setComments, setActiveComment, setObjectiveData } = usePmsContext();
    const [tabs, setTabs] = useState<any[]>([]);

    const { data: commentsData } = useApi({
        key: ["comments", profile?.id],
        httpConfig: {
            method: "get",
            url: `goals/view/comments/${profile?.id}/${rootCycleId}`
        },
        queryConfig: {
            refetchInterval: 30000,
            enabled: (!!profile?.id && !!rootCycleId)
        }
    });

    useEffect(() => {
        if (commentsData?.data) {
            let result = commentsData?.data?.commentsDtoList;

            //Adding Supervisor comments to the list
            if (commentsData?.data?.supervisorApprovalResponses?.length > 0) {
                let supervisorCommment = commentsData.data?.supervisorApprovalResponses[0];
                result = [{
                    id: -1,
                    reviewName: supervisorCommment?.reviewName,
                    commentsDtoList: [{
                        approvedComment: true,
                        commentedBy: supervisorCommment?.approvedBy,
                        comments: supervisorCommment?.comments
                    }]
                }].concat(result);
            }
            setComments(result);
        }
        // eslint-disable-next-line
    }, [commentsData]);

    useEffect(() => {
        let item: any = comments?.filter((item: IComments) => item?.id === Number(activeTab));
        setActiveComment(item?.length > 0 ? item[0] : []);
        // eslint-disable-next-line
    }, [comments, activeTab]);

    useEffect(() => {
        if (baseCycles?.length > 0) {
            setTabs(baseCycles?.map((item: any) => ({
                label: item?.reviewName,
                key: item?.id?.toString(),
                children: <TabContent />,
                disabled: (item?.status === 'YET_TO_START')
            })));

            let active: Review | undefined = baseCycles?.filter((item: any) => item?.status === 'STARTED')?.pop();
            if (!active) {
                active = baseCycles?.filter((item: any) => item?.status === 'COMPLETED')?.pop();
            }
            if (active) {
                setActiveTab(active?.id?.toString());
                setObjectiveData({
                    userId: profile?.id,
                    rootCycleId: rootCycleId,
                    reviewTimelineId: active?.id,
                    fromAchievement: true
                });
            }
        }
        // eslint-disable-next-line
    }, [baseCycles]);

    const onTabChange = (active: string) => {
        setActiveTab(active);
        setObjectiveData({
            userId: profile?.id,
            rootCycleId: rootCycleId,
            reviewTimelineId: Number(active),
            fromAchievement: true
        });
    }

    return (
        <Row>
            <Col span={24}>
                <Title level={3} className="mb-2">Review Insight</Title>
            </Col>
            <Col span={24}>
                <Tabs
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    items={tabs}
                    onChange={onTabChange}
                />
                <MarkProgress />
            </Col>
        </Row>
    )
}

export default ReviewInsightCard;