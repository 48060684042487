import { httpInstance } from "../util/http";

export const fetchNewToken = async () => {

    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) return null;

    try {
        const token: any = await httpInstance
            .post("/auth/refreshtoken", { token: refreshToken })
            .then(res => res.data.data);
        return token;
    } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
        return null;
    }
};

export const refreshAuth = async (failedRequest: any) => {
    const response = await fetchNewToken();

    if (!response)
        return Promise.reject();

    const { accessToken, refreshToken } = response;

    if (accessToken) {
        failedRequest.response.config.headers.Authorization = "Bearer " + accessToken;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        // you can set your token in storage too
        // setToken({ token: newToken });
        return Promise.resolve(accessToken);
    } else {
        // you can redirect to login page here
        // router.push("/login");
        return Promise.reject();
    }
};