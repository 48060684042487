import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App, ConfigProvider } from "antd";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './styles/common.scss'
import RouteComponent from './routing/RouteComponent';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: "'IBM Plex Sans', sans-serif",
                        fontSize: 16,
                        fontSizeSM: 14,
                        fontWeightStrong: 500,
                        colorPrimary: "#0088DD",
                        colorInfo: "#7a7dfb",
                        colorWarning: "#ffa305",
                        colorSuccess: "#03bf7a",
                        colorError: "#E64141",
                        colorSuccessBg: "#E1EDE9",
                        borderRadius: 4,
                        borderRadiusLG: 8,
                        sizeStep: 6,
                        sizeUnit: 6,
                        colorBgContainer: "#ffffff",
                        colorPrimaryBg: "#c4cdd5",
                        colorLink: "#0095DA",
                        colorText: "#454F5B",
                        colorTextDescription: "#454F5B",
                        colorTextHeading: '#454F5B',
                        margin: 16,
                    },
                    components: {
                        Form: {
                            algorithm: true,
                            itemMarginBottom: 20,
                            verticalLabelPadding: 0,
                            labelColor: "#454F5B"
                        },
                        Modal: {
                            titleFontSize: 20
                        },
                        Drawer: {
                            paddingLG: 24,
                            footerPaddingBlock: 16,
                            footerPaddingInline: 24,
                            fontSizeHeading3: 20
                        },
                        Table: {
                            colorPrimary: "#454F5B",
                            algorithm: true,
                        },
                        Progress: {
                            colorSuccess: "#03BF7A",
                            colorError: "#E64141",
                            remainingColor: '#FFFFFF',
                            defaultColor: "#7A7DFB"
                        },
                        Layout: {
                            headerBg: "#FFFFFF",
                            headerHeight: 104,
                            bodyBg: "#FBFBFB",
                            colorBgContainer: "#fff",
                            colorBgLayout: "#fff",
                        },
                        Input: {
                            paddingInline: 12,
                            fontSizeLG: 16,
                        },
                        InputNumber: {
                            paddingInline: 12,
                            fontSizeLG: 16,
                        },
                        Select: {
                            optionPadding: 12,
                            controlPaddingHorizontal: 10,
                            paddingContentHorizontal: 10,
                            showArrowPaddingInlineEnd: 12,
                            fontSizeLG: 16,
                            fontWeightStrong: 500
                        },
                        Tabs: {
                            horizontalItemPadding: "10px 40px",
                            inkBarColor: "#0095DA",
                            itemColor: "#637381",
                            colorBorderSecondary: "#DFE3E8",
                            itemSelectedColor: "#454F5B",
                            itemActiveColor: "#454F5B",
                            itemHoverColor: "#454F5B",
                            titleFontSize: 18,
                            horizontalItemGutter: 0
                        },
                        Card: {
                            headerBg: '#FFFFFF',
                            headerFontSize: 18,
                            tabsMarginBottom: 0,
                            padding: 15,
                            paddingLG: 20,
                            colorBorderSecondary: 'rgba(0, 0, 0, 0.22)',
                            borderRadius: 8,
                            boxShadowTertiary: '4px 4px 4px 4px rgba(0, 0, 0, 0.06)'
                        },
                        Menu: {
                            itemBg: '#07449B',
                            itemColor: '#fff',
                            itemHoverColor: '#fff',
                            itemSelectedColor: '#fff !important',
                            horizontalItemSelectedColor: '#fff',
                            itemPaddingInline: 48,
                            activeBarHeight: 4
                        },
                        Button: {
                            colorPrimary: '#1A93D3',
                            colorPrimaryBorder: '#1A93D3',
                            colorPrimaryHover: '#1A93D3',
                            paddingInline: 20,
                            defaultShadow:'#0088dd45'
                        },
                        Steps: {
                            dotSize: 44,
                            dotCurrentSize: 44,
                            colorTextDescription: "#637381",
                            fontSizeLG: 16
                        },
                        Statistic: {
                            contentFontSize: 20,
                        },
                        Collapse: {
                            headerPadding: '12px 16px',
                            contentPadding: '12px 16px'
                        },
                        Slider: {
                            controlSize: 20,
                            dotSize: 6,
                            handleSize: 20,
                            dotActiveBorderColor: "#BDBDBD",
                            handleActiveColor: "#48AB8A",
                            handleColor: "#48AB8A",
                            handleLineWidthHover: 6,
                            railSize: 6,
                            trackBg: "#03bf7a",
                            trackHoverBg: "#03bf7a"
                        },
                        Result: {
                            iconFontSize: 200,
                            subtitleFontSize: 16,
                            titleFontSize: 22,
                            extraMargin: '16px 0 0 0'
                        }
                    }
                }}
            >
                <App>
                    <QueryClientProvider client={queryClient}>
                        <RouteComponent />
                    </QueryClientProvider>
                </App>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>
);