import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { dateTime } from '../../../util/Common';
import { useApi } from '../../../util/useRequestProcessor';
import { usePagination } from '../../../context/PaginationProvider';
import ConfirmationModal from '../ConfirmationModal';
import { usePmsContext } from '../../../context/PmsContext';

interface Props {
    searchKey: string;
}

const ExtensionTable: React.FC<Props> = ({ searchKey }) => {
    const { pagination, setTotal, setPage, setSorter, setPageSize } = usePagination();
    const { page, pageSize, sort_by, sort_order } = pagination;
    const { setExtensionExport, extensionFilters } = usePmsContext();
    const [response, setResponse] = useState<any[]>([]);

    const [paginationConfig, setPaginationConfig] = useState({
        current: page,
        pageSize: pageSize,
        total: 0,
        showTotal: (total: number, range: [number, number]) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50"],
        locale: { items_per_page: "" },
        onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize)
            setPaginationConfig((prev) => ({
                ...prev,
                current: page,
                pageSize: pageSize,
            }));
        },
        onShowSizeChange: (current: number, size: number) => {
            setPage(current);
            setPageSize(pageSize);
            setPaginationConfig((prev) => ({
                ...prev,
                current: current,
                pageSize: size,
            }));
        },
    });

    const handleTableChange = (pagination: any, __: any, sorter: any) => {
        const { column, columnKey } = sorter;
        const { sortName } = column || {};
        setSorter(sortName || columnKey, sorter?.order);
        setExtensionExport(
            {
                pageSize: pageSize,
                pageNo: page - 1,
                sortBy: sort_by,
                sortDir: sort_order,
                search: searchKey
            }
        );
        refetch();
    };

    const { isPending, data, refetch } = useApi({
        key: ['extensionManagement', pagination],
        httpConfig: {
            method: "post",
            url: "team/users-extensions",
            params: {
                pageSize: pageSize,
                pageNo: page - 1,
                sortBy: sort_by,
                sortDir: sort_order,
                search: searchKey
            },
            data: extensionFilters
        }
    });

    useEffect(() => {
        setExtensionExport(
            {
                pageSize: pageSize,
                pageNo: page - 1,
                sortBy: sort_by,
                sortDir: sort_order,
                search: searchKey
            }
        );
        refetch();
        // eslint-disable-next-line
    }, [searchKey, extensionFilters]);


    useEffect(() => {
        if (data) {
            setResponse(data?.data?.content);
            setTotal(data?.data?.totalElements);
            setPaginationConfig((prev) => {
                return {
                    ...prev,
                    ...{
                        total: data?.data?.totalElements || 0
                    },
                };
            });
        }
        // eslint-disable-next-line
    }, [data])

    const columns: any = [
        {
            title: 'Emp ID',
            dataIndex: 'empId',
            key: 'empId',
            sorter: true,
            width: 120,
            ellipsis: true,
            fixed: 'left'
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            sorter: true,
            width: 200,
            ellipsis: true,
            fixed: 'left'
        },
        {
            title: 'Status',
            key: 'status',
            width: 280,
            render: (id: number, item: any) => {
                if (item.status === null) {
                    return (
                        <>
                            <ConfirmationModal model='approveExtension' extensionRowData={item} />
                        </>
                    )
                } else if (item.status === 'Approved') {
                    return (
                        <span style={{ 'color': '#00BC40' }}>
                            Approved on {dateTime(item.modifiedDate, 'date2')}
                        </span>
                    )
                } else if (item.status === 'Rejected') {
                    return (
                        <span style={{ 'color': '#CF1919' }}>
                            Rejected on {dateTime(item.modifiedDate, 'date2')}
                        </span>
                    )
                }
            },
            sorter: true,
            fixed: 'left'
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            sorter: true,
            ellipsis: true
        },
        {
            title: 'Requested On',
            dataIndex: 'requestDate',
            key: 'requestDate',
            render: (id: number, item: any) => dateTime(item.requestDate, 'date1'),
            sorter: true,
        },
        {
            title: 'Requested For',
            dataIndex: 'requestedFor',
            key: 'requestedFor',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            sorter: true,
            ellipsis: false,
            className: 'reason-column',
        },
        {
            title: 'Rejected Reason',
            dataIndex: 'rejectedReason',
            key: 'rejectedReason',
            sorter: true,
            ellipsis: false,
            className: 'reason-column',
        },
        {
            title: 'Extn. Date',
            dataIndex: 'extendedDate',
            key: 'extendedDate',
            render: (id: number, item: any) => dateTime(item.extendedDate, 'date1'),
            sorter: true,
        },
        {
            title: 'Req.Count',
            dataIndex: 'count',
            key: 'count',
            sorter: true,
            ellipsis: true,
            className: 'reqCount-column'
        },

    ];

    return (
        <Table
            id='extensionTable'
            dataSource={response}
            rowKey="id"
            columns={columns}
            pagination={paginationConfig}
            loading={isPending}
            onChange={handleTableChange}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={false}
        />
    );
};

export default ExtensionTable;
