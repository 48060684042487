import React, { useEffect, useState } from "react";
import { usePagination } from "../../../context/PaginationProvider";
import { useApi, useMutate } from "../../../util/useRequestProcessor";
import { Card, Table, Typography, Image, Input, Button } from "antd";
// import searchImg from '../../../assets/images/searchImg.svg';
import exportImg from '../../../assets/images/export.svg'
import ReportsFilter from "../filter/ReportsFilter";
import { usePmsContext } from "../../../context/PmsContext";

const { Text } = Typography;
const { Search } = Input;


const ReportTable: React.FC = () => {
    const { pagination, setTotal, setPage, setSorter, setPageSize } = usePagination();
    const { page, pageSize, sort_by, sort_order } = pagination;
    const { reportsFilters } = usePmsContext();
    const [reports, setReports] = useState<any[]>([]);
    const [reportsExport, setRepotrsExport] = useState<any>({});
    const [searchKey, setSearchKey] = useState('');

    const [paginationConfig, setPaginationConfig] = useState({
        current: page,
        pageSize: pageSize,
        total: 0,
        showTotal: (total: number, range: [number, number]) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50"],
        locale: { items_per_page: "" },
        onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize)
            setPaginationConfig((prev) => ({
                ...prev,
                current: page,
                pageSize: pageSize,
            }));
        },
        onShowSizeChange: (current: number, size: number) => {
            setPage(current);
            setPageSize(size);
            setPaginationConfig((prev) => ({
                ...prev,
                current: current,
                pageSize: size,
            }));
        },
    });

    const handleTableChange = (pagination: any, __: any, sorter: any) => {
        const { column, columnKey } = sorter;
        const { sortName } = column || {};
        setSorter(sortName || columnKey, sorter?.order);
        setRepotrsExport(
            {
                pageSize: pageSize,
                pageNo: page - 1,
                sortBy: sort_by,
                sortDir: sort_order,
                search: searchKey
            }
        );
        refetch();
    };

    const { isPending, data, refetch } = useApi({
        key: ['reportTable', pagination],
        httpConfig: {
            method: "post",
            url: "dashboard/submission-review-status",
            params: {
                pageSize: pageSize,
                pageNo: page - 1,
                sortBy: sort_by,
                sortDir: sort_order,
                search: searchKey
            },
            data: reportsFilters
        }
    });

    useEffect(() => {
        setRepotrsExport(
            {
                pageSize: pageSize,
                pageNo: page - 1,
                sortBy: sort_by,
                sortDir: sort_order,
                search: searchKey
            }
        );
        refetch();
        // eslint-disable-next-line
    }, [searchKey, reportsFilters]);

    useEffect(() => {
        if (data) {
            setReports(data?.data?.content);
            setTotal(data?.data?.totalElements);
            setPaginationConfig((prev) => {
                return {
                    ...prev,
                    ...{
                        total: data?.data?.totalElements || 0
                    },
                };
            });
        }
        // eslint-disable-next-line
    }, [data]);


    const { mutate: exportReportsCall = (reportsFilters), isLoading: exportLoading } = useMutate({
        key: ['reportsExport'],
        httpConfig: {
            method: 'post',
            url: 'dashboard/submission-review-status-export',
            params: reportsExport,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        },
        customConfig: {
            form: reportsFilters,
            callback: async (response: Blob) => {
                const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel;base64' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'GoalSubmissionReports.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            }
        }
    });

    const exportExcel = () => {
        exportReportsCall(reportsFilters);
    }

    const handleSearch = (value: string) => {
        setSearchKey(value);
    };

    const getDynamicColumns = (data: any[]) => {
        const dynamicColumns = [];
        const keys = new Set();

        data?.forEach((item) => {
            Object.keys(item)?.forEach((key) => {
                keys.add(key);
            });
        });

        if (keys.has('q1Status')) {
            dynamicColumns.push({
                title: 'Q1 Status',
                dataIndex: 'q1Status',
                key: 'q1Status',
                sorter: true,
                ellipsis: false,
                className: 'reviewStatus-column'
            });
        }

        if (keys.has('q2Status')) {
            dynamicColumns.push({
                title: 'Q2 Status',
                dataIndex: 'q2Status',
                key: 'q2Status',
                sorter: true,
                ellipsis: false,
                className: 'reviewStatus-column'
            });
        }

        if (keys.has('q3Status')) {
            dynamicColumns.push({
                title: 'Q3 Status',
                dataIndex: 'q3Status',
                key: 'q3Status',
                sorter: true,
                ellipsis: false,
                className: 'reviewStatus-column'
            });
        }

        if (keys.has('q4Status')) {
            dynamicColumns.push({
                title: 'Q4 Status',
                dataIndex: 'q4Status',
                key: 'q4Status',
                sorter: true,
                ellipsis: false,
                className: 'reviewStatus-column'
            });
        }

        return dynamicColumns;
    };

    const baseColumns: any = [
        {
            title: 'EMP ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            sorter: true,
            width: 120,
            ellipsis: true,
            fixed: 'left'
        },
        {
            title: 'Username',
            dataIndex: 'userName',
            key: 'userName',
            sorter: true,
            width: 200,
            ellipsis: true,
            fixed: 'left'
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            sorter: true,
            fixed: 'left',
            className: 'designation-column'
        },
        {
            title: 'Supervisor',
            dataIndex: 'supervisor',
            key: 'supervisor',
            sorter: true,
            fixed: 'left',
            ellipsis: true
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            sorter: true,
            ellipsis: true
        },
        {
            title: 'Goal Count',
            dataIndex: 'goalCount',
            key: 'goalCount',
            sorter: true,
            className: 'goalCount-column'
        },
        {
            title: 'G&O Status',
            key: 'goalStatus',
            dataIndex: 'goalStatus',
            render: (id: number, item: any) => {
                if (item.goalStatus === 'Submitted') {
                    return (
                        <span style={{ 'color': '#00BC40' }}>
                            {item.goalStatus}
                        </span>
                    )
                } else if (item.goalStatus === 'Not Submitted') {
                    return (
                        <span style={{ 'color': '#CF1919' }}>
                            {item.goalStatus}
                        </span>
                    )
                }
            },
            sorter: true,
            ellipsis: false,
            className: 'reviewStatus-column'
        }
    ];
    const dynamicColumns = getDynamicColumns(reports);
    const columns = [...baseColumns, ...dynamicColumns];

    return (
        <Card className="mt-4 extensionCard" bordered={false} title={
            <>
                <Text>Submission and Review Status</Text>
            </>
        }
            extra={
                <>
                    <Search
                        placeholder="Search..."
                        className="mt-1 extensionBtn"
                        allowClear
                        onSearch={handleSearch}
                        style={{
                            width: 300,
                            height: 50,
                            margin:0
                        }}
                    />
                    <ReportsFilter />
                    <Button className="m-2 extensionBtn no-hover" onClick={exportExcel} loading={exportLoading}>
                        <span>
                            <Image src={exportImg} className="extensionImage" preview={false} />
                            Export
                        </span>
                    </Button>
                </>
            }>
            <Table
                id="reportsTable"
                dataSource={reports}
                rowKey="id"
                columns={columns}
                pagination={paginationConfig}
                loading={isPending}
                onChange={handleTableChange}
                scroll={{ x: 'max-content' }}
                showSorterTooltip={false}
            />
        </Card>
    )
}

export default ReportTable;

