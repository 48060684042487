import React, { useEffect, useState } from 'react';
import { Button, Table, Select } from 'antd';
import '../../../styles/datatable.scss';
import { SelectValue } from 'antd/lib/select';

const DataTable: React.FC = () => {

    const [data, setData] = useState([]);
    const [, setFilter] = useState({
        page: 1,
        page_size: 10,
    });
    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 1,
        total: 100,
        onChange: function (page: any, pageSize: any) {
            setFilter((prev) => {
                return { ...prev, ...{ page: page, page_size: pageSize } };
            });
            setPagination((prev) => {
                return {
                    ...prev,
                    ...{
                        total: 100,
                        pageSize: pageSize,
                        page: page
                    },
                };
            });
        },
        showTotal: function (total: any, range: any) {
            return `Showing ${range[0]}-${range[1]} of ${total} entries`;
        },
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
        locale: { items_per_page: "" },
    });

    const handleResponseChange = (value: SelectValue) => {
    }

    useEffect(() => {

        fetchDummyEntities(pagination).then((result: any) => {
            setData(result.content);
        });
    }, [pagination]);


    //Start Dummy pageable data 
    function fetchDummyEntities(pagination: any) {
        const pageSize = pagination.pageSize;
        const pageNo = pagination.page;
        const delay = 500;

        return new Promise((resolve) => {
            setTimeout(() => {
                const entities = Array.from({ length: pageSize }, (_, index) => ({
                    sno: index + 1 + pageSize * (pageNo - 1),
                    id: (index + 1 + pageSize * (pageNo - 1)) * 1000,
                    requests: `Requests ${index + 1 + pageSize * (pageNo - 1)}`,
                    requestedOn: `Requested On ${index + 1 + pageSize * (pageNo - 1)}`,
                    requestedBy: `Requested By ${index + 1 + pageSize * (pageNo - 1)}`,
                    reason: `Reason @reason/ reason sample 1234 e3e3ipjwkjiwj2ji2jwi2 do2doi ${index + 1 + pageSize * (pageNo - 1)}`,
                    actions: '',
                }));

                resolve({
                    content: entities,
                    totalPages: Math.ceil(100 / pageSize),
                });
            }, delay);
        });
    }
    // End Dummy pageable data 

    const responseOptions: any[] = [
        {
            value: 'approved',
            label: 'Approved'
        },
        {
            value: 'awaiting',
            label: 'Awaiting'
        },
        {
            value: 'cancelled',
            label: 'Cancelled'
        },

    ];

    const columns: any = [
        {
            title: 'Sl.No',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Requests',
            dataIndex: 'requests',
            key: 'requests',
        },
        {
            title: 'Requested On',
            dataIndex: 'requestedOn',
            key: 'requestedOn',
        },
        {
            title: 'Requested By',
            dataIndex: 'requestedBy',
            key: 'requestedBy',
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: '20%'
        },
        {
            title: 'Response',
            dataIndex: 'response',
            key: 'response',
            width: '200',
            render: () => (
                <Select defaultValue={responseOptions[0]}
                    style={{ width: 200 }}
                    options={responseOptions}
                    onChange={(value: SelectValue) => handleResponseChange(value)} />
            ),
        },
        {
            title: '',
            key: 'actions',
            render: () => (
                <span>
                    <Button type="primary" size="large" >Save</Button>
                </span>
            ),
        },
    ];

    const zigZagRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    return (

        <Table
            dataSource={data}
            rowKey='id'
            rowClassName={zigZagRowClassName}
            columns={columns}
            pagination={{ ...pagination }}
        />

    );
};

export default DataTable;