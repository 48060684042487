import React, { useState } from "react";
import { Typography, Image, Row, Col, Card, Button, Input } from "antd";
import arrow from '../../assets/images/leftArrow.svg'
import exportImg from '../../assets/images/export.svg'
import '../../styles/extension.scss'
import { Link } from "react-router-dom";
import { PaginationProvider } from "../../context/PaginationProvider";
import { Content } from "antd/es/layout/layout";
import ExtensionTable from "../shared/data-table/ExtensionTable";
import { usePmsContext } from "../../context/PmsContext";
import { useMutate } from "../../util/useRequestProcessor";
import ExtensionFliter from "../shared/filter/ExtensionFilter";

const { Text } = Typography;
const {Search} = Input;

const ExtensionManagement: React.FC = () => {
    const { extensionExport, extensionFilters } = usePmsContext();
    const [searchKey, setSearchKey] = useState('');

    const exportExcel = () => {
        exportExtensionCall(extensionFilters);
    }

    const { mutate: exportExtensionCall = (extensionFilters), isLoading: exportLoading } = useMutate({
        key: ['extensionExport'],
        httpConfig: {
            method: 'post',
            url: 'team/user-extensions-export',
            params: extensionExport,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        },
        customConfig: {
            form: extensionFilters,
            callback: async (response: Blob) => {
                const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel;base64' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'extensions_export.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            }
        }
    });

    const handleSearch = (value: string) => {
        setSearchKey(value);
    };

    return (
        <>
            <Link to={"/admin"}>
                <>
                    <Image src={arrow} height={15} preview={false} /><Text className="backText">Back to Dashboard</Text>
                </>
            </Link>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Card className="mt-4 extensionCard" bordered={false} title={
                        <>
                            <Text>Review Extension Requests</Text>
                        </>
                    }
                        extra={
                            <>
                                <Search
                                    placeholder="Search..."
                                    className="mt-1 extensionBtn"
                                    allowClear
                                    onSearch={handleSearch}
                                    style={{
                                        width: 300,
                                        height: 50,
                                        margin: 0
                                    }}
                                />
                                <ExtensionFliter />
                                <Button className="m-2 extensionBtn no-hover" onClick={exportExcel} loading={exportLoading}>
                                    <span>
                                        <Image src={exportImg} className="extensionImage" preview={false} />
                                        Export
                                    </span>
                                </Button>
                            </>
                        }>
                        <PaginationProvider>
                            <Content>
                                <ExtensionTable searchKey={searchKey} />
                            </Content>
                        </PaginationProvider>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ExtensionManagement;

