import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Typography, Image, Flex, Avatar, Tag } from 'antd';
import '../../styles/my-team.scss'
import commentImg from '../../assets/images/comment.svg'
import { Container } from 'react-bootstrap';
import { useApi } from '../../util/useRequestProcessor';
import useAuth from '../../hooks/useAuth';
import ViewGoals from './models/ViewGoals';
import NoData from '../../layouts/shared/NoData';

const { Text, Title, Paragraph } = Typography;

interface Props {
    tab: string;
    teamUserId?: any;
    timelLineId?: any;
};

const TeamRM: React.FC<Props> = (tab: any) => {

    const { user } = useAuth();
    const { data, refetch, isLoading } = useApi({
        key: ['get-approval', user?.id],
        httpConfig: {
            method: 'get',
            url: `goals/get/approve-request/${user?.id}`
        }
    })

    const [performerDetails, setPerformerDetails] = useState<any>(null)

    useEffect(() => {
        refetch();
        const Data: any = data?.data
        if (Data) {
            setPerformerDetails(Data)
        } else {
            setPerformerDetails(null)
        }
        // eslint-disable-next-line
    }, [tab, data])


    return (
        <>
            <Card className="PerformanceScroll" loading={isLoading} bordered={false} title={
                <>
                    Team Performances <span className='countTeamPerformance'>{performerDetails?.length ? performerDetails?.length : 0}</span>
                </>
            }>
                <div style={{ height: 'auto' }}>
                    {(performerDetails === null) && (
                        <div className='mt-4'>
                            <NoData />
                        </div>
                    )}
                    {(performerDetails !== null) && (
                        <>
                            {Array.isArray(performerDetails) && performerDetails?.map((item: any, index: any) => (
                                <Card key={index} bordered={false} className={`teamSubCard ${index === 0 ? 'mt-1' : ''}`}>
                                    <Row justify="space-between" align="middle">
                                        <Col span={20}>
                                            <Row className='bordered'>
                                                <Col span={10} className='py-4 px-3 right-bordered d-flex align-items-center justify-content-left'>
                                                    <Flex className='d-flex align-items-center'>
                                                        <Avatar src={item?.imgUrl} size={100} className='me-3'>{item?.empName?.charAt(0)?.toUpperCase()}</Avatar>
                                                        <Text>
                                                            <Title level={4} className='d-block greyTxt'>{item?.empName}</Title>
                                                            <Paragraph type='secondary' className='mb-2'>Emp ID - {item?.empId}</Paragraph>
                                                            <Paragraph type='secondary' className='mb-2'>Role - {item?.role}</Paragraph>
                                                        </Text>
                                                    </Flex>
                                                </Col>
                                                <Col span={14} className='px-5 right-bordered d-flex align-items-center'>
                                                    <Flex className='centerProgressCard' align='middle'>
                                                        <Container className='px-1'>
                                                            <Row>
                                                                <Col span={18}>
                                                                    <Text className='d-block greyTxt'>No of Objectives</Text>
                                                                    <Title className='mt-1 mb-0' level={3}>{item?.noOfObjetcives}</Title>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        <Container className='pl-5 d-flex align-items-center justify-content-center'>
                                                            <div>
                                                                <Tag
                                                                    className={item.status === true ? "py-2 px-4 tag greenTag" : "py-2 px-4 tag orgTag"} style={{ cursor: 'default' }}
                                                                >{item.status === true ? 'APPROVED' : 'PENDING'}</Tag>
                                                            </div>

                                                        </Container>
                                                    </Flex>
                                                </Col>
                                            </Row>
                                            <Row className='px-3 py-2 right-bordered'>
                                                <Col span={11} className='d-flex justify-content-start'>
                                                    {item?.comment &&
                                                        (
                                                            <>
                                                                <Image className='px-1' src={commentImg} style={{ width: 'auto' }} height={15} preview={false} />
                                                                <Text className='sub-head1 mt-1' style={{ lineHeight: '20px !important' }}>{item?.comment}</Text>
                                                            </>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4}>
                                            <ViewGoals key={'viewGoals' + index} model="reviewEdit" item={item} />
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </>
                    )}
                </div>
            </Card>
        </>
    )
}

export default TeamRM;