import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography, theme,Image } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React from "react";
import profileImg from '../../../assets/images/profileImg.jpg'
import { Link } from "react-router-dom";
const { Text } = Typography;

const UserView: React.FC = () => {
    const {
        token: { colorError }
    } = theme.useToken();

    const handleNavigation = () => {
    }

    const employeeDetails = 
        {
            'empId':10057,
            'officialMail':'abcd@gmail.com',
            'mobile':'9345567878',
            'doj':'29 Feb, 2000',
            'status': 'Active',
            'reportingManager':'Vignesh Ramasamy',
            'roleGroup':'Employee, Reporting Manager',
            'reviewCycle':'May Cycle',
            'currentReview':'Mid Review',
            'organisation': 'Quess',
            'lob':'Technology Solutions',
            'brand':'Heptagon',
            'vertical':'Heatagon',
            'subVertical':'Back End',
            'quessLevel':'L4',
            'designation':'Associate Software Developer',
            'workLocation':'Coimbatore',
            'branchName':'Coimbatore'
        }
    
    return (
        <Content>
            <Title className="" level={4}>
                Users
                <RightOutlined style={{ fontSize: 16 }} />
                Employee Details
            </Title>
            <Row gutter={[16, 16]} className="mt-4">
                {/*start left column  */}
                <Col span={8}>
                    <Card type="inner" size="small"
                        title={
                            <Content className="p-3">
                                {/* <img src={profileImg} className="d-block profileImg" alt="employee-img" /> */}
                                <Image className="d-block profileImg" src={profileImg} preview={false} alt="employee-img"/>
                                <Title level={4} className="text-center mt-3">Arun Gpolakrishanan</Title>
                                <Text className="d-block text-center">UI UX Designer</Text>
                            </Content>
                        }>
                        <Row>
                            <Col span={12}>
                                <Text>Emp ID</Text>
                                <Title level={5} className="mt-0">100815</Title>
                            </Col>
                            <Col span={12}>
                                <Text>Reports to</Text>
                                <Title level={5} className="mt-0">Thangadurai</Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Text>Joining Date</Text>
                                <Title level={5} className="mt-0">10-10-2018</Title>
                            </Col>
                        </Row>
                    </Card>
                    <Card type="inner" className="mt-3" size="small" title="Review Cycle">
                        <Row>
                            <Col span={14}>
                                <Text className="blue-text">Current Review Cycle</Text>
                            </Col>
                            <Col span={6} offset={4}>
                                <Text color={colorError}>Yet to start</Text>
                            </Col>
                        </Row>
                        <Row className="reviewNavtab mt-3" onClick={handleNavigation}>
                            <Col span={18}>
                                <Title level={5}>Mid Review</Title>
                                <Text>1 Jan 2023 - 30 May 2023</Text>
                            </Col>
                            <Col span={2} offset={4}>
                                <RightOutlined className="mt-3" />
                            </Col>
                        </Row>
                        <hr />
                        <Row className="reviewNavtab mt-3" onClick={handleNavigation}>
                            <Col span={18}>
                                <Title level={5}>Mid Review</Title>
                                <Text>1 Jan 2023 - 30 May 2023</Text>
                            </Col>
                            <Col span={2} offset={4}>
                                <RightOutlined className="mt-3" />
                            </Col>
                        </Row>
                    </Card>
                    <Card type="inner" className="mt-3" size="small" title="Previous Review Cycle">
                        <div className="mt-2">
                            <Text>Year 2020 - 2021</Text>
                            <Text className="reviewLink"><Link to='/users'>Mid Review</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to='/users'>Annual Review</Link></Text>
                        </div>
                        <div className="mt-2">
                            <Text>Year 2020 - 2021</Text>
                            <Text className="reviewLink"><Link to='/users'>Mid Review</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to='/users'>Annual Review</Link></Text>
                        </div>
                        <div className="mt-2">
                            <Text>Year 2020 - 2021</Text>
                            <Text className="reviewLink"><Link to='/users'>Mid Review</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to='/users'>Annual Review</Link></Text>
                        </div>
                    </Card>
                </Col>
                {/*end left column  */}
                {/*start right column  */}
                <Col span={16}>
                    <Card type="inner" size="small" title="Employee Details">
                        <Row className="mt-4">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Employee ID:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.empId}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Offcial Email ID:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.officialMail}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Mobile Number:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.mobile}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Date of Joining:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.doj}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Active/Inactive:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.status}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Role Group:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.roleGroup}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Review Cycle:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.reviewCycle}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Current Review:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.currentReview}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Organisation:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.organisation}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>LOB:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.lob}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Brand:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.brand}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Vertical:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.vertical}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Sub-Vertical:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.subVertical}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Quess Level:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.quessLevel}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Quess Designation:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.designation}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Work Loction:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.workLocation}</Text>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={6}>
                                <Title className="d-block" level={5}>Branch Name:</Title>
                            </Col>
                            <Col span={18}>
                                <Text>{employeeDetails.branchName}</Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {/*end right column  */}
            </Row>
        </Content>
    )
}

export default UserView;