import {
    Button,
    Form,
    Input,
    Select,
    Typography,
    Image,
    InputNumber,
    App,
    Tag,
    Drawer,
    Row,
    Col,
} from "antd";
import { useEffect, useState } from "react";
import "../../../styles/employeeDashboard.scss";
import editIcon from "../../../assets/images/edit.svg";
import { useApi, useMutate } from "../../../util/useRequestProcessor";
import useAuth from "../../../hooks/useAuth";
import { EditOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { TextArea } = Input;

interface OptionItem {
    id: number;
    name: string;
    status: boolean;
    label?: string;
}

interface Props {
    model: any;
    goal?: any;
    teamMateID?: any;
    onClick: () => void;
}

const AddGoal: React.FC<Props> = ({ model, goal, teamMateID, onClick }) => {
    const [visible, setVisible] = useState(false);
    //   let objectiveName: any;
    let dataPayload: any;
    let updatePayload: any;
    const [measure, setMeasure] = useState<any[]>([]);
    const [mesurement, setMesurement] = useState<OptionItem[]>([]);
    const [targetSuffix, setTargetSuffix] = useState({});
    const [form] = Form.useForm<any>();
    const [targetMax, setTargetMax] = useState<number>(999999999999);
    const { user } = useAuth();
    const { message } = App.useApp();

    const { data: dataMesurement } = useApi({
        key: ["mesurement"],
        httpConfig: {
            method: "get",
            url: "goals/measurement",
            params: {
                pageSize: 20,
                pageNo: 0,
                sortBy: "name",
                sortDir: "desc",
            },
        },
    });

    const setSuffix = (value: any) => {
        let suffix = mesurement?.filter((item: any) => item.id === value)[0]?.label;
        setTargetSuffix(suffix ? { addonAfter: suffix } : {});
    }
    useEffect(() => {
        setMeasure(mesurement?.map((item: any) => ({
            label: item?.name + (item?.label ? (" (" + item?.label + ")") : ''),
            value: item?.id
        })));
        if (goal?.id) {
            setSuffix(goal?.goalSettingDto?.measurement?.id)
        }
        // eslint-disable-next-line
    }, [mesurement, goal]);

    useEffect(() => {
        if (dataMesurement?.data?.content) {
            setMesurement(dataMesurement?.data?.content);
        }

        let goalItem = {
            description: goal?.goalSettingDto?.description,
            measurement: goal?.goalSettingDto?.measurement?.name,
            measurementId: goal?.goalSettingDto?.measurement?.id,
            objective: goal?.goalSettingDto?.objective,
            target: goal?.goalSettingDto?.target,
        };
        form.setFieldsValue(goalItem);
        // eslint-disable-next-line
    }, [dataMesurement, visible, goal]);

    useEffect(() => {
        if (form.getFieldValue('measurementId') === 1) {
            setTargetMax(100);
        } else {
            setTargetMax(999999999999);
        }
        // eslint-disable-next-line
    }, [form.getFieldValue('measurementId')]);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const onFinishValue = (values: any, modelType: any) => {
        if (modelType === "ViewGoalEdit") {
            updatePayload = {
                id: goal?.id,
                description: form.getFieldValue(["description"]),
                measurementId:
                    Number(form.getFieldValue(["measurementId"])) || goal?.goalSettingDto?.measurement?.id,
                objective: form.getFieldValue(["objective"]),
                userId: teamMateID,
                target: form.getFieldValue(["target"]),
                reviewCycleId: 1,
                objectiveName: form.getFieldValue(["objective"]),
                measurementName: form.getFieldValue(["measurement"]),
            };
            doUpdate(updatePayload);
        } else if (modelType === "RMadd") {
            dataPayload = {
                id: null,
                description: form.getFieldValue(["description"]),
                measurementId: Number(form.getFieldValue(["measurementId"])),
                objective: form.getFieldValue(["objective"]),
                userId: teamMateID,
                target: form.getFieldValue(["target"]),
                reviewCycleId: 1,
                objectiveName: form.getFieldValue(["objective"]),
                measurementName: form.getFieldValue(["measurement"]),
            };
            doCreate(dataPayload);
        }
    };

    const onFinish = (values: any) => {
        onFinishValue(values, model);
    };
    const { isPending: AddPending, mutate: doCreate = dataPayload } = useMutate({
        key: ["addGoal", dataPayload?.userId],
        httpConfig: {
            form: form,
            method: "post",
            url: "/goals/create",
        },
        customConfig: {
            form: dataPayload,
            callback: async (response: any) => {
                if (response?.data !== null) {
                    message.success(response?.message, 4);
                    handleCancel();
                } else {
                    message.error(response?.error, 4);
                }
            },
            invalidateKey: [['get-approval', user?.id], ["goalObjective", teamMateID]],
        },
    });

    const { isPending: EditPending, mutate: doUpdate = dataPayload } = useMutate({
        key: ["updateGoal", dataPayload?.userId],
        httpConfig: {
            form: form,
            method: "post",
            url: `goals/update/${goal?.id}`,
        },
        customConfig: {
            form: dataPayload,
            callback: async (response: any) => {
                if (response?.data !== null) {
                    message.success(response?.message, 4);
                    handleCancel();
                } else {
                    message.error(response?.error, 4);
                }
            },
            invalidateKey: [['get-approval', user?.id], ["goalObjective", teamMateID]],
        },
    });

    return (
        <>
            {model === "setObject" && (
                <Button
                    className="add_btn"
                    type="primary"
                    size="large"
                    onClick={showModal}
                >
                    Set Objectives
                </Button>
            )}
            {model === "add" && (
                <Button className="add_btn" type="primary" onClick={showModal}>
                    Add
                </Button>
            )}
            {model === "RMadd" && (
                <Button className="add_btn" type="primary" onClick={showModal}>
                    Add G&O
                </Button>
            )}
            {model === "edit" && (
                <div onClick={showModal} style={{ cursor: 'pointer' }}>
                    <Image
                        className="img-edit"
                        height={20}
                        src={editIcon}
                        preview={false}
                        width={30}
                    />
                    <Text className="edit-text">Edit</Text>
                </div>
            )}
            {model === "ViewGoalEdit" && (
                <Tag onClick={showModal} style={{ cursor: 'pointer' }}><EditOutlined /></Tag>
            )}

            <Drawer
                title={
                    model === "ViewGoalEdit"
                        ? <>Edit G&O <Text type='secondary'>(2024-2025)</Text></>
                        : <>Add G&O <Text type='secondary'>(2024-2025)</Text></>
                }
                width={720}
                onClose={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                footer={
                    <Row gutter={16} justify={"end"}>
                        <Col><Button type='primary' ghost size='large' onClick={handleCancel} loading={AddPending || EditPending}>Cancel</Button></Col>
                        <Col><Button type='primary' size='large' htmlType="submit" onClick={form.submit} loading={AddPending}>{model === "ViewGoalEdit" ? 'Update' : 'Add'}</Button></Col>
                    </Row>
                }
                open={visible}
            >
                <Form
                    form={form}
                    name="addGoal"
                    layout="vertical"
                    size='large'
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="objective"
                        label="Objective"
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                            { max: 75, message: "Objective cannot be more than 75 characters" },
                            { whitespace: true, message: "This is a mandatory field" }
                        ]}
                        extra={'Ex: Learning, Increase Annual Sales, ...'}
                    >
                        <TextArea
                            id="objective"
                            placeholder="Please enter the Objective"
                            autoSize={{ minRows: 2, maxRows: 5 }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                            { max: 250, message: "Description cannot be more than 250 characters" },
                            { whitespace: true, message: "This is a mandatory field" }
                        ]}
                    >
                        <TextArea
                            id="description"
                            placeholder="Please enter the Description"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="measurementId"
                        label="Measurement Criteria (UOM)"
                        rules={[
                            { required: true, message: "This is a mandatory field" }
                        ]}
                        extra={"UOM - Unit of Measurement"}
                    >
                        <Select options={measure} placeholder="Select an Measurement Criteria" id="measurementId" onSelect={setSuffix} />
                    </Form.Item>
                    <Form.Item
                        name="target"
                        label="Target"
                        rules={[
                            { required: true, message: "This is a mandatory field" },
                            { max: targetMax, type: 'number', message: 'Maximum Limit Exceeds' },
                            { min: 1, type: 'number', message: 'Target must be greater than Zero' }
                        ]}
                    >
                        <InputNumber
                            id="target"
                            placeholder="Please enter the Target"
                            className='w-100'
                            {...targetSuffix}
                        />
                    </Form.Item>

                    {/* <Flex className="p-2 mt-5" style={{ position: 'absolute', bottom: 20, right: 20 }} >
                        <Button className="mx-3" size="large" onClick={handleCancel}>
                            Cancel
                        </Button>
                        {model === "ViewGoalEdit" ? (
                            <Button type="primary" size="large" htmlType="submit" loading={EditPending} onClick={onClick}>
                                Update
                            </Button>
                        ) : (
                            <Button type="primary" size="large" htmlType="submit" loading={AddPending} onClick={onClick}>
                                Add
                            </Button>
                        )}
                    </Flex> */}
                </Form>
            </Drawer>
        </>
    );
};

export default AddGoal;
