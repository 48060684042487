import React, { useEffect, useState } from "react";
import { Card, theme } from "antd";
import { usePmsContext } from "../../context/PmsContext";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import CelebrationEffect from "./CelebrationEffect";
import NoData from "../../layouts/shared/NoData";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const GraphComponent = () => {

    const { submittedOn, approvedOn, activeTab, graphData, baseCycles } = usePmsContext();
    const [labels, setLabels] = useState<string[]>([]);
    const [isWaiting, setIsWaiting] = useState(false);
    const [dataSet, setDataSet] = useState<number[]>([1]);
    const { token } = theme.useToken();

    const statusLabels: string[] = ['Below Plan', 'In-Plan', 'Above Plan'];
    const tooltipLabels: string[] = ['You are below plan', 'You are In-plan', 'You are above plan'];

    useEffect(() => {
        if (submittedOn !== null && approvedOn === null) {
            setIsWaiting(true);
        } else {
            setIsWaiting(false);
        }

        setLabels(['Base'].concat(baseCycles?.map((item) => item?.reviewLabelAlter)));
        let temp: number[] = [1].concat(new Array(baseCycles?.length).fill(null));

        graphData?.forEach((graph: any, index: number) => {
            temp[index + 1] = graph?.value;
        });
        setDataSet(temp);
        // eslint-disable-next-line
    }, [activeTab, submittedOn, approvedOn]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
            tooltip: {
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBgContainer,
                borderWidth: 1,
                padding: 10,
                cornerRadius: 5,
                titleColor: token.colorText,
                titleFont: {
                    weight: 500,
                    size: token.fontSizeSM,
                    family: token.fontFamily
                },
                callbacks: {
                    title: (xDatapoint: any) => {
                        return xDatapoint[0]?.dataIndex > 0 ? tooltipLabels[xDatapoint[0]?.formattedValue] : '';
                    },
                    label: () => { return '' },
                    labelTextColor: () => token.colorText,
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    callback: (value: any, index: number) => {
                        return value === 0 ? '' : labels[index];
                    },
                    color: token.colorText,
                    font: {
                        weight: 500,
                        size: token.fontSizeSM,
                        family: token.fontFamily
                    }
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    stepSize: 1,
                    display: true,
                    callback: (value: any, index: number) => {
                        return statusLabels[value]; // Use value as index to get the corresponding label
                    },
                    color: token.colorText,
                    font: {
                        weight: 500,
                        size: token.fontSizeSM,
                        family: token.fontFamily
                    },
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Review Insight',
                data: dataSet,
                borderColor: token.colorPrimary,
                backgroundColor: token.colorBgContainer,
                lineTension: 0.5
            }
        ],
    };
    return (
        <Card
            bordered={false}
            className={`d-flex justify-content-center align-items-center ${isWaiting === true ? 'blur-graph' : 'graph-section'}`}
            style={{ minHeight: '100%' }}
        >
            {isWaiting === true && (
                <>
                    <Card bordered={false} className="waitingCard text-center">
                        Waiting for Supervisor's update
                    </Card>
                </>
            )}
            {(dataSet[1] !== null || (dataSet[0] !== null && isWaiting === true)) ? (
                <Line options={options} data={data} width={380} height={300} />
            ) : (
                <div className="text-center">
                    <h5>Review Status Graph</h5>
                    <div className="mt-4">
                        <NoData />
                    </div>
                </div>
            )}
            {(
                <CelebrationEffect />
            )}
        </Card>
    );
}

export default GraphComponent;
