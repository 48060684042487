import { useEffect, useState } from "react";
import Lottie from "lottie-react";

import Celebration from '../../assets/JSON/celebration2.json'
import { usePmsContext } from "../../context/PmsContext";

const CelebrationEffect = () => {
    const { level, activeTab} = usePmsContext();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (level === 2) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 5000); // 5 seconds

            return () => clearTimeout(timer);
        } else {
            setIsVisible(false);
        }
         // eslint-disable-next-line
    }, [level, activeTab]);


    return (
        <>
            {isVisible && (
                <div className="celebration-container">
                    <Lottie animationData={Celebration}></Lottie>
                </div>
            )}
        </>
    )
}

export default CelebrationEffect;