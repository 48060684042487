import React, { useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { Button, Card, Col, Form, Input, Radio, RadioChangeEvent, Row, Select, Typography, theme } from "antd";
const { Title, Text } = Typography;

const UserEdit: React.FC = () => {

    const {
        token: { colorError }
    } = theme.useToken();

    const [form] = Form.useForm<any>();

    const onFinish = () => {
    };
    const [gender, setGender] = useState<string>('male');
    const [status, setStatus] = useState<string>('active');

    const onGenderChange = (e: RadioChangeEvent) => {
        const value = e.target.value;
        setGender(value);
        return;
    };

    const genderOptions = [
        {
            label: 'Male',
            value: 'male',
        },
        {
            label: 'Female',
            value: 'female',
        },
        {
            label: 'Transgender',
            value: 'transgender',
        },
    ];
    const onStatusChange = (e: RadioChangeEvent) => {
        const value = e.target.value;
        setStatus(value);
        return;
    };

    const dropDownChange = () => {
    };

    const statusOptions = [
        {
            label: 'Active',
            value: 'active',
        },
        {
            label: 'inactive',
            value: 'inactive',
        }
    ];

    const dropDownoptions = [
        {
            label: 'Value1',
            value: 'value1'
        },
        {
            label: 'Value2',
            value: 'value2'
        },
        {
            label: 'Value3',
            value: 'value3'
        },
        {
            label: 'Value4',
            value: 'value4'
        },
        {
            label: 'Value5',
            value: 'value5'
        },
        {
            label: 'Value6',
            value: 'value6'
        },
        {
            label: 'Value7',
            value: 'value7'
        }
    ];


    return (
        <Content>
            <Title className="" level={4}>
                Users
                <RightOutlined style={{ fontSize: 16 }} />
                Employee Details
            </Title>
            <Text>Edit existing employee details</Text>
            <Card type="inner" className="outerCard mt-3" size="small" title='Employee Details'>
                <Form className="p-2" form={form} onFinish={onFinish}>
                    <Card type="inner" className="innerCard mt-3" size="small" title='Employee Details'>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">First Name <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="firstName"
                                            className="formInputItem"
                                            label=""
                                            rules={[
                                                { required: true, message: 'Please Provide First name!' },
                                                {
                                                    pattern: new RegExp(/^[a-zA-Z0-9.]*$/),
                                                    message: "No Space or Special Chars Allowed",
                                                },
                                            ]}
                                        >
                                            <Input className="formInput" id="firstName" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Employee ID <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="empID"
                                            className="formInputItem"
                                            label=""
                                            rules={[{ required: false, message: 'Please Provide Employee ID!' }]}
                                        >
                                            <Input className="formInput"  id="empID" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Middle Name</Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="middleName"
                                            className="formInputItem"
                                            label=""
                                            rules={[{ required: false, message: '' }]}
                                        >
                                            <Input className="formInput" id="middlename" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Gender <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={15}>
                                        <Form.Item>
                                            <Radio.Group size="large"
                                                options={genderOptions}
                                                onChange={onGenderChange}
                                                value={gender}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Last Name <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="lastName"
                                            className="formInputItem"
                                            label=""
                                            rules={[{ required: true, message: 'Please Provide Last Name!' }]}
                                        >
                                            <Input className="formInput" id="lastName" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Official Email ID <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="officialMail"
                                            className="formInputItem"
                                            label=""
                                            rules={[{ required: true, message: 'Please Provide your Email!' }]}
                                        >
                                            <Input className="formInput" id="officialMail" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Employement Type <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="employeeType"
                                            className="formInputItem"
                                            label=""
                                            rules={[{ required: true, message: 'Please Select Employement Type!' }]}
                                        >
                                            <Input className="employeeType" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Personal Mobile Number <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="mobileNo"
                                            className="formInputItem"
                                            label=""
                                            rules={[
                                                { required: true, message: 'Please Provide your Mobile Number!' },
                                                { min: 10, message: "Phone must be minimum 8 characters." }
                                            ]}
                                        >
                                            <Input className="formInput" id="mobileNo" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Card type="inner" className="innerCard mt-3" size="small" title='Emoloyment Details'>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Date of Joining <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="doj"
                                            className="formInputItem"
                                            label=""
                                            rules={[{ required: true, message: 'Please Provide Date of Joining!' }]}
                                        >
                                            <Input className="formInput" id="doj" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Role Group <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                mode="tags"
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Active/Inactive <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={15}>
                                        <Form.Item>
                                            <Radio.Group size="large"
                                                options={statusOptions}
                                                onChange={onStatusChange}
                                                value={status}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Review Cycle <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Reporting Manager ID</Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Current Review <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Card type="inner" className="innerCard mt-3" size="small" title='Organisation/Work Details'>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Organisation <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Quess Level <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">LOB <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Quess Designation <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Brand <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Work Location</Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Vertical <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Branch Name <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <Text className="formLabel">Sub-vertical <span style={{ color: colorError }}>*</span></Text>
                                    </Col>
                                    <Col span={1} className="formLabel">:</Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={dropDownChange}
                                                tokenSeparators={[',']}
                                                options={dropDownoptions}
                                                value='value1'
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Form.Item>
                        <Content className="mt-5 d-flex">
                            <Button type="primary" className="empFormBtn" htmlType="submit">Update Details</Button>
                            <Button danger className="empFormBtn mx-5">Cancel</Button>
                        </Content>
                    </Form.Item>
                </Form>
            </Card>
        </Content>
    )
}

export default UserEdit;