import React, { useEffect, useState } from "react";
import { Col, Menu, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import "../../styles/my-team.scss";
import { Container } from "react-bootstrap";
import TopPerformance from "./TopPerformance";
import TeamPerformance from "./TeamPerformance";
import ChartDisplay from "./chart";
import { useApi } from "../../util/useRequestProcessor";
import TeamRM from "./Teamrm";
import { usePmsContext } from "../../context/PmsContext";
import useAuth from "../../hooks/useAuth";

const MyTeam: React.FC = () => {
    const { user } = useAuth();
    const { setMyTeamCurrentLabel, setShowRequestBtn, setSupervisorTab } = usePmsContext();
    const [currentTab, setCurrentTab] = useState<any>();
    const [baseCycle, setBaseCycle] = useState<any | null>(null);
    const [dataPayload, setDataPayload] = useState<any>({});

    const onTabChange = (e: any) => {
        setCurrentTab(e.key);
        setSupervisorTab(e.key);
        const currentLabel = baseCycle?.find((item: any) => item.id === +(e.key));
        setMyTeamCurrentLabel(currentLabel);
    };

    const { refetch, data } = useApi({
        key: ['baseCycles'],
        httpConfig: {
            method: 'get',
            url: 'config/reviewCycles/baseCycles-supervisor',
        }
    });

    const { data: buttonData, refetch: buttonRefetch } = useApi({
        key: ["extensionButtonResponse"],
        httpConfig: {
            method: "post",
            url: "team/extension-button-response",
            data: dataPayload
        }
    });

    useEffect(() => {
        if (user?.id && currentTab) {
            const newDataPayload = {
                reportingManagerId: user?.id,
                cycleId: +(currentTab)
            };
            setDataPayload(newDataPayload);
        }
        // eslint-disable-next-line
    }, [currentTab]);

    useEffect(() => {
        if (Object.keys(dataPayload).length > 0) {
            buttonRefetch();
        }
        // eslint-disable-next-line
    }, [dataPayload, data]);

    useEffect(() => {
        if (buttonData) {
            setShowRequestBtn(buttonData?.data);
        }
        //eslint-disable-next-line
    }, [buttonData])

    useEffect(() => {
        refetch()
        const Data: any = data?.data
        if (Data) {
            setBaseCycle(Data);
        }
        setCurrentTab('3');
        const currentLabel = Data?.find((item: any) => item.id === +(3));
        setMyTeamCurrentLabel(currentLabel);
        // eslint-disable-next-line
    }, [data, refetch])

    const menuItems = baseCycle?.map((item: any) => ({
        key: item.id.toString(),
        label: item.reviewLabel,
        disabled: item.status === 'YET_TO_START',
    }));

    return (
        <Content className="myTeam">
            <Menu
                onClick={onTabChange}
                selectedKeys={[currentTab]}
                style={{ width: "100%" }}
                mode="horizontal"
                items={menuItems}
                className="supervisorMenu"
            >

                {/* {baseCycle?.map((items: any) => (
                    <Menu.Item key={items?.id} disabled={items?.status === 'YET_TO_START'}>
                        <Text>{items?.reviewLabel}</Text>
                    </Menu.Item>
                ))} */}
            </Menu>
            {(currentTab !== '3') && (
                <Row
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                    ]}
                    className="mt-4"
                >
                    <Col span={24}>
                        <ChartDisplay tab={currentTab} />
                    </Col>
                    <Col span={24}>
                        <TeamPerformance tab={currentTab} />
                    </Col>
                    <Col span={12}>
                        <TopPerformance tab={currentTab} />
                    </Col>
                </Row>
            )}
            {(currentTab === '3') && (
                <Container className="mb-5">
                    <Row className="mt-4">
                        <Col span={24}>
                            <TeamRM tab={currentTab} />
                        </Col>
                    </Row>
                </Container>
            )}
        </Content>
    );
};

export default MyTeam;
