import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAuth } from "../services/authServices";

const defaultOptions: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL || "",
    headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
    },
    validateStatus: function (status: number) {
        return status >= 200 && status < 500 && status !== 401;
    },
    timeout: 2500
};

/**
 * axios method for network calls without authorization
 */
const httpInstance = axios.create(defaultOptions);

export const setHeaderToken = (token: string) => {
    httpInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
    //client.defaults.headers.common.Authorization = null;
    delete httpInstance.defaults.headers.common.Authorization;
};

createAuthRefreshInterceptor(httpInstance, refreshAuth, {
    statusCodes: [401], // default: [ 401 ]
    pauseInstanceWhileRefreshing: true,
});

httpInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { httpInstance };
