import React, { useEffect, useState } from "react";
import { Typography, Card, Row, Col, Button, List, Progress, Statistic, Result } from 'antd';
import Icon from "@ant-design/icons";
import { useApi } from "../../util/useRequestProcessor";
import { usePmsContext } from "../../context/PmsContext";
import { ReactComponent as emptyGoals } from "../../assets/images/emptyGoals.svg";
import { ReactComponent as editIcon } from "../../assets/images/edit.svg";
import { ReactComponent as ontrack } from "../../assets/images/ontrack.svg";
import { ReactComponent as ontrackException } from "../../assets/images/ontrackException.svg";
import { ReactComponent as ontrackActive } from "../../assets/images/ontrackActive.svg";
import { ReactComponent as ontrackSuccess } from "../../assets/images/ontrackSuccess.svg";
import { ReactComponent as ontrackNormal } from "../../assets/images/ontrackNormal.svg";
import { ReactComponent as editRoundIcon } from "../../assets/images/EditIcon.svg";

const { Title, Text, Paragraph } = Typography;

enum Status {
    Success = 'success',
    Exception = 'exception',
    Normal = 'normal',
    Active = 'active'
};

const GoalsObjectiveCard: React.FC = () => {
    const { stage, setGoalModal, setCurrent, setApprovalModal, goals, setGoals, objectiveData, profile, setSubmittedOn, submittedOn, setApprovedOn, approvedOn, setProgressModal, setGraphData, reviewStatus, setLevel, baseCycles } = usePmsContext();
    const status: Status[] = [Status.Exception, Status.Active, Status.Success, Status.Normal];
    const statusImage: any[] = [ontrack, ontrackException, ontrackActive, ontrackSuccess, ontrackNormal];
    const [isNotStarted, setIsNotStarted]= useState<any>();

    const { data, isLoading, refetch } = useApi({
        key: ["goalObjective", profile?.id],
        httpConfig: {
            method: "post",
            url: "goals/user-goals-obj",
            data: objectiveData
        },
        queryConfig: {
            enabled: !!profile?.id,
            refetchInterval: 30000,
        }
    });

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [objectiveData]);

    function getStatusById(id:any) {
        for (let item of baseCycles) {
            if (item.id === id) {
                return item.status;
            }
        }
        return null;
    }
    

    useEffect(() => {
        if (data?.data) {
            setGoals(data?.data?.reviewInsight?.map((item: any) => {
                let achiedPer: number = item?.target_achieved / item?.goalSettingDto?.target * 100;
                return ({
                    ...item,
                    achievedPercentage: item?.target_achieved ? achiedPer?.toFixed(0) : 0,
                })
            }));
            setGraphData(data?.data?.graphDtos)
            setSubmittedOn(data?.data?.submittedOn);
            setApprovedOn(data?.data?.approvedOn);
            setLevel(data?.data?.level);
            //Date Restriction button response
            const ID= objectiveData.reviewTimelineId;
            const currentIDStatus=getStatusById(ID);
            setIsNotStarted(currentIDStatus);
        }
        // eslint-disable-next-line
    }, [data, submittedOn, approvedOn]);

    return (
        <Card bordered={false} title={"Goals & Objectives (G&O)"} className="golasCardScroll" loading={isLoading} extra={
            <>
                {(stage?.current === 0 && goals.length > 0) && (
                    <>
                        {goals?.length > 0 && (<Button type="primary" ghost className="ms-3" onClick={() => setApprovalModal(true)}>Submit for Approval</Button>)}
                        <Button type="primary" className="ms-3" onClick={() => { setCurrent({}); setGoalModal(true); }}>Add G&O</Button>
                    </>
                )}
                {(stage?.current >= 2 && goals.length > 0 && (submittedOn === null&& isNotStarted === 'STARTED' )) && (
                    <>
                        <Button type="primary" ghost className="ms-3" onClick={() => setApprovalModal(true)}>Submit to Supervisor</Button>
                        <Button type="primary" className="ms-3" onClick={() => setProgressModal(true)}>Mark Progress</Button>
                    </>
                )}</>
        }>
            {(stage?.current === 0 && goals.length === 0) && (
                <>
                    <Result
                        icon={<Icon component={emptyGoals} />}
                        title="Looks like you haven't set any goals for 2024-25."
                        subTitle="Let's set the goals, track them, and make progress together!"
                        extra={<Button type="primary" size="large" className="mb-3" onClick={() => { setCurrent({}); setGoalModal(true); }}>Set Your G&O</Button>}
                        className="py-0"
                    />
                </>
            )}
            {goals?.length > 0 && (
                <List
                    dataSource={goals}
                    itemLayout="vertical"
                    bordered={false}
                    renderItem={(item, index) => (
                        <List.Item
                            key={item?.id}
                            className={`goal-item ${status[item?.currentStatusId ? (item?.currentStatusId - 1) : (index % status.length)]}`}
                        >
                            <Row justify={"center"} className="pt-4">
                                <Col span={24} className="px-4">
                                    <Row>
                                        <Col span={20}><Title level={5} className="title">{item?.goalSettingDto?.objective}</Title></Col>
                                        <Col span={4} className="text-end">
                                            {stage?.current === 0 && (
                                                <>
                                                    <Button type="text" className="px-0" icon={<Icon component={editIcon} style={{ fontSize: 16 }} />} onClick={() => { setGoalModal(true); setCurrent(item) }}>Edit</Button>
                                                </>
                                            )}
                                            {stage?.current >= 2 && item?.currentStatusId && (
                                                <Paragraph className="title">
                                                    <Icon component={statusImage[item?.currentStatusId ?? 0]} style={{ fontSize: 20 }} /> {reviewStatus?.filter((status) => status?.value === item?.currentStatusId)?.pop()?.label}
                                                </Paragraph>
                                            )}
                                            {stage?.current >= 2 && !item?.currentStatusId && (
                                                <Paragraph type="secondary">
                                                    <Icon component={ontrack} style={{ fontSize: 20 }} />Not Updated
                                                </Paragraph>
                                            )}
                                        </Col>
                                        <Col span={stage?.current >= 2 ? '24' : '18'}>
                                            <Text type="secondary">Description</Text>
                                            <Paragraph>{item?.goalSettingDto?.description}</Paragraph>
                                        </Col>
                                        {item?.goalSettingDto?.individualComments && (
                                            <Col span={24}>
                                                <Text type="secondary">Your Comment</Text>
                                                <Paragraph>{item?.goalSettingDto?.individualComments}</Paragraph>
                                            </Col>
                                        )}
                                        {stage?.current < 2 && (
                                            <Col span={6} className="text-end">
                                                <Title level={2} className="title">{Number(item?.goalSettingDto?.target).toLocaleString('en-IN')} <Text style={{ fontSize: '0.7em' }} className="title">{item?.goalSettingDto?.measurement?.label}</Text></Title>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                {stage?.current >= 2 &&
                                    <Col className="achieved px-4" span={24}>
                                        <Row justify={"center"} align={"middle"} gutter={[16, 16]}>
                                            <Col span={15} lg={14} xxl={15}>
                                                <Progress
                                                    percent={item?.achievedPercentage ?? 0}
                                                    status={`${status[item?.currentStatusId ? (item?.currentStatusId - 1) : (index % status.length)]}`}
                                                    size={['100%', 10]}
                                                    showInfo={false}
                                                    className="mb-0"
                                                />
                                                <Paragraph className="my-2">Achieved: {item?.achievedPercentage ?? 0}%</Paragraph>
                                            </Col>
                                            <Col span={0} xl={1} xxl={2}></Col>
                                            <Col span={3} lg={5} xl={4} xxl={3}>
                                                <Statistic
                                                    title="Target"
                                                    value={item?.goalSettingDto?.target ?? '-'}
                                                    suffix={item?.goalSettingDto.measurement?.label}
                                                    className="px-3 py-1"
                                                />
                                            </Col>
                                            <Col span={4} lg={5} xxl={4} className="py-2">
                                                <Statistic
                                                    title="Achieved"
                                                    value={item?.target_achieved ?? '-'}
                                                    suffix={item?.target_achieved ? item?.goalSettingDto.measurement?.label : ''}
                                                    className="px-3 py-1 smart"
                                                />
                                                {stage?.current >= 2 && goals.length > 0 && (submittedOn === null&& isNotStarted === 'STARTED' ) && (
                                                    <Button
                                                        className="iconEdit"
                                                        icon={<Icon component={editRoundIcon} style={{ fontSize: 32, color: "transparent" }} />}
                                                        onClick={() => {
                                                            setCurrent(item);
                                                            setProgressModal(true);
                                                        }}
                                                    />)}
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </List.Item>
                    )}
                />
            )}
        </Card>
    )
}

export default GoalsObjectiveCard;