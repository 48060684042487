import React from "react";
import { List, Typography } from 'antd';
import { IComments } from "../../types/Pms";
import NoData from "../../layouts/shared/NoData";

const { Title, Paragraph } = Typography;

interface Props {
    comments: IComments | undefined;
}

const CommentsList: React.FC<Props> = ({ comments }) => {

    return (
        <>
            {(comments?.commentsDtoList?.length === 0 || comments?.commentsDtoList === undefined) ? (
                <>
                    <NoData/>
                </>
            ) : (
                <List
                    dataSource={comments?.commentsDtoList}
                    itemLayout="vertical"
                    bordered={true}
                    className="commentList"
                    renderItem={(item, index) => (
                        <List.Item
                            key={`index_${index}`}
                            className={`p-0 mb-3 w-75 commentItem ${item?.approvedComment ? 'right' : 'left'}`}
                        >
                            <Title level={5} className="title">{item?.approvedComment ? 'Supervisor Comment' : 'My Comment'}</Title>
                            <Paragraph className="m-0 p-2 comment" style={{textAlign:'left'}}>{item?.comments ?? '.'}</Paragraph>
                            <Paragraph className="commentsBy fs-12">{item?.commentedBy}</Paragraph>

                        </List.Item>
                    )}
                />
            )}
        </>
    )
}

export default CommentsList;