import { Navigate, Route, Routes } from "react-router-dom";
import AdminDashboard from "../components/admin/AdminDashboard";
import ConfigureReview from "../components/admin/configure-review-cycle/ConfigureReview";
import Users from "../components/admin/users/Users";
import Requests from "../components/admin/Requests";
import AdminMain from "../layouts/admin-layouts/AdminMain";
import UserView from "../components/admin/users/UserView";
import UserEdit from "../components/admin/users/UserEdit";
import EmployeeMain from "../layouts/employee-layouts/EmployeeMain";
import LoginForm from "../components/auth/LoginForm";
import EmployeeDashboard from "../components/employee/EmployeeDashboard";
import MyTeam from "../components/employee/MyTeam";
import PdLogin from "../components/auth/PdLogin";
import AuthProvider from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import { ROLE } from "../util/Common";
import ExtensionManagement from "../components/admin/ExtensionManagement";

const RouteComponent: React.FC = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="login" element={<LoginForm />} />
                <Route path="pd_login/:token" element={<PdLogin />} />
                <Route path="/" element={<EmployeeMain />}>
                    <Route index element={<RequireAuth requiredRoles={[ROLE.EMPLOYEE]}><EmployeeDashboard /></RequireAuth>}></Route>
                    <Route path="my-team" element={<RequireAuth requiredRoles={[ROLE.MANAGER]}><MyTeam /></RequireAuth>}></Route>
                </Route>

                <Route path="/admin" element={<RequireAuth requiredRoles={[ROLE.ADMIN]}><AdminMain /></RequireAuth>}>
                    <Route index element={<AdminDashboard />} />
                    <Route path="configure-review" element={<ConfigureReview />} />
                    <Route path="requests" element={<Requests />} />
                    <Route path="users">
                        <Route index element={<Users />} />
                        <Route path="view" element={<UserView />} />
                        <Route path="edit" element={<UserEdit />} />
                    </Route>
                    <Route path="ExtensionManagement" element={<ExtensionManagement />} />
                </Route>

                {<Route path='/*' element={<Navigate to={'/'} />} />}
            </Routes>
        </AuthProvider >
    );
}

interface RequireAuthProps {
    requiredRoles: string[];
}

export const RequireAuth: React.FC<React.PropsWithChildren<RequireAuthProps>> = ({ children, requiredRoles }) => {

    const { user } = useAuth();
    const hasRequiredRole = requiredRoles.some(role => user?.role?.includes(role));

    if (!hasRequiredRole) {
        return (
            <Navigate
                to="/"
                replace // <-- redirect
            />
        );
    }

    return <>{children}</>;
};

export default RouteComponent;

