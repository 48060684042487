import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { App } from 'antd';
import { httpInstance } from './http';

interface CustomConfig {
    form?: HTMLFormElement,
    callback?: any,
    invalidateKey?: any,
}

interface QueryConfig {
    key?: any,
    httpConfig?: AxiosRequestConfig,
    mutateConfig?: any,
    queryConfig?: any,
    customConfig?: CustomConfig
}

export interface DataObject {
    content: [],
    empty: boolean,
    first: boolean,
    last: boolean,
    number: number,
    numberOfElements: number,
    pageable: object,
    size: number,
    sort: object,
    totalElements: number,
    totalPages: number
}

export interface ApiResponse {
    statusCode: number,
    error: string,
    errorMap?: object | null | {},
    message?: string,
    data: DataObject | any | null
}
interface ErrorResponse {
    statusCode: number;
    message: string;
}

export const useApi = (queryConfig: QueryConfig) => {
    return useQuery<ApiResponse, ErrorResponse>({
        queryKey: queryConfig.key,
        queryFn: async () => {
            let res = await httpInstance.request({ ...queryConfig.httpConfig });
            return res.data;
        },
        placeholderData: keepPreviousData,
        ...queryConfig.queryConfig,
    });
}

export const useMutate: any = (queryConfig: QueryConfig) => {
    const queryClient = useQueryClient();
    const { message } = App.useApp();
    return useMutation({
        mutationKey: queryConfig.key,
        mutationFn: async (data) => {
            let res = await httpInstance.request({ ...queryConfig.httpConfig, ...{ data: data } });
            return res.data;
        },
        onSuccess(response) {
            if (queryConfig.customConfig?.form && (response?.errorMap || response?.error)) {
                if (response?.errorMap && Object.keys(response?.errorMap)?.length) {
                    Object.keys(response?.errorMap).forEach(key => {
                        return queryConfig.customConfig?.form?.setFields([
                            {
                                name: key,
                                errors: [response?.errorMap[key]],
                            },
                        ]);
                    });
                } else {
                    message.error(response?.error);
                }
            } else if (queryConfig?.customConfig?.callback)
                queryConfig?.customConfig?.callback(response);
        },
        onError: (error: AxiosError) => {
            console.log("Something went wrong!", error);
        },
        onSettled: (data: any) => {
            if (data?.statusCode === 200) {
                queryConfig?.customConfig?.invalidateKey &&
                    queryConfig?.customConfig?.invalidateKey?.forEach((key: any) => {
                        queryClient.invalidateQueries({ queryKey: key });
                    });
            }
        },
        ...queryConfig.mutateConfig,
    });
}