import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Image, Flex } from 'antd';
import '../../styles/dashboard.scss';
import { useApi } from '../../util/useRequestProcessor';
import ReportTable from '../shared/data-table/ReportTable';
import DashboardFooter from './DashboardFooter';
import '../../styles/dashboard.scss'
import userImg from '../../assets/images/vector.svg';
import targetGif from '../../assets/images/target2.gif';
import graphImage from '../../assets/images/graphImg.svg'
import RoundChart from './RoundChart';

const { Title, Text } = Typography;


const AdminDashboard: React.FC = () => {

    const [submitReport, setSubmitReport] = useState<any>([]);

    const { data, isLoading } = useApi({
        key: ['submitReport'],
        httpConfig: {
            method: 'get',
            url: 'dashboard/goals-submitted',
        }
    });

    useEffect(() => {
        if (data) {
            setSubmitReport(data?.data);
        }
    }, [data])

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col span={5}>
                    <Card bordered={false} className="dashboardCard userCard" loading={isLoading}>
                        <Image src={userImg} className="vectorImg mt-3" preview={false} />
                        <Title level={5} className="mt-2">Total No of Users</Title>
                        <Title level={1} className="mt-4">{submitReport?.totalUser}</Title>
                    </Card>
                </Col>
                <Col span={9}>
                    <Card bordered={false} className="dashboardCard gifCard" loading={isLoading}>
                        <Row>
                            <Col span={20}>
                                <Title level={4} className="adminHead">Goals & Objectives - 2024</Title>
                                <Text className="adminSubHead">Submission Summary</Text>
                            </Col>
                            <Col span={4}>
                                <Image src={targetGif} width={'100%'} className='targetGifDashboard' preview={false} />
                            </Col>
                        </Row>
                        <RoundChart
                            submittedCount={submitReport?.submittedCount}
                            notSubmittedCount={submitReport?.notSubmittedCount}
                            submittedLabel={'Submitted'}
                            notSubmittedLabel={'Not Submitted'} />
                    </Card>
                </Col>
                <Col span={10}>
                    <Card bordered={false} className="dashboardCard grapghCard" loading={isLoading}
                        title={
                            <Row>
                                <Title level={4} className="adminHead">Goals & Objectives Approval</Title>
                                <Text className="adminSubHead">Supervisor Approval Status</Text>
                            </Row>
                        }
                    >
                        <Row>
                            <Col span={14} className='graph-column'>
                                <Image src={graphImage} height={'100%'} preview={false} />
                            </Col>
                            <Col span={10} className="mt-4" style={{ textAlign: 'left' }}>
                                <Flex justify='start' align='center' gap={8}>
                                    <div className="bluCircle"></div>
                                    <Text className="adminCountTxt">{submitReport?.supervisorApproved}</Text>
                                    <Text className="adminSecHead">Approved</Text>
                                </Flex>
                                <Flex justify='start' align='center' gap={8}>
                                    <div className="yellowCircle"></div>
                                    <Text className="adminCountTxt">{submitReport?.supervisorNotApproved}</Text>
                                    <Text className="adminSecHead">Not Approved</Text>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <ReportTable />
                </Col>
            </Row>

            <DashboardFooter />

        </>

    )
}

export default AdminDashboard