
import { Col, Row, Typography } from "antd";
import { Titles } from "../../shared/Titles"
import useDocumentTitle from "../../shared/useDocumentTitle"
import ConfigureCycle from "./ConfigureCycle";

const { Title } = Typography;

const ConfigureReview = () => {
    useDocumentTitle(Titles.configureReview);

    return (
        <>
            <Title level={4}>Configuration Review Cycle</Title>
            <p className='pt-2 pb-4'>Displays all the employees Performance Review Schedule</p>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <ConfigureCycle />
                </Col>
            </Row>
        </>
    )
}

export default ConfigureReview