import React, { useState, useEffect } from 'react';
import PmsContext from './PmsContext';
import { PmsContextType, OptionItem, TimelineItem, IGoal, IComments, IGoalsRequest, Review } from '../types/Pms';
import { useApi } from '../util/useRequestProcessor';
import useAuth from '../hooks/useAuth';

interface ProviderProps {
    children: React.ReactNode;
}

const PmsProvider: React.FC<ProviderProps> = ({ children }) => {
    const { user } = useAuth();
    const [measurement, setMeasurement] = useState<any[]>([]);
    const [profile, setProfile] = useState<undefined>();
    const [timeline, setTimeline] = useState<TimelineItem[]>();
    const [stage, setStage] = useState<any>({
        current: 0,
        status: 'wait'
    });
    const [goals, setGoals] = useState<IGoal[]>([]);
    const [graphData, setGraphData] = useState([]);
    const [baseCycles, setBaseCycles] = useState<Review[]>([]);
    const [goalModal, setGoalModal] = useState<boolean>(false);
    const [approvalModal, setApprovalModal] = useState<boolean>(false);
    const [progressModal, setProgressModal] = useState<boolean>(false);
    const [commentsModal, setCommentsModal] = useState<boolean>(false);
    const [performerDetails, setPerformerDetails] = useState<any>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [current, setCurrent] = useState<any>({});
    const [currentCycle, setCurrentCycle] = useState<any>('4');
    const [commentArray, setCommentArray] = useState<any>();
    const [reviewStatus, setReviewStatus] = useState<OptionItem[]>([]);
    const [approvedOn, setApprovedOn] = useState<any>();
    const [submittedOn, setSubmittedOn] = useState<any>();
    const [activeTab, setActiveTab] = useState<string | undefined>();
    const [rootCycleId, setRootCycleId] = useState<number>(1);
    const [comments, setComments] = useState<IComments[]>([]);
    const [activeComment, setActiveComment] = useState<IComments | undefined>(undefined);
    const [objectiveData, setObjectiveData] = useState<IGoalsRequest>({
        userId: user?.id,
        rootCycleId: rootCycleId,
        reviewTimelineId: 2,
        fromAchievement: false
    });
    const [myTeamCurrentLabel, setMyTeamCurrentLabel] = useState<any>();
    const [level, setLevel] = useState<any>();
    const [showRequestBtn, setShowRequestBtn] = useState<any>();
    const [supervisorTab, setSupervisorTab] = useState<any>('3');
    const [extensionExport, setExtensionExport] = useState<any>();
    const [extensionFilters, setExtensionFilters] = useState<any>();
    const [reportsFilters, setReportsFilters] = useState<any>();

    const { data: statuses } = useApi({
        key: ["reviewStatus"],
        httpConfig: {
            method: "get",
            url: "goals/current-status",
            params: {
                pageSize: 10,
                pageNo: 0,
                sortBy: "id",
                sortDir: "asc",
            },
        },
    });

    const { data: measurements } = useApi({
        key: ["mesurements"],
        httpConfig: {
            method: "get",
            url: "goals/measurement",
            params: {
                pageSize: 10,
                pageNo: 0,
                sortBy: "name",
                sortDir: "desc",
            },
        },
    });

    useEffect(() => {
        if (statuses?.data?.content) {
            setReviewStatus(statuses?.data?.content?.map((item: any) => ({
                value: item.id,
                label: item.name
            })));
        }
        if (measurements?.data?.content) {
            setMeasurement(measurements?.data?.content);
        }
    }, [statuses, measurements]);

    const pmsContextValue: PmsContextType = {
        profile,
        setProfile,
        timeline,
        setTimeline,
        stage,
        setStage,
        baseCycles,
        setBaseCycles,
        goalModal,
        setGoalModal,
        approvalModal,
        setApprovalModal,
        progressModal,
        setProgressModal,
        commentsModal,
        setCommentsModal,
        refresh,
        setRefresh,
        measurement,
        current,
        setCurrent,
        currentCycle,
        setCurrentCycle,
        commentArray,
        setCommentArray,
        reviewStatus,
        setReviewStatus,
        approvedOn,
        setApprovedOn,
        submittedOn,
        setSubmittedOn,
        goals,
        setGoals,
        activeTab,
        setActiveTab,
        rootCycleId,
        setRootCycleId,
        comments,
        setComments,
        activeComment,
        setActiveComment,
        objectiveData,
        setObjectiveData,
        graphData,
        setGraphData,
        myTeamCurrentLabel,
        setMyTeamCurrentLabel,
        performerDetails,
        setPerformerDetails,
        level,
        setLevel,
        showRequestBtn,
        setShowRequestBtn,
        supervisorTab,
        setSupervisorTab,
        extensionExport,
        setExtensionExport,
        extensionFilters,
        setExtensionFilters,
        reportsFilters,
        setReportsFilters
    };

    return <PmsContext.Provider value={pmsContextValue}>
        {children}
    </PmsContext.Provider>;
};

export default PmsProvider;