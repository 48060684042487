import { Button, Flex, Modal, Image, Typography, Input, Form, App, Tag } from "antd";
import React, { useState } from "react";
import confirm from "../../assets/images/confirm.gif";
import { useMutate } from "../../util/useRequestProcessor";
import useAuth from "../../hooks/useAuth";
import { usePmsContext } from "../../context/PmsContext";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { dateTime } from "../../util/Common";


const { Title, Text } = Typography;
const { TextArea } = Input;

interface ChildProps {
    model: string;
    supervisor?: any;
    currentTab?: any;
    currentName?: any;
    extensionRowData?: any;
}

const ConfirmationModal: React.FC<ChildProps> = ({
    model,
    supervisor,
    currentTab,
    currentName,
    extensionRowData
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isExtension, setIsExtension] = useState(false);
    const [approveExtension, setApproveExtension] = useState(false);
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState("");
    const [reason, setReason] = useState<string>('');
    const [rejectReason, setRejectReason] = useState<string>('');
    const [actionType, setActionType] = useState<'approve' | 'reject' | null>(null);
    const { message } = App.useApp();
    const { user } = useAuth();
    let index;
    let dataPayload: any;
    let superPayload: any;
    let extensionPayload: any;
    let approvalRejectPayload: any;
    const { setStage, submittedOn, showRequestBtn, supervisorTab, myTeamCurrentLabel } = usePmsContext();


    let currentTabName = currentName?.replace(" Review", "");



    dataPayload = {
        userId: user?.id,
        managerId: null,
        comments: "",
        reviewPrefix: null,
        reviewSubmit: false,
    };


    superPayload = {
        userId: user?.id,
        managerId: null,
        currentReviewCycleId: currentTab,
        reviewPrefix: currentTabName,
        comments: value,
        reviewSubmit: true,
    };
    extensionPayload = {
        supervisorId: user?.id,
        reason: reason,
        cycleId: supervisorTab,
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showisSupervisor = () => {
        setIsSupervisor(true);
    };
    const showConfirm = () => {
        setVisible(true);

    };
    const showIsExtension = () => {
        setIsExtension(true);
    };

    const showApproveExtension = (type: 'approve' | 'reject') => {
        setActionType(type);
        setApproveExtension(true);
    };

    const handleOk = () => {
        // supervisor(true);
        approval(dataPayload);
        setIsModalOpen(false);
    };

    const handleQSubmitOk = () => {
        superApproval(superPayload);
    };
    const handleExSubmitOk = () => {
        reasonSubmit(extensionPayload);
    };

    const onStepChange = (variable: any) => {
        setStage(variable);
    };

    const approveRejectExtension = (status: boolean) => {
        approvalRejectPayload = {
            id: extensionRowData?.id,
            status: status,
            rejectionReason: rejectReason
        }
        approvalReject(approvalRejectPayload)
    }

    const { isLoading: approvalLoading, mutate: approval = dataPayload } =
        useMutate({
            key: "role",
            httpConfig: {
                method: "post",
                url: "goals/goal-submit",
            },
            customConfig: {
                form: dataPayload,
                callback: async (response: any) => {
                    // setIsModalOpen(true);
                },
            },
        });

    const { isLoading: approveRejectLoading, mutate: approvalReject = approvalRejectPayload } =
        useMutate({
            key: ['approvalReject'],
            httpConfig: {
                method: "post",
                url: "team/extend-date",
            },
            customConfig: {
                form: dataPayload,
                callback: async (response: any) => {
                    if (response?.error) {
                        message.error(response?.error)
                    } else if (response?.message && response?.data != null) {
                        message.success(response?.message + ' ' + dateTime(response?.data?.extendedDate, 'date1'));
                        handleConfirmCancel();
                    } else if (response?.message && response?.data == null) {
                        message.success(response?.message);
                        handleConfirmCancel();
                    }
                },
                invalidateKey: [['extensionManagement']],
            },
        });

    const { isLoading: supervisorLoading, mutate: superApproval = superPayload } =
        useMutate({
            key: "role",
            httpConfig: {
                method: "post",
                url: "goals/goal-submit",
            },
            customConfig: {
                form: superPayload,
                callback: async (response: any) => {
                    setIsModalOpen(false);
                    setIsSupervisor(false);
                    showConfirm();
                },
            },
        });
    const { isLoading: extensionLoading, mutate: reasonSubmit = extensionPayload } =
        useMutate({
            key: ['extensionSubmit'],
            httpConfig: {
                method: "post",
                url: "team/raise-request",
            },
            customConfig: {
                callback: async (response: any) => {
                    if (response?.message !== null) {
                        message.success(response?.message, 4);
                        handleCancel()
                    } else {
                        message.error(response?.error, 4);
                    }
                },
                invalidateKey: [["extensionButtonResponse"]]
            },
        });

    const handleQCancel = () => {
        setIsModalOpen(false);
        setIsSupervisor(false);
    };
    const handleSupervisorOk = () => {
        setVisible(false);
        index = 3;
        onStepChange(index);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsExtension(false);
        setReason('');
    };

    const handleConfirmCancel = () => {
        setIsConfirm(false);
        setApproveExtension(false);
    };

    const handleConfirmOk = () => {
        setIsConfirm(false);
        setIsSupervisor(true);
    };

    const onFinishFailed = (errorInfo: any) => {
    };

    return (
        <>
            {(submittedOn === null) && (
                <>
                    {model === "submitApproval" && (
                        <Button
                            className="submit_btn"
                            onClick={showModal}
                            loading={approvalLoading}
                        >
                            Submit for Approval
                        </Button>
                    )}
                    {model === "submitSupervisor" && (
                        <Button className="submit_btn" onClick={showisSupervisor}>
                            Submit to Supervisor
                        </Button>
                    )}

                </>
            )}

            {((showRequestBtn?.isShow === true && myTeamCurrentLabel?.status === 'COMPLETED') && model === "extension") && (
                <>
                    <span className="px-3"><Tag className="remainingCountTag" icon={<ExclamationCircleOutlined />} color="warning">{`Remaining Request Count : ${2 - showRequestBtn?.count}`}</Tag></span>
                    <Button type='primary' onClick={showIsExtension}>{'Request for Extension'}</Button>
                </>
            )}

            {(model === 'approveExtension') && (
                <>
                    <Button className='m-1' type='primary' onClick={() => showApproveExtension('approve')}>Approve</Button>
                    <Button className='m-1' type='default' onClick={() => showApproveExtension('reject')}>Reject</Button>
                </>
            )}
            <Modal
                className={
                    model === "submitSupervisor"
                        ? "approval"
                        : model === "confirm"
                            ? "alert"
                            : "confirm"
                }
                title=""
                open={isModalOpen}
                centered
                footer={null}
                closable={false}
            >
                <>
                    <Flex vertical align="center">
                        <Image height={80} src={confirm} preview={false} width={80} />
                        <Title
                            level={5}
                            className="mt-1 greyTxt"
                            style={{ textAlign: "center" }}
                        >
                            Your goals and objectives has been sent for Supervisor Approval
                        </Title>
                    </Flex>
                    <Flex className="mt-3" align="center" justify="center">
                        <Button type="primary" ghost onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className="confirm-btn" type="primary" onClick={handleOk}>
                            Okay
                        </Button>
                    </Flex>
                </>
            </Modal>

            <Modal
                className={
                    model === "submitSupervisor"
                        ? "approval"
                        : model === "confirm"
                            ? "alert"
                            : "confirm"
                }
                title=""
                open={isConfirm}
                centered
                footer={null}
                closable={false}
            >
                <>
                    <Flex vertical align="center">
                        <Title
                            level={5}
                            className="mt-3 greyTxt"
                            style={{ textAlign: "center" }}
                        >
                            Are you sure want to submit your progress to supervisor?
                        </Title>
                    </Flex>
                    <Flex align="center" justify="center">
                        <Button type="primary" ghost onClick={handleConfirmCancel}>
                            Cancel
                        </Button>
                        <Button
                            className="confirm-btn"
                            type="primary"
                            onClick={handleConfirmOk}
                        >
                            Okay
                        </Button>
                    </Flex>
                </>
            </Modal>

            <Modal
                className="approval"
                title=""
                open={isSupervisor}
                onCancel={handleCancel}
                centered
                footer={null}
                closable={false}
            >
                <Form
                    name="addGoal"
                    layout="vertical"
                    onFinish={handleQSubmitOk}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Flex vertical>
                        <Title level={4}>
                            Quarter 1 Performance Review
                        </Title>
                        <span style={{ fontSize: "14px" }}>
                            Enter your self valuation for overall Q1 cycle.
                        </span>
                        <Title level={4} className="mt-3">
                            Comments
                        </Title>
                        <Form.Item
                            name="objective"
                            className="formInputItem"
                            label=""
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the Comment!",
                                },
                            ]}
                        >
                            <TextArea
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Enter your Comments"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Flex>
                    <Flex align="center" justify="flex-end" className="mt-3">
                        <Button type="primary" ghost onClick={handleQCancel}>
                            Skip
                        </Button>
                        <Button
                            className="confirm-btn"
                            type="primary"
                            htmlType="submit"
                            loading={supervisorLoading}
                        >
                            Submit
                        </Button>
                    </Flex>
                </Form>
            </Modal>

            <Modal
                className="confirm"
                title=""
                open={visible}
                onCancel={handleConfirmCancel}
                centered
                footer={null}
                closable={false}
            >
                <>
                    <Flex vertical align="center">
                        <Image height={80} src={confirm} preview={false} width={80} />

                        <Title level={5} className="mt-3" style={{ textAlign: "center" }}>
                            Q1 progress submitted successfully
                        </Title>
                    </Flex>
                    <Flex align="center" justify="center">
                        <Button
                            className="confirm-btn"
                            type="primary"
                            onClick={handleSupervisorOk}
                        >
                            Okay
                        </Button>
                    </Flex>
                </>
            </Modal>

            <Modal
                className="approval"
                title=""
                open={isExtension}
                onCancel={handleCancel}
                centered
                footer={null}
                closable={false}
            >
                <Form
                    name="addGoal"
                    layout="vertical"
                    onFinish={handleExSubmitOk}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Flex vertical>
                        <Title level={4}>
                            Reason for Extension
                        </Title>
                        <Title level={4} className="mt-3">
                            Comments
                        </Title>
                        <Form.Item
                            name="reason"
                            className="formInputItem"
                            initialValue={reason}
                            label=""
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the Reason!",
                                },
                                { max: 250, message: "Reason cannot be more than 250 characters" },
                                { whitespace: true, message: "This is a mandatory field" }
                            ]}
                        >
                            <TextArea
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder="Type your Reason"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Flex>
                    <Flex align="center" justify="flex-end" className="mt-3">
                        <Button type="primary" ghost onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            className="confirm-btn"
                            type="primary"
                            htmlType="submit"
                            loading={extensionLoading}
                        >
                            Submit
                        </Button>
                    </Flex>
                </Form>
            </Modal>

            <Modal
                className="approval"
                title={actionType === 'approve' ? "Extension Confirmation" : "Extension Rejection"}
                open={approveExtension}
                onCancel={handleCancel}
                centered
                footer={null}
                closable={false}
            >
                {(actionType === 'approve') ? (
                    <>
                        <Flex vertical align="center">
                            <Text className="confirmationSecomdaryText" style={{ 'color': '#00BC40' }}>Are you sure you want to approve the Extension Request for 3 days?</Text>
                            <Text className="mt-1">( {extensionRowData?.userName} - {extensionRowData?.designation} )</Text>
                        </Flex>
                        <Flex className="mt-4" align="center" justify="right">
                            <Button type="primary" ghost onClick={handleConfirmCancel}>
                                Cancel
                            </Button>
                            <Button
                                className="confirm-btn"
                                type="primary"
                                onClick={() => approveRejectExtension(true)}
                                loading={approveRejectLoading}>
                                Confirm
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <>
                        <Form
                            name="rejectReasonForm"
                            layout="vertical"
                            onFinish={() => approveRejectExtension(false)}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Flex vertical align="center">
                                <Text className="confirmationSecomdaryText" style={{ 'color': '#CF1919' }}>Are you sure, you want to reject the Extension Request?</Text>
                                <Text className="mt-1">( {extensionRowData?.userName} - {extensionRowData?.designation} )</Text>
                            </Flex>
                            <Form.Item
                                name="rejectReason"
                                className="formInputItem reasonRejectItem"
                                initialValue={rejectReason}
                                label={
                                    <Text type="secondary">
                                        Reason for Rejection
                                    </Text>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Reason!",
                                    },
                                    { max: 250, message: "Reason cannot be more than 250 characters" },
                                    { whitespace: true, message: "This is a mandatory field" }
                                ]}
                            >
                                <TextArea
                                    value={reason}
                                    id="rejectReason"
                                    onChange={(e) => setRejectReason(e.target.value)}
                                    placeholder="Type your Reason"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                            <Flex className="mt-4" align="center" justify="right">
                                <Button type="primary" ghost onClick={handleConfirmCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    className="confirm-btn"
                                    type="primary"
                                    htmlType="submit"
                                    onSubmit={() => approveRejectExtension(false)}
                                    loading={approveRejectLoading}
                                >
                                    Confirm
                                </Button>
                            </Flex>
                        </Form>
                    </>
                )}

            </Modal>
        </>
    );
};

export default ConfirmationModal;
