import { useState } from 'react'
import { IAuthUser, AuthContextType } from '../types/Users';
import { useNavigate } from 'react-router-dom';
import { useMutate } from '../util/useRequestProcessor';

const useAuth = (): AuthContextType => {
    const [user, setUser] = useState<IAuthUser | null>(() => {
        const localStorageData = localStorage.getItem('authUser');
        let authUser = null;
        if (localStorageData) {
            authUser = JSON.parse(localStorageData) as IAuthUser;
        }
        return authUser
    });
    const navigate = useNavigate();

    const logoutMutation = useMutate({
        key: 'logout',
        httpConfig: {
            method: 'get',
            url: '/auth/signout',
        },
        customConfig: {
            callback: async (response: any) => {
                setUser(null);
                localStorage.clear();
                sessionStorage.clear();
                navigate('/login');
            }
        },
    });

    const logout = async () => {
        try {
            await logoutMutation.mutateAsync();
        } catch (error: any) { }
    }

    const login = (user: IAuthUser) => {
        localStorage.setItem('authUser', JSON.stringify(user));
        localStorage.setItem('token', user?.token);
        localStorage.setItem('refreshToken', user?.refreshToken);
        setUser(user);
        navigate('/');
    };

    return { user, setUser, login, logout };
};

export default useAuth;
