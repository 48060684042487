import { useEffect, useRef, useState } from "react";
import {
    Button,
    Collapse,
    Flex,
    Form,
    Typography,
    App,
    Drawer,
    Row,
    Col,
} from "antd";
import { useApi, useMutate } from "../../../util/useRequestProcessor";
import useAuth from "../../../hooks/useAuth";
import TextArea from "antd/es/input/TextArea";
import AddGoal from "./AddGoals";
const { Title, Text } = Typography;

interface Props {
    model?: any;
    stepNo?: any;
    item?: any;
    stepChange?: (stepNo: number) => void;
    onClick?: () => void;
}

interface goalSettingData {
    description: any;
    finalReviewComment: any;
    id: number;
    measurement: any;
    objective: any;
    target: number;
}
interface UserData {
    currentStatusId: any;
    currentStatus: any;
    reviewCycleId: any;
    userId: any;
    goalAchievementId: any;
    goalSettingDto: goalSettingData;
    id: number;
    targetAchieved: any;
    target_achieved: any;
    achievedPercentage: any;
}

const ViewGoals: React.FC<Props> = ({ model, item }) => {
    const [visible, setVisible] = useState(false);
    const { Panel } = Collapse;
    const { message } = App.useApp();
    const cycleID: number = 1;
    const [dataArray, setDataArray] = useState<UserData[]>([]);
    const { user } = useAuth();
    const [commnetsValue, setComments] = useState("");
    const [commnetsDisplay, setDisplayComments] = useState([]);
    const [form] = Form.useForm<any>();
    let payload: any;
    const fieldRef = useRef<HTMLInputElement>(null);

    const colors = ['#ffa305', '#7B2B7D', '#03bf7a', '#4f53f8'];

    const getColorByIndex = (index: any) => {
        const colorIndex = index % colors.length;
        return colors[colorIndex];
    };

    const { data } = useApi({
        key: ["goalObjective", item?.userId],
        httpConfig: {
            method: "post",
            url: "goals/user-goals-obj",
            data: {
                userId: item?.userId,
                rootCycleId: cycleID,
                reviewTimelineId: item?.timelLineId,
                fromAchievement: false,
            }
        },
        queryConfig: {
            enabled: visible
        }
    });

    useEffect(() => {
        setDataArray(data?.data?.reviewInsight);
        setDisplayComments(data?.data?.comments);
        
        // eslint-disable-next-line
    }, [data]);

    const { ApprovePending, mutate: reviewApi = payload } = useMutate({
        key: ["approve-goals"],
        httpConfig: {
            method: "post",
            url: "goals/approve-request",
        },
        customConfig: {
            form: form,
            callback: async (response: any) => {
                if (response.data != null) {
                    message.success(response?.message, 4);
                    handleCancel();
                    form.resetFields();
                } else {
                    message.error(response?.error, 4);
                }
            },
            invalidateKey: [['get-approval', user?.id], ['baseCycles', user?.id]],
        },
    });

    const showModal = () => {
        setVisible(true);
        setComments("");
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleClick = () => { };

    const onFinish = (values: any) => {
        payload = {
            managerId: user?.id,
            timeLineId: item?.timelLineId,
            comment: commnetsValue,
        };
        reviewApi(payload)
    };

    const onFinishFailed = () => {
        fieldRef.current?.focus();
    }

    const [isRecuit, setRecuit] = useState(false);

    const handleRecuitChange = (key: any) => {
        setRecuit(!isRecuit);
    };

    if (dataArray && dataArray.length > 0) {
        dataArray.forEach((item) => {
            let value = (item.targetAchieved / item.goalSettingDto.target) * 100;
            item.achievedPercentage = parseFloat(value.toFixed(2));
        });
    }

    return (
        <>
            <Flex className="px-3 justify-content-center" justify="center" key={'button_' + item?.empId}>
                <Button type="primary" ghost className="d-flex" onClick={showModal}>
                    View Goals
                </Button>
            </Flex>
            <Drawer
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>Review Goals & Objectives</div>
                        <div hidden={item?.status === true} className="me-3">
                            <AddGoal
                                model="RMadd"
                                teamMateID={item.userId}
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                }
                width={720}

                // onClick={() => onFinish(model)}
                maskClosable={false}
                footer={
                    <Row gutter={16} justify={"end"} hidden={item?.status === true}>
                        <Col>
                            <Button
                                type="primary"
                                ghost
                                size="large"
                                onClick={handleCancel}
                                loading={ApprovePending}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="large"
                                onClick={form.submit}
                                htmlType="submit"
                                loading={ApprovePending}
                            >
                                Approve
                            </Button>
                        </Col>
                    </Row>
                }
                open={visible}
                onClose={handleCancel}
            >
                {dataArray?.map((items: any, index: any) => (
                    <>
                        {/* <Collapse className='review-collapse' defaultActiveKey={['1']} ghost items={items} expandIconPosition='end' /> */}
                        <Collapse
                            key={index}
                            ghost
                            expandIconPosition="end"
                            onChange={() => handleRecuitChange(index)}
                            className="review-progress"
                        // defaultActiveKey={}
                        >
                            <Panel
                                header={
                                    <Flex justify="space-between">
                                        <span style={{ color: getColorByIndex(index) }}>{items?.goalSettingDto?.objective}</span>

                                        {/* <span onClick={ }><EditOutlined /></span> */}
                                    </Flex>
                                }
                                key={index.toString()}
                                className="mb-4"
                            >
                                <Flex justify="space-between">
                                    <Text className="d-block sub-head1 mt-0">Description</Text>
                                    <span hidden={item?.status === true}>
                                        <AddGoal
                                            model="ViewGoalEdit"
                                            goal={items}
                                            teamMateID={item.userId}
                                            onClick={handleClick}
                                        />
                                    </span>
                                </Flex>
                                <Title className="mt-0" level={5}>
                                    {items?.goalSettingDto?.description}
                                </Title>

                                <Flex className="mt-3" justify="space-between">
                                    <Title level={5}>Target</Title>
                                    <Text className="target-text">
                                        {Number(items?.goalSettingDto?.target).toLocaleString('en-IN') +
                                            " " +
                                            items?.goalSettingDto?.measurement?.label}
                                    </Text>
                                </Flex>
                            </Panel>
                        </Collapse>
                    </>
                ))}

                {commnetsDisplay?.map(
                    (comments: any, index: any) =>
                        comments?.comments !== null &&
                        comments?.comments !== "" && (
                            <>
                                <div key={index}>
                                    <Text className="comment-title">Comments</Text>
                                    <Flex vertical>
                                        <Text className="super-text mt-1 w-75">
                                            {comments?.comments}
                                        </Text>
                                        <Flex className="mt-1 px-1" justify="flex-start">
                                            <span className="fs-12">{comments?.commentedBy}</span>
                                        </Flex>
                                    </Flex>
                                </div>
                            </>
                        )
                )}
                <Form
                    name="reviewProgress"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Flex
                        hidden={item?.status === true}
                        vertical
                        className="mt-4 commentsCard"
                    >
                        <Text className="modal-label">Comments</Text>
                        <Form.Item
                            name="Comments"
                            className="formInputItem"
                            label=""
                            rules={[
                                { required: true, message: "This is a mandatory field" },
                                {
                                    max: 250,
                                    message: "Comment cannot be more than 250 characters.",
                                },
                            ]}
                        >
                            <TextArea
                                id="Comments"
                                ref={fieldRef}
                                placeholder={item?.status ? "Please enter your Comment" : ""}
                                hidden={item?.status === true}
                                onChange={(e) => setComments(e.target.value)}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Flex>
                </Form>
            </Drawer>
        </>
    );
};

export default ViewGoals;
