import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AuthContext from './AuthContext';
import { AuthContextType } from '../types/Users';

interface AuthProviderProps {
    children: React.ReactNode; // Define children prop
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const { user, login, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/login')
        }
        // eslint-disable-next-line
    }, []);

    const authContextValue: AuthContextType = {
        user,
        login,
        logout,
    };

    return <AuthContext.Provider value={authContextValue}>
        {children}
    </AuthContext.Provider>;
};

export default AuthProvider;