import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import '../../../styles/datatable.scss';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useApi, DataObject } from '../../../util/useRequestProcessor';
import { usePagination } from '../../../context/PaginationProvider';
import { dateTime } from '../../../util/Common';

const DataTable: React.FC = () => {

    const navigate = useNavigate();
    const { pagination, setTotal, setPage, setSorter } = usePagination();
    const { page, pageSize, sort_by, sort_order } = pagination;

    const [response, setResponse] = useState<DataObject[] | []>([]);

    const [paginationConfig, setPaginationConfig] = useState({
        pageSize: pageSize,
        page: page,
        total: 100,
        onChange: function (page: number, pageSize: number) {
            setPage(page);
            setPaginationConfig((prev) => {
                return {
                    ...prev,
                    ...{
                        pageSize: pageSize,
                        page: page
                    },
                };
            });
        },
        showTotal: function (total: any, range: any) {
            return `Showing ${range[0]}-${range[1]} of ${total} entries`;
        },
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
        locale: { items_per_page: "" },
    });

    const handleTableChange = (pagination: any, __: any, sorter: any) => {
        // const { current, pageSize } = pagination;
        const { column, columnKey } = sorter;
        const { sortName } = column || {};
        setSorter(sortName || columnKey, sorter?.order)
    };

    const { isFetching, isPending, data } = useApi({
        key: ['users', pagination],
        httpConfig: {
            method: 'post',
            url: 'user-details/users',
            params: {
                'pageSize': pageSize,
                'pageNo': page,
                'sortBy': sort_by,
                'sortDir': sort_order
            },
        }
    });

    useEffect(() => {
        if (data?.data?.content) {
            setResponse(data?.data?.content);
            setTotal(data?.data?.totalElements);
            setPaginationConfig((prev) => {
                return {
                    ...prev,
                    ...{
                        total: data?.data?.totalElements || 0
                    },
                };
            });
        }
        // eslint-disable-next-line
    }, [data, setTotal]);

    const handleRoute = (index: any) => {
        if (index === 0) {
            navigate('view')
        } else {
            navigate('edit')
        }
    }

    const columns: any = [
        {
            title: 'Emp ID',
            dataIndex: 'empId',
            key: 'empId',
            sorter: true
        },
        {
            title: 'Employee Name',
            dataIndex: 'firstName',
            key: 'name',
            render: (idx: number, x: any) => x.firstName + ' ' + x.lastName,
            sorter: true
        },
        {
            title: 'DOJ',
            dataIndex: 'dateOfJoining',
            key: 'dateOfJoining',
            render: (idx: number, x: any) => dateTime(x.dateOfJoining, 'date1'),
            sorter: true
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Reporting Manager',
            dataIndex: 'reportingManager',
            key: 'reportingManager',
            sorter: true
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            sorter: true
        },
        {
            title: 'Actions',
            key: 'actions',
            render: () => (
                <>
                    <Button type="link" onClick={() => handleRoute(0)} icon={<EyeOutlined />}></Button>
                    <Button type="link" onClick={() => handleRoute(1)} icon={<EditOutlined />}></Button>
                </>
            ),
        },
    ];

    const zigZagRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    return (

        <Table
            dataSource={response}
            rowKey='id'
            className='userTable'
            rowClassName={zigZagRowClassName}
            columns={columns}
            pagination={{ ...paginationConfig }}
            loading={isFetching || isPending}
            onChange={handleTableChange}
            showSorterTooltip={false}
        />

    );
};

export default DataTable;