import React, { useEffect } from 'react';
import { Card, Col, Row, Typography, Avatar } from 'antd';
import { useApi } from '../../util/useRequestProcessor'
import useAuth from "../../hooks/useAuth";
import { dateTime } from "../../util/Common";
import { usePmsContext } from '../../context/PmsContext';

const { Title, Paragraph } = Typography;

const ProfileCard: React.FC = () => {
    const { user } = useAuth();
    const { profile, setProfile } = usePmsContext();

    const { data, isLoading} = useApi({
        key: ['profile', user?.id],
        httpConfig: {
            method: 'get',
            url: `user-details/users/${user?.id}`,
        },
        queryConfig: {
            enabled: !!user?.id
        }
    });

    useEffect(() => {
        if (data?.data) {
            setProfile?.(data?.data)
        }
    }, [data, setProfile])

    return (
        <Card bordered={false} loading={isLoading}>
            <Row align={"middle"}>
                <Col span={24} lg={10} xl={8} xxl={6}>
                    <Row gutter={16}>
                        <Col span={6} xl={7} xxl={6}>
                            <Avatar src={profile?.profilePicture} size={100}>{profile?.firstName?.charAt(0)?.toUpperCase()}</Avatar>
                        </Col>
                        <Col span={18} xl={17} xxl={18}>
                            <Title level={3} className='mb-1'>{profile?.firstName != null ? profile?.firstName + ' ' + profile?.lastName : '-'}</Title>
                            <Paragraph className='mb-1' type='secondary'>{profile?.employeeId != null ? profile?.employeeId : '-'} {profile?.quessDesignation != null ? "| " + profile?.quessDesignation : ''}</Paragraph>
                            <Paragraph className='copyIcon blueTxt fw-400' copyable >{profile?.email != null ? profile?.email : '-'}</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col className='subContent' span={24} lg={14} xl={16} xxl={18}>
                    <Row>
                        <Col span={8} xxl={6}>
                            <Paragraph type='secondary' className='mb-1'>Designation</Paragraph>
                            <Paragraph className='fw-500'>{profile?.quessDesignation != null ? profile?.quessDesignation : '-'}</Paragraph>
                        </Col>
                        <Col span={8} xxl={6}>
                            <Paragraph type='secondary' className='mb-1'>Department</Paragraph>
                            <Paragraph className='fw-500'>{profile?.subVertical != null ? profile?.subVertical : '-'}</Paragraph>
                        </Col>
                        <Col span={8} xxl={6}>
                            <Paragraph type='secondary' className='mb-1'>Reporting To</Paragraph>
                            <Paragraph className='fw-500'>{profile?.reportingManager != null ? profile?.reportingManager : '-'}</Paragraph>
                        </Col>
                        <Col span={8} xxl={6}>
                            <Paragraph type='secondary' className='mb-1'>Date of Joining</Paragraph>
                            <Paragraph className='fw-500'>{profile?.dateOfJoining != null ? dateTime(profile?.dateOfJoining, 'date1') : '-'}</Paragraph>
                        </Col>
                        <Col span={8} xxl={6}>
                            <Paragraph type='secondary' className='mb-1'>Date of Birth</Paragraph>
                            <Paragraph className='fw-500'>{profile?.dob != null ? dateTime(profile?.dob, 'date1') : '-'}</Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default ProfileCard;