import { useState, useEffect, useRef } from "react";
import { Button, Form, Flex, Image, Input, Space, Tag, Typography, Row, Col, Progress, InputNumber, Slider, App, Collapse, Radio, Drawer } from "antd";
import editImg from "../../../assets/images/editBlue.svg"
import { SmileOutlined } from '@ant-design/icons';
import { MehOutlined } from '@ant-design/icons';
import { FrownOutlined } from '@ant-design/icons';
import useAuth from "../../../hooks/useAuth";
import { useApi, useMutate } from "../../../util/useRequestProcessor";
import { Container } from "react-bootstrap";
import { Content } from "antd/es/layout/layout";
import { usePmsContext } from "../../../context/PmsContext";
import { IGoal } from "../../../types/Pms";
import { dateTime } from "../../../util/Common";

const { Text, Title, Paragraph } = Typography;
const { Group } = Radio;
const { TextArea } = Input;

interface Props {
    model: any;
    tab: any;
    teamMateData: any;
}
const Review: React.FC<Props> = ({ model, tab, teamMateData }) => {
    const [open, setOpen] = useState(false);
    const [commentData, setCommentData] = useState<any>([]);
    const [formComments, setFormComments] = useState<string | undefined>(undefined); // Initial status is empty
    const [clickedTag, setClickedTag] = useState<number | undefined>(undefined);
    const [form] = Form.useForm<any>();
    const cycleID = tab.tab;
    const { user } = useAuth();
    const { myTeamCurrentLabel } = usePmsContext();
    const fieldRef = useRef<HTMLInputElement>(null);
    // Define mapping of statuses to colors
    let dataPayload: any;
    let rootCycleId = 1;

    const colorArray = ['#ffa305', '#7B2B7D', '#03bf7a', '#4f53f8'];

    const onFinish = (values: any) => {
        const { selectTags, comments } = values;
        setFormComments(comments);
        setClickedTag(selectTags);
        dataPayload = {
            performanceStatusId: clickedTag,
            comments: comments,
            userId: teamMateData?.id,
            managerId: user?.id,
            currentReviewCycleId: Number(cycleID),
        }
        onReiviewSubmit(dataPayload);
    };
    const { isPending: AddPending, mutate: onReiviewSubmit = dataPayload } = useMutate({
        key: ["team-performance"],
        httpConfig: {
            method: "post",
            url: "team/performance-status",
        },
        customConfig: {
            callback: async (response: any) => {
                if (response?.data !== null) {
                    message.success(response?.message, 4);
                    handleVisibleCancel();
                } else {
                    if (clickedTag === null) {
                        fieldRef.current?.focus();
                    } else {
                        message.error(response?.error, 4);
                    }
                }
            },
            invalidateKey: [["team-performance", user?.id]]
        },
    });

    const { mutate: viewAllComments } = useMutate({
        key: ['view-team-performance', teamMateData?.id],
        httpConfig: {
            method: "get",
            url: `goals/view/comments/${teamMateData?.id}/${rootCycleId}`
        },
        customConfig: {
            callback: async (response: any) => {
                if (response?.data !== null) {
                    setCommentData(response?.data?.commentsDtoList);
                }
            },
        },
    });

    useEffect(() => {
        setFormComments(teamMateData?.performanceCommet);
        form.setFieldsValue({ selectTags: formComments });
        setClickedTag(teamMateData?.performanceLevelId);
        // eslint-disable-next-line 
    }, [commentData, teamMateData])

    const showVisible = () => {
        setOpen(true);
        refetch();
        viewAllComments();
    };

    const handleVisibleCancel = () => {
        setOpen(false);
        form.resetFields();
        handleTagClick(undefined);
        setClickedTag(undefined);
        form.setFieldsValue({ selectTags: undefined });
    };
    const handleTagClick = (value: any) => {
        setClickedTag(value);
    };
    const onFinishFailed = (errorInfo: any) => {
        fieldRef.current?.focus();
    };

    const [markProgressForm] = Form.useForm();
    const { message } = App.useApp();


    const [goalsForm, setGoalsForm] = useState([]);
    const [objectiveForm, setObjectiveForm] = useState({});
    const [objectiveData, setObjectiveData] = useState([]);

    const { data, refetch } = useApi({
        key: ["goalObjective", teamMateData?.id],
        httpConfig: {
            method: "post",
            url: "goals/user-goals-obj",
            data: objectiveForm
        },
        queryConfig: {
            enabled: !!teamMateData?.id,
            refetchInterval: 30000,
        }
    });

    useEffect(() => {
        setObjectiveForm({
            userId: teamMateData?.id,
            rootCycleId: rootCycleId,
            reviewTimelineId: Number(cycleID),
            fromAchievement: true
        });
        // eslint-disable-next-line
    }, [cycleID, teamMateData]);

    useEffect(() => {
        refetch();
        if (data?.data) {
            setGoalsForm(data?.data?.reviewInsight?.map((item: any) => {
                let achiedPer: number = item?.target_achieved / item?.goalSettingDto?.target * 100;
                return ({
                    ...item,
                    achievedPercentage: item?.target_achieved ? achiedPer?.toFixed(0) : 0,
                })
            }));

        }
        // eslint-disable-next-line
    }, [data, objectiveForm]);

    const { data: objectives } = useApi({
        key: ["reviewStatus"],
        httpConfig: {
            method: "get",
            url: "goals/current-status",
            params: {
                pageSize: 10,
                pageNo: 0,
                sortBy: "name",
                sortDir: "des",
            },
        },
    });

    useEffect(() => {
        if (objectives?.data?.content) {
            setObjectiveData(objectives?.data?.content?.map((item: any) => ({
                value: item.id,
                label: item.name
            })));
        }
        // eslint-disable-next-line
    }, [objectives]);

    function generateMarks(targetValue: any) {
        const marks: { [key: number]: string } = {};
        for (let i = 0; i <= 100; i += 20) {
            marks[Math.round((i / 100) * targetValue)] = `${i}%`;
        }
        marks[targetValue] = "100%";
        return marks;
    }

    const genExtra = (goal: IGoal, index: any) => (
        <Progress type="circle" className={`routine-color-${index % colorArray.length}`} percent={goal?.achievedPercentage} size={32} />
    );

    const panelContent = (goal: IGoal, index: number) => {
        return (
            <Row>
                <Col span={24}>
                    <Text type="secondary">Description</Text>
                </Col>
                <Col span={24}>
                    <Paragraph>{goal?.goalSettingDto?.description}</Paragraph>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, 'currentStatusId']}
                        label="Current Status"
                        initialValue={goal?.currentStatusId ?? 1}
                    >
                        <Group size='small' className='tag-selection' disabled={true} >
                            {objectiveData?.map((item: any, index: any) => (
                                <Radio.Button
                                    key={item?.value}
                                    value={item?.value}
                                    disabled={true}
                                    className={`${item?.label?.toString()?.replace(/\s+/g, '-').toLowerCase()}`}
                                >
                                    {item?.label}
                                </Radio.Button>
                            ))}
                        </Group>
                    </Form.Item>
                </Col>
                <Col span={12} className='mb-3'>Target</Col>
                <Col span={12} className='text-end rupeeClass'>{Number(goal?.goalSettingDto?.target).toLocaleString('en-IN') + ' ' + goal?.goalSettingDto?.measurement?.label}</Col>
                <Col span={12}>Achieved</Col>
                <Col span={12} className='text-end'>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, `targetAchieved`]}
                        noStyle
                        className="rupeeClass"
                        initialValue={typeof goal?.target_achieved === 'number' ? goal?.target_achieved : 0}
                    >
                        <InputNumber addonAfter={goal?.goalSettingDto?.measurement?.label} disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={[`reviewProgressRequestDto`, `${index}`, `slider`]}
                        label="Percentage Achieved"
                        initialValue={typeof goal?.target_achieved === 'number' ? goal?.target_achieved : 0}
                    >
                        <Slider
                            max={goal?.goalSettingDto?.target}
                            marks={generateMarks(goal?.goalSettingDto?.target)}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                {goal?.goalSettingDto?.individualComments !== null && (
                    <Col span={24}>
                        <Form.Item
                            name={[`reviewProgressRequestDto`, `${index}`, `individualComments`]}
                            label="Comments"
                            initialValue={goal?.goalSettingDto?.individualComments}
                            rules={[
                                { required: true, message: "Please Enter your comments!" },
                            ]}
                        >
                            <TextArea
                                placeholder="Please Enter your Comments"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                disabled={true}
                                showCount
                                maxLength={250}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        );
    }

    interface Item {
        key: string | number;
        label: string | React.ReactNode;
        children: string | React.ReactNode;
        extra?: string | React.ReactNode;
        className: string;
    }

    const items: Item[] = goalsForm?.map((goal: IGoal, index: number) => ({
        key: goal?.id,
        label: goal?.goalSettingDto?.objective,
        children: panelContent(goal, index),
        extra: genExtra(goal, index),
        className: `mb-4 routine-color-${index % colorArray.length}`
    }));


    return (
        <>
            {model === "review" && (
                <Button type='primary' ghost size='large' onClick={showVisible} disabled={myTeamCurrentLabel?.status === 'COMPLETED'}>
                    Review
                </Button>
            )}
            {model === "reviewEdit" && (
                <div
                    onClick={myTeamCurrentLabel?.status !== 'COMPLETED' ? showVisible : undefined}
                    style={{ cursor: myTeamCurrentLabel?.status !== 'COMPLETED' ? 'pointer' : 'not-allowed' }}
                >
                    <Flex className='px-3' justify='end'>
                        <span className='editReviewTxt'>Review</span><Image src={editImg} className='editIcon' preview={false} width={16} />
                    </Flex>
                    <Container className='d-flex justify-content-center'>
                        {teamMateData?.performanceLevelId === 0 ? (
                            <FrownOutlined className="smileIcon" />
                        ) : (
                            <>
                                {teamMateData?.performanceLevelId === 1 ? (<MehOutlined className="smileIcon" />) : (<SmileOutlined className="smileIcon" />)}
                            </>
                        )}
                    </Container>
                    <Container className='d-flex justify-content-center'>
                        <Title level={4}>{teamMateData?.performanceLevelId === 0 ? 'Below Plan' : teamMateData?.performanceLevelId === 1 ? 'In Plan' : 'Above Plan'}</Title>
                    </Container>
                </div>
            )}
            {model === "view" && (
                <Button onClick={showVisible} className="viewButton" type="primary" ghost size="small">
                    View all
                </Button>
            )}

            <Drawer
                title={<>Supervisor Review <Tag>{myTeamCurrentLabel?.reviewLabelAlter}</Tag> <Text type='secondary'>{dateTime(myTeamCurrentLabel?.supervisorReviewDates[0]?.startDate, 'date1') + ' - ' + dateTime(myTeamCurrentLabel?.supervisorReviewDates[0]?.endDate, 'date1')}</Text></>}
                width={720}
                onClose={handleVisibleCancel}
                maskClosable={false}
                destroyOnClose={true}
                footer={
                    <Row gutter={16} justify={"end"}>
                        <Col><Button type='primary' ghost size='large' onClick={handleVisibleCancel} hidden={model === 'view'} loading={AddPending}>Cancel</Button></Col>
                        <Col><Button type='primary' size='large' onClick={form.submit} hidden={model === 'view'} loading={AddPending}>{model === "reviewEdit" ? 'Update' : 'Submit'}</Button></Col>
                    </Row>
                }
                open={open}
            >
                <>
                    {/* Mark Progress Form  */}
                    <Form
                        form={markProgressForm}
                        layout="vertical"
                        size='large'
                        requiredMark={false}
                        preserve={false}
                        initialValues={{ selectTags: clickedTag, comments: formComments }}
                    >
                        <Collapse
                            items={items}
                            defaultActiveKey={'1'}
                            expandIconPosition={'end'}
                            accordion={true}
                            ghost
                            size='middle'
                            className='review-progress'
                        />
                    </Form>
                    {/* Mark Progress Form  */}
                    <hr />
                    <Form
                        className="mt-3 reviewProgressForm"
                        name="reviewProgress"
                        disabled={model === 'view'}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >
                        <Flex vertical>
                            <Form.Item
                                name="selectTags"
                                initialValue={clickedTag}
                                label={<><Paragraph className="review-status-label">Supervisor Review Status</Paragraph></>}
                                rules={[{ required: true, message: 'This is a mandatory field' }]}
                            >
                                <Radio.Group id="selectTags" onChange={(e) => handleTagClick(e.target.value)} className="groupRadio" value={clickedTag}>
                                    <Space size={[16, 14]} wrap>
                                        <Radio.Button value={2} className={clickedTag === 2 ? "greenTag" : ""}>
                                            Above Plan
                                        </Radio.Button>
                                        <Radio.Button value={1} className={clickedTag === 1 ? "orgTag" : ""}>
                                            In Plan
                                        </Radio.Button>
                                        <Radio.Button value={0} className={clickedTag === 0 ? "redTag" : ""}>
                                            Below Plan
                                        </Radio.Button>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Flex>
                        <Flex className="mt-4" vertical>
                            <Form.Item
                                name="comments"
                                label={<><Paragraph className="review-status-label">Comment</Paragraph></>}
                                initialValue={formComments}
                                rules={[
                                    { required: true, message: "This is a mandatory field" },
                                    { max: 250, message: "Comment cannot be more than 250 characters." },
                                    { whitespace: true, message: "This is a mandatory field" }
                                ]}
                            >
                                <TextArea
                                    id="GoalComments"
                                    ref={fieldRef}
                                    placeholder="Please enter your comment"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    showCount
                                    maxLength={250}
                                />
                            </Form.Item>
                        </Flex>
                        <Flex className="" vertical>
                            {model === 'view' && (
                                <>
                                    {commentData?.map((review: any, index: any) => (
                                        <Content>
                                            {review.commentsDtoList.map((comment: any) => (
                                                <>
                                                    {comment?.approvedComment === false &&
                                                        <Flex className="mt-5 left-review-comment" vertical>
                                                            <Text className="review-comment-date">{dateTime(comment?.commentsCreatedDate, 'date1')}</Text>
                                                            <Text className="review-comment-text">
                                                                {comment?.comments}
                                                            </Text>
                                                        </Flex>

                                                    }
                                                    {comment?.approvedComment === true &&
                                                        <Flex className="mt-5 right-review-comment" vertical align="end">
                                                            <Text className="review-comment-date">{dateTime(comment?.commentsCreatedDate, 'date1')}</Text>
                                                            <Text className="right-review-comment-text">
                                                                {comment?.comments}
                                                            </Text>
                                                        </Flex>
                                                    }
                                                </>
                                            ))}
                                        </Content>
                                    ))}
                                </>
                            )}
                        </Flex>
                    </Form>

                </>
            </Drawer>
        </>
    );
};

export default Review;
