import React from "react";
import { Flex, Typography, Image, Button } from "antd";
import targetGif from '../../assets/gif/targetArrow.gif'
import { useLocation } from "react-router-dom";
import { usePmsContext } from "../../context/PmsContext";

const { Text, Paragraph } = Typography;

const TitleCard: React.FC = () => {

    const location = useLocation();
    const { stage,setGoalModal, goals } = usePmsContext();

    const targetPage = '/my-team';
    const isOnTargetPage = location.pathname === targetPage;

    return (
        <>
            {(stage?.current === 0 && goals?.length === 0 && !isOnTargetPage) && (
                <Flex className='topTitleCard mt-3' align='center' vertical justify='center'>
                    <Paragraph className="m-0 fw-400">
                        <Image src={targetGif} className='targetGif' alt="" preview={false} />
                        Ready to set <Text strong>sail towards new horizons</Text>? It's time to chart your course with <Text strong>fresh goals, objectives, and targets</Text>! Click on "<Button className='px-0' type="link" onClick={() => setGoalModal(true)} style={{ fontWeight: "500" }}>Set Your G&O</Button>" to embark on your <Text strong>journey to success</Text>!
                    </Paragraph>
                </Flex>
            )}
        </>
    );
}

export default TitleCard;