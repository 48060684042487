import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Flex, Alert, Typography } from "antd";
import { useMutate } from "../../util/useRequestProcessor";
import { useAuthContext } from "../../context/AuthContext";

const { Title, Paragraph } = Typography;

const PdLogin: React.FC = () => {
    const { token } = useParams();
    const [error, setError] = useState<string>('');
    const { user, login } = useAuthContext();

    const { isPending, mutate: pmsLogin } = useMutate({
        key: "login",
        httpConfig: {
            method: "post",
            url: "auth/pmsauth",
        },
        customConfig: {
            callback: async (response: any) => {
                login(response?.data)
            }
        },
        mutateConfig: {
            onError: (error: any, variables: any) => {
                setError('Authentication Failed')
            },
        }
    });

    useEffect(() => {
        pmsLogin({ token });
        // eslint-disable-next-line
    }, [])

    return (
        <Flex justify="center" align="center" style={{ height: "100vh" }} vertical>
            <Card title="PD - PMS Authentication" loading={isPending} style={{ minHeight: '45vh', minWidth: "35vw" }}>
                {error && <Alert
                    message={error}
                    type="error"
                    showIcon
                />}
                {user && (<>
                    <Title level={3}>Welcome  {user?.name}!</Title>
                    <Paragraph>Please wait...! You will be redirected to landing page</Paragraph>
                </>)}
            </Card>
        </Flex>
    );
};

export default PdLogin;
