import React, { useEffect } from 'react';
import { Card, DatePicker, List, Form, Button, App } from 'antd';
import dayjs from 'dayjs';
import { useApi, useMutate } from '../../../util/useRequestProcessor';
import { dateTimeFormat } from '../../../util/Common';

const { RangePicker } = DatePicker;

interface IConfigureCycle {
    id: number,
    reviewName: string,
    reviewLabel: string,
    reviewLableAlter: string,
    reviewParent: number,
    isAnnual?: null | boolean,
    sortOrder: number,
    reviewCycleDateList: any
}

const ConfigureCycle: React.FC = () => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { data: rootCycle } = useApi({
        key: ['rootCycles'],
        httpConfig: {
            method: 'get',
            url: 'config/reviewCycles'
        }
    });

    const { isFetching, isPending, data } = useApi({
        key: ['reviewCycles'],
        httpConfig: {
            method: 'get',
            url: 'config/reviewCycles/' + rootCycle?.data[0].id
        },
        queryConfig: {
            enabled: !!rootCycle?.data[0]
        }
    });

    const { isPending: isSubmitted, mutate: doCycleSubmit } = useMutate({
        key: ["configureCycle"],
        httpConfig: {
            method: "put",
            url: "config/reviewCycles",
        },
        customConfig: {
            form: form,
            callback: async (response: any) => {
                message.success(response.message)
            },
        },
        mutateConfig: {
            onSettled: () => { }
        }
    });

    useEffect(() => {
        if (data?.data) {
            let initialValue: any = {};
            data?.data?.forEach((element: any) => {
                if (element?.reviewCycleDateList?.length === 0) return;

                initialValue[element.id] = [dayjs(element?.reviewCycleDateList[0]['startDate'], dateTimeFormat['dateInput']), dayjs(element?.reviewCycleDateList[0]['endDate'], dateTimeFormat['dateInput'])];
            });
            form.setFieldsValue(initialValue);
        }
        // eslint-disable-next-line
    }, [data])

    const onFinish = (values: any) => {
        let formValues: any = [];
        Object.keys(values).forEach(key => {
            const value = values[key];
            formValues.push({
                reviewCycleId: key,
                startDate: value[0].format(dateTimeFormat['dateInput']),
                endDate: value[1].format(dateTimeFormat['dateInput'])
            });
        });

        doCycleSubmit({ reviewCycleDateRequestList: formValues })
    };

    return (
        <Card
            title={"Setup Cycle for current year: " + (rootCycle?.data[0]?.reviewName ? rootCycle?.data[0]?.reviewName : "")}
            loading={isFetching || isPending}>
            {data?.data && (<Form
                form={form}
                onFinish={onFinish}
            >
                <List
                    grid={{ gutter: 20, column: 4 }}
                    dataSource={data?.data?.filter((item: any) => item?.reviewCycleDateList?.length > 0)}
                    renderItem={(item: IConfigureCycle) => {

                        return (
                            <List.Item>
                                <Card title={item.reviewName}>
                                    <Form.Item
                                        name={item.id}
                                        rules={[
                                            { required: true }
                                        ]}
                                    >
                                        <RangePicker
                                            format={dateTimeFormat['date1']}
                                        />
                                    </Form.Item>
                                </Card>
                            </List.Item>
                        )
                    }}
                />
                <Form.Item className="mt-3">
                    <Button type="primary" htmlType="submit" loading={isSubmitted}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            )}
        </Card>
    );
};

export default ConfigureCycle;