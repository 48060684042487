import Layout, { Content } from 'antd/es/layout/layout'
import EmployeeHeader from '../partials/Header'
import { Outlet } from 'react-router-dom'
import PmsProvider from '../../context/PmsProvider'
import { PaginationProvider } from '../../context/PaginationProvider'

const AdminMain = () => {

    return (
        <PmsProvider>
            <PaginationProvider>
                <Layout>
                    {/* <Header className='px-0' style={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
                lineHeight: '60px'
            }}> */}
                    <EmployeeHeader />
                    {/* </Header> */}
                    {/* <TopMenu /> */}
                    <Content className='px-5 py-4'>
                        <Outlet />
                    </Content>
                </Layout>
            </PaginationProvider>
        </PmsProvider>
    )
}

export default AdminMain
